import {
  abdominalPain,
  andrology,
  cancer,
  chestPain,
  cosmatology,
  diabetes,
  diabetlogy,
  healthProduct,
  healthVlog,
  heartDisease,
  highUricAcid,
  hyperTension,
  injuries,
  insomnia,
  labTest,
  medicine,
  mentalHealth,
  muscelePain,
  nearestDoc,
  numbness,
  obesity,
  onlineConsultation,
  pyscology,
  stores,
  sweating,
  testiclePain,
  tobacco,
} from "../../Assets/image-user-homepage/index.js";

export const serviceData = [
  { image: onlineConsultation, title: "Online Consultation", link: "/" },
  { image: nearestDoc, title: "Find Doctor near you", link: "/" },
  { image: medicine, title: "Medicine", link: "/" },
  { image: labTest, title: "Lab tests", link: "/" },
  { image: healthProduct, title: "HeathCare Products", link: "/" },
  { image: stores, title: "Stores", link: "/" },
  { image: healthVlog, title: "Health Vlogs", link: "/" },
  { image: healthProduct, title: "HeathCare Products", link: "/" },
  { image: stores, title: "Stores", link: "/" },
  { image: healthVlog, title: "Health Vlogs", link: "/" },
];

export const commonSymptoms = [
  { image: chestPain, title: "Chest Pain", link: "/" },
  { image: insomnia, title: "Insomnia", link: "/" },
  { image: testiclePain, title: "Testicle Pain", link: "/" },
  { image: sweating, title: "Sweating", link: "/" },
  { image: abdominalPain, title: "Adbnomial Pain", link: "/" },
  { image: numbness, title: "Numbness", link: "/" },
  { image: muscelePain, title: "Muscle Pain", link: "/" },
  { image: highUricAcid, title: "High Uric Acid", link: "/" },
];

export const commonHealthIssue = [
  { image: diabetes, title: "Diabetes", link: "/" },
  { image: cancer, title: "Cancer", link: "/" },
  { image: mentalHealth, title: "Mental Health", link: "/" },
  { image: heartDisease, title: "Heart Disease", link: "/" },
  { image: obesity, title: "Obesity", link: "/" },
  { image: hyperTension, title: "Hpertension", link: "/" },
  { image: injuries, title: "Injuries", link: "/" },
  { image: tobacco, title: "Tobbaco", link: "/" },
];

export const ourSpecialistConcern = [
  {
    image: pyscology,
    title: "Psychologist",
    description: "Erectile Dystunction, Premoture Ejaculation, Testicular Pain",
    link: "/",
  },
  {
    image: andrology,
    title: "Andrologist",
    description:
      "Erectile Dystunction, Premoture Ejaculation, Testicular` Pain",
    link: "/",
  },
  {
    image: cosmatology,
    title: "Cosmetologist",
    description:
      "Erectile Dystunction, Premoture Ejaculation, Testicular` Pain",
    link: "/",
  },
  {
    image: diabetlogy,
    title: "Diabetologist",
    description:
      "Erectile Dystunction, Premoture Ejaculation, Testicular` Pain",
    link: "/",
  },
];

export const offersArray = [
  { image: sweating, link: "/" },
  { image: sweating, link: "/" },
  { image: sweating, link: "/" },
  { image: sweating, link: "/" },
];
