import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import "./styles/index.css";
import { ABDM, About, AvijoAlpha, AvijoExpert, AvijoHome, Blogs, ChooseAcc, Contact, CreateAccount, DoCare, DownloadApp, EditorialPolicy, FeesAndPaymentsPolicy, FaviconChanger, HealthAssistant, Home, HppHome, Login, MainHome, NotFound, PrivacyPolicy, ReturnRefundAndCancellationPolicy, Safety, ShippingAndDeliveryPolicy, TermsAndConditions, Verfied, } from "./Pages";

import { Blog, ContactNav, HomeNav, HomeNavbar, ProfilePanel, UserHeader, UserLoginOtp, } from "./components";
import { useDispatch } from "react-redux";
import { checkAuth, setMyPath } from "./Redux/userSlice";
import { useSelector } from "react-redux";
import Cookie from "js-cookie";

const App = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setMyPath(location.pathname));
  }, [location.pathname]);

  useEffect(() => {
    dispatch(checkAuth());
  }, [dispatch]);

  const currentPath = location.pathname;
  const pathsToHideNavbar = [
    "/user/login",
    "/user/otp-verify",
    "/user/create-account",
    "/lab/login",
    "/lab/otp-verify",
    "/privacy-policy",
    "/policy/shipping-and-delivery-policy",
    "/policy/editorial-policy",
    "/terms-and-conditions",
    "/policy/return-refund-and-cancellation-policy",
    "/policy/fees-and-payments-policy",
    "/verfied",
    "/contact",
    "/provider",
    "/",
  ];

  const isNavbarVisible = () => {
    for (let path of pathsToHideNavbar) {
      if (currentPath.startsWith(path)) {
        return false;
      }
    }
    return true;
  };

  const showContactNavbar = currentPath === "/about" || currentPath === "/abdm";
  const showSafetyNavbar = currentPath === "/safety";
  const showUserHome = currentPath === "/user/home";
  const showHeader = currentPath.startsWith("/user") && !showUserHome;
  const showNavbar = isNavbarVisible();

  return (
    <>
      <FaviconChanger />
      {/*Add this component here to handle favicon changes */}
      {/* {showHPPProfileNavbar && <HPPProfileNavbar />} */}
      {showContactNavbar && <ContactNav />}
      {showSafetyNavbar && <ContactNav />}
      {/* {showProfileNavbar && <ProfileNavbar />} */}
      {/* {showHeader && <UserHeader />} */}
      {showUserHome && <HomeNav />}
      {showNavbar && <HomeNavbar />}
      {/* {showProfileEditNavbar && <ProfileEditNav />} */}
      {/* {showHPPProfileEditNavbar && <HPPProfileEditNav />} */}
      {/* {showPharmacyProfileEditNavbar && <PharmacyProfileEditNav />} */}
      {/* {showLabProfileEditNavbar && <LabProfileEditNav />} */}
      <Routes>
        {/* Home Route */}
        {/* <Route path='/' element={<AvijoHome />} /> */}
        <Route path='/' element={<Home />} />
        <Route path='/provider' element={<MainHome />} />

        {/* Route are changed according to requirement */}
        {/* User Route */}
        {/* <Route path='/user/home' element={<Home />} /> */}
        <Route path='/user' element={<AvijoHome />} />
        <Route path='/user/login' element={<ProtectedRoute element={<Login />} />} />
        <Route path='/user/otp-verify' element={<ProtectedRoute element={<UserLoginOtp />} />} />
        <Route path='/user/create-account' element={<ProtectedRoute element={<CreateAccount />} />} />
        {/* Doctor Route */}

        {/* legals */}
        <Route path='/feesandpaymentspolicy' element={<FeesAndPaymentsPolicy />} />
        <Route path='/editorialpolicy' element={<EditorialPolicy />} />
        <Route path='/returnrefundandcancellationpolicy' element={<ReturnRefundAndCancellationPolicy />} />
        <Route path='/shippingandDeliverypolicy' element={<ShippingAndDeliveryPolicy />} />
        <Route path='/privacypolicy' element={<PrivacyPolicy />} />
        <Route path='/policy/:option' element={<TermsAndConditions />} />
        {/* Verifed */}
        <Route path='/verfied' element={<Verfied />} />
        <Route path='/safety' element={<Safety />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/about' element={<About />} />
        <Route path='/abdm' element={<ABDM />} />
        <Route path='/alpha' element={<AvijoAlpha />} />
        <Route path='/expert' element={<AvijoExpert />} />
        <Route path='/docare' element={<DoCare />} />
        <Route path='/drjii' element={<HealthAssistant />} />
        <Route path='/downloadapp' element={<DownloadApp />} />
        <Route path='/pricing' element={<ChooseAcc />} />
        <Route path='*' element={<NotFound />} />
        {/*Abha Route */}
        <Route path='/blogs' element={<Blogs />} />
        <Route path='/blog/:name' element={<Blog />} />
        <Route path='/hpp' element={<HppHome />} />
      </Routes>
    </>
  );
};

const WrappedApp = () => (
  <Router>
    <App />
  </Router>
);

const ProtectedRoute = ({ element }) => {
  const user = useSelector((state) => state.user.userData);
  console.log("This is the user data = ", user);
  return !user ? element : <Navigate to='/user' />;
};

export default WrappedApp;
