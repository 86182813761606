import React from "react";
import { Link } from "react-router-dom";

const links = [
  { name: "Medicine", path: "#" },
  { name: "Lab test", path: "#" },
  { name: "Health Care", path: "#" },
  { name: "Health Vlogs", path: "#" },
  { name: "Offers", path: "#" },
  { name: "Stores", path: "#" },
];

const SecondNavbar = () => {
  return (
    <div>
      <div className='container'>
        <div className='row border-top-0 border bg-white py-3 text-center'>
          <div className='col-md-12 secondLinkmain'>
            {links.map((link, index) => (
              <a key={index} href={link.path}>
                {link.name}
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecondNavbar;
