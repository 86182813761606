import React, { useState } from "react";
import toast from "react-hot-toast";
import { signInWithGooglePopup } from "../../firebase";
import { toastHandler } from "../../utils/toastHandler";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BaseUrl } from "../../Routes/BaseUrl";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { setUserData } from "../../Redux/userSlice";

const GoogleSignUpRegister = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileError, setMobileError] = useState("");
  const dispatch = useDispatch();

  const validateMobileNumber = (number) => /^[0-9]{10}$/.test(number);

  const handleAuth = async () => {
    try {
      const res = signInWithGooglePopup();
      const response = await toastHandler(
        res,
        "Google Signin...",
        "Done!",
        "Something went wrong with Google"
      );
      console.log(response);

      let apiData = {
        firebaseToken: response.user.uid,
        fullName: response.user.displayName,
        email: response.user.email,
        mobileNumber: response.user.phoneNumber || "",
        provider: "google",
      };

      const userLogin = await axios.post(
        `${BaseUrl}/user/google-signin`,
        apiData,
        { withCredentials: true }
      );
      const userLoginResponse = await toastHandler(
        userLogin,
        "Verifying...",
        "Successfully verified from Google",
        "There is an issue in verifying, try again later"
      );
      console.log("This is the userLoginResponse", userLoginResponse.data.data.token, BaseUrl);
      if (userLoginResponse?.data?.data === true) {
        setFormData(apiData);
        setIsModalOpen(true);
      } else {
        dispatch(setUserData(userLoginResponse.data.data.data));
        navigate("/");
      }
    } catch (error) {
      toast.error("Something went wrong");
      console.error("Error :: ", error);
    }
  };

  const handleProceed = async () => {
    try {
      if (!validateMobileNumber(mobileNumber)) {
        setMobileError("Please enter a valid 10-digit mobile number.");
        return;
      }
      setMobileError("");

      const res = await axios.post(`${BaseUrl}/user/send-otp`, {
        mobileNumber,
      });
      await toastHandler(
        res,
        "Sending OTP",
        "OTP Sent Successfully",
        "Failed to send OTP"
      );

      navigate("/user/create-account", {
        state: { ...formData, mobileNumber },
      });
    } catch (error) {
      toast.error("An error occurred while sending OTP");
      console.error("Error in sending OTP:", error);
    }
  };

  return (
    <>
      <button onClick={handleAuth} className='btn-hero'>
        <svg
          width='25'
          height='24'
          viewBox='0 0 25 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M19.8295 20.1504C21.8623 18.2741 23.035 15.5182 23.035 12.2443C23.035 11.482 22.9666 10.749 22.8395 10.0454H12.715V14.2086H18.5004C18.2463 15.5475 17.4841 16.6811 16.3407 17.4434'
            fill='#0097DB'
          />
          <path
            d='M3.11734 16.8277C4.88619 20.3361 8.51189 22.75 12.7141 22.75C15.6166 22.75 18.05 21.7922 19.8287 20.1504L16.3398 17.4434C15.3821 18.0884 14.1605 18.4793 12.7141 18.4793C9.91914 18.4793 7.54439 16.5931 6.69414 14.0522'
            fill='#36AB53'
          />
          <path
            d='M3.1182 7.18201C2.38525 8.62836 1.965 10.2604 1.965 12C1.965 13.7395 2.38525 15.3716 3.1182 16.8179L5.90345 14.6484L6.69505 14.0425C6.48005 13.3975 6.353 12.7134 6.353 12C6.353 11.2866 6.48005 10.6025 6.69505 9.95746'
            fill='#F7C01B'
          />
          <path
            d='M12.7142 5.5304C14.2974 5.5304 15.7046 6.07765 16.8285 7.13315L19.9069 4.05475C18.0403 2.3152 15.6166 1.25 12.7141 1.25C8.51189 1.25 4.88619 3.66385 3.11734 7.18205L6.69414 9.9575C7.54439 7.4166 9.91919 5.5304 12.7142 5.5304Z'
            fill='#D5423B'
          />
        </svg>
        Google
      </button>

      {isModalOpen && (
        <div className='fixed inset-0 flex items-center justify-center bg-gray-300 bg-opacity-70'>
          <div className='relative w-96 rounded-lg bg-white p-6 shadow-lg'>
            <div
              className='absolute right-3 top-3 cursor-pointer text-gray-500 hover:text-black'
              onClick={() => setIsModalOpen(false)}
            >
              <AiOutlineClose size={24} />
            </div>
            <h2 className='mb-4 text-lg font-semibold'>Enter Mobile Number</h2>
            <input
              type='text'
              placeholder='Enter your mobile number'
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
              className='w-full rounded-md border p-2 focus:outline-none focus:ring-2 focus:ring-blue-500'
            />
            {mobileError && (
              <p className='mt-2 text-sm text-red-500'>{mobileError}</p>
            )}
            <div className='mt-4 flex justify-between'>
              <button
                onClick={handleProceed}
                className='rounded-md bg-blue-500 px-4 py-2 text-white'
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default GoogleSignUpRegister;
