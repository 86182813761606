import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { Link, useNavigate } from "react-router-dom";
import ExpertNav from "../components/Navbar/ExpertNav";
import Footer from "../components/Home/footer/Footer";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  management,
  teleConsultation,
  insightOperation,
  digitalHealth,
  linkageAlpha,
  profit,
  world,
  startExpert,
} from "../Assets/index.js";
import { BrandLogo } from "../components/index.js";

const AvijoExpert = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='App'>
      <ExpertNav />
      <Header />
      <Helmet>
        <title> Avijo Expert | Avijo</title>
        <link rel='icon' href='./avijoexpert.ico' />
        <meta
          name='discription'
          content='Discover Avijo Expert, a powerful EMR/EHR Ai powered tool tailored for doctors and healthcare professionals to Streamline Daily operations and practice, improve patient care and manage records effortlessly for free.'
        ></meta>
      </Helmet>
      <Carousel />
      <SupportData />
      <Footer />
    </div>
  );
};

const Header = () => {
  return (
    <header className='hero bg-gradient-to-r from-[#ECF9FF] to-[#C3EBFE]'>
      <div className='flex flex-col justify-between p-4 md:flex-row'>
        <div className='flex h-full w-full flex-col p-2 md:mr-12 md:w-1/2'>
          <div>
            <div className='flex flex-row items-end'>
              <BrandLogo subTitle={"Expert"} />
            </div>
            <span className='font-gilroy text-left text-xl font-[500] text-[#535353]'>
              Avijo Expert is the premier application designed specifically for
              healthcare doctors/professionals. Built to complement the Avijo
              Alpha platform, Avijo Expert empowers doctors and medical
              practitioners with the tools they need to manage their practice,
              engage with patients, and contribute to a growing health
              community—all from the convenience of their devices.
            </span>
          </div>
          <button className='mt-9 flex h-12 w-[130px] items-center justify-center rounded-lg bg-[#0097DB]'>
            <a
              href='https://expert.avijo.in/'
              target='_blank'
              rel='noopener noreferrer'
              style={{ textDecoration: "none", color: "white" }}
              className='flex items-center gap-2 text-lg'
            >
              Get Started
              <img
                src={require("../Assets/image/diagonal_arrowWhite.png")}
                className='h-[14px] w-[18px]'
              />
            </a>
          </button>
        </div>
        <div className='flex w-full justify-center p-12 md:ml-20 md:w-1/2'>
          <img
            src='https://cdni.iconscout.com/illustration/premium/thumb/healthcare-equipment-illustration-download-in-svg-png-gif-file-formats--medical-pattern-first-aid-kit-medicine-doctor-pharmacy-pregnancy-pack-people-illustrations-4494350.png'
            alt='header'
            className='h-[350px] w-auto'
          />
        </div>
      </div>
    </header>
  );
};

const CarouselItem = ({
  active,
  index,
  title,
  content,
  buttonText,
  imgSrc,
  bgColor,
  setActive,
}) => (
  <div
    className={`${active === index ? "active" : ""} carousel-item flex flex-col items-center`}
    style={{ backgroundColor: bgColor }}
  >
    <h2 className='ml-[20%] w-[60%] self-center pt-5 text-center text-4xl'>
      Elevate Your Medical Practice with Cutting-Edge Technology
    </h2>
    <div className='ml-10 mt-5 flex w-[90%] flex-col items-center justify-between text-center md:flex-row'>
      {[
        "Patient care",
        "Remote Working",
        "AI optimize",
        "Digital presence and operations",
        "Comprehensive ecosystem",
      ].map((text, idx) => (
        <div key={idx} onClick={() => setActive(idx)}>
          <span
            className={`cursor-pointer text-center text-[22px] font-semibold hover:text-white ${active === idx ? "text-[#0097DB]" : "text-black"}`}
          >
            {text}
          </span>
        </div>
      ))}
    </div>
    <div className='flex flex-col items-center justify-between p-4 pt-2 md:flex-row'>
      <div className='flex h-full w-full flex-col p-2 md:w-[65%]'>
        <div className='flex-grow'>
          <h1 className='font-gilroy font-semiBold ml-4 text-[36px] text-black'>
            {title}
          </h1>
          {content.map((item, idx) => (
            <div key={idx} className='mt-2 flex flex-row items-start'>
              <img
                src={require("../Assets/image/arrow.png")}
                className='mr-2 mt-1 h-[13px] w-[9px]'
                alt='arrow'
              />
              <span className='font-gilroy font-mdeium text-[20px] text-black'>
                {item}
              </span>
            </div>
          ))}
        </div>
        <button className='mt-4 w-[220px] rounded-lg bg-[#0097DB] p-2'>
          <a
            href='https://expert.avijo.in/'
            target='_blank'
            rel='noopener noreferrer'
            className='flex items-center gap-2 text-lg text-white no-underline'
          >
            <span className='text-sm text-white'>{buttonText}</span>
            <img
              src={require("../Assets/image/diagonal_arrowWhite.png")}
              className='h-[14px] w-[18px]'
              alt='arrow'
            />
          </a>
        </button>
      </div>
      <div className='flex h-[430px] w-full justify-center p-4 md:w-[500px]'>
        <img src={imgSrc} alt='header' className='h-[80%] w-[80%]' />
      </div>
    </div>
  </div>
);

const Carousel = () => {
  const [active, setActive] = useState(0);

  const items = [
    {
      index: 0,
      title: "Comprehensive Patient Management",
      content: [
        "Centralized platform for managing patient records, appointments, and communications.",
        "Easy access to patient history and diagnostic reports for informed decision-making",
        "Secure storage and retrieval of patient data compliant with ABDM standards.",
      ],
      buttonText: "Register as HPR for demo",
      imgSrc: management,
      bgColor: "#FCEFE7",
    },
    {
      index: 1,
      title: "Teleconsultation Integration",
      content: [
        "Seamless video, audio, and chat-based consultation options.",
        "Integrated prescription generation and sharing with patients.",
        "Allows for remote diagnosis and follow-up, expanding patient reach.",
        "Real-time appointment booking and management.",
      ],
      buttonText: "Register as HPR for demo",
      imgSrc: teleConsultation,
      bgColor: "#FAEFD4",
    },
    {
      index: 2,
      title: "AI-Powered Insights and operation",
      content: [
        "Utilize AI for patient treatment recommendations and insights..",
        "Analyze patient data for trends and predictive healthcare outcomes..",
        "Support clinical decisions with AI-driven diagnostic tools..",
      ],
      buttonText: "Register as HPR for demo",
      imgSrc: insightOperation,
      bgColor: "#E4DDFF",
    },
    {
      index: 3,
      title: "E-Prescription & Digital Health Records",
      content: [
        "Issue digital prescriptions directly through the platform.",
        "Maintain digital health records that are easily accessible for both doctors and patients.",
        "Simplify follow-ups and treatment plans with digital documentation.",
      ],
      buttonText: "Register as HFR for demo",
      imgSrc: digitalHealth,
      bgColor: "#C3DBE4",
    },
    {
      index: 4,
      title: "Linkage with Avijo Alpha",
      content: [
        "ISeamless integration with Avijo Alpha for enhanced facility management.",
        "Streamline communication and coordination with other healthcare professionals",
        "Utilize the linkage software to expand your professional network.",
      ],
      buttonText: "Register as HFR for demo",
      imgSrc: linkageAlpha,
      bgColor: "#F3F2E0",
    },
  ];

  const handlePrev = () => {
    setActive((prev) => (prev > 0 ? prev - 1 : items.length - 1));
  };

  const handleNext = () => {
    setActive((prev) => (prev < items.length - 1 ? prev + 1 : 0));
  };

  return (
    <div id='carouselExample' className='relative'>
      <div className='carousel-inner'>
        {items.map((item) => (
          <CarouselItem
            key={item.index}
            active={active}
            index={item.index}
            title={item.title}
            content={item.content}
            buttonText={item.buttonText}
            imgSrc={item.imgSrc}
            bgColor={item.bgColor}
            setActive={setActive} // Pass setActive function
          />
        ))}
      </div>
      <button
        className='absolute left-0 top-1/2 -translate-y-1/2 transform p-2'
        onClick={handlePrev}
        style={{ backgroundColor: "transparent" }}
      >
        <img
          src={require("../Assets/image/left.png")}
          alt='Previous'
          className='h-8 w-8'
        />
      </button>
      <button
        className='absolute right-0 top-1/2 -translate-y-1/2 transform p-2'
        onClick={handleNext}
        style={{ backgroundColor: "transparent" }}
      >
        <img
          src={require("../Assets/image/right.png")}
          alt='Next'
          className='h-8 w-8'
        />
      </button>
    </div>
  );
};

const SupportData = () => {
  const data = [
    {
      id: 0,
      heading: "Profit & Growth Opportunities",
      image: profit,
      points: [
        {
          id: 0,
          text: "Avijo Expert isn’t just about managing your practice—it’s about growing it. The app provides opportunities for additional revenue through profit margins on diagnosis and medicine prescriptions. You can also participate in our affiliate program, recommending trusted healthcare products to your patients through the Avijo platform, and earning commissions on every sale.",
        },
      ],
    },
    {
      id: 1,
      heading: "Join the Docare Community",
      image: world,
      points: [
        {
          id: 0,
          text: "Enhance your professional presence by joining Docare, Avijo’s health community. As part of Docare, you can create and share health-related content, engage in meaningful discussions with other healthcare professionals, and increase your visibility to patients seeking expertise in your specialty. Whether you’re looking to educate the public, share insights with peers, or simply network, Docare offers a platform to amplify your voice .",
        },
      ],
    },
    {
      id: 2,
      heading: "Get Started with Avijo Expert",
      image: startExpert,
      points: [
        {
          id: 0,
          text: "Avijo Expert is more than just an app—it’s a comprehensive tool designed to support your growth as a healthcare professional. Whether you’re looking to streamline your practice, increase your patient base, or contribute to a broader healthcare community, Avijo Expert has the features and functionality you need.",
        },
      ],
    },
  ];

  return (
    <>
      {data.map((item, index) =>
        index % 2 === 0 ? (
          index % 2 === 0 && (
            <div
              key={index}
              className='border-1 m-4 flex flex-col items-center justify-between rounded-[5px] pb-4 md:flex-row'
            >
              <div className='flex h-full w-full flex-col p-2 md:w-1/2'>
                <div className='flex-grow'>
                  <h3 className='font-gilroy font-semiBold text-[36px]'>
                    {item.heading}
                  </h3>
                  <ul className='list-disc pl-0'>
                    {item.points.map((point, idx) => (
                      <div
                        key={idx}
                        className='font-gilroy mt-2 text-[20px] font-medium text-[#424242]'
                      >
                        <p className='expert-paragraph'>{point.text}</p>
                      </div>
                    ))}
                  </ul>
                </div>
              </div>
              <div className='w-full p-4 pb-0 md:w-1/2'>
                <img
                  src={item.image}
                  alt={item.heading}
                  loading='lazy'
                  className='rounded-2 h-[310px] w-[500px] md:ml-10'
                />
              </div>
            </div>
          )
        ) : (
          <div
            key={index}
            className='border-1 m-4 flex flex-col items-center justify-between rounded-[5px] pb-4 md:flex-row'
          >
            <div className='w-full p-4 pb-0 md:w-1/2'>
              <img
                src={item.image}
                alt={item.heading}
                loading='lazy'
                className='rounded-2 h-[310px] w-[500px]'
              />
            </div>
            <div className='flex h-full w-full flex-col p-2 md:w-1/2'>
              <div className='flex-grow'>
                <h3 className='font-gilroy font-semiBold text-[36px]'>
                  {item.heading}
                </h3>
                <ul className='list-disc pl-0'>
                  {item.points.map((point, idx) => (
                    <div
                      key={idx}
                      className='font-gilroy mt-2 text-[20px] font-medium text-[#424242]'
                    >
                      <p className='expert-paragraph'>
                        {point.text}
                        <Link
                          to='/DoCare'
                          className='text-decoration-none ps-2'
                        >
                          Learn more..
                        </Link>
                      </p>
                    </div>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};

export default AvijoExpert;
