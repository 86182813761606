const list1 = [
  { to: "/about", label: "About" },
  { to: "/abdm", label: "ABDM" },
  { to: "/blogs", label: "Blogs" },
  { to: "http://avijobs.in", label: "Career", external: true },
  { to: "/contact", label: "Contact" },
  { to: "/safety", label: "Security" },
];
const list2 = [
  { to: "/provider", label: "For Provider" },
  { to: "/alpha", label: "Health Facility" },
  { to: "/expert", label: "Health Professionals" },
  { to: "/", label: "Abha Creation" },
];
const list3 = [
  { to: "https://www.instagram.com/avijo.in/", label: "Instagram" },
  {
    to: "https://www.facebook.com/profile.php?id=61557517891146",
    label: "Facebook",
  },
  {
    to: "https://www.youtube.com/channel/UCZgHMknFFjnpVi59jEf6m3w",
    label: "YouTube",
  },
  { to: "https://www.linkedin.com/company/103452119", label: "LinkedIn" },
  {
    to: "https://x.com/avijo_in?t=o_OpfyI_UyieZTx93ribsQ&s=08",
    label: "Twitter",
  },
];
const list4 = [
  { to: "/policy/terms-and-conditions", label: "Terms and Conditions" },
  { to: "/policy/privacy-policy", label: "Privacy Policy" },
  { to: "/policy/fees-and-payments-policy", label: "Fees and Payments Policy" },
  {
    to: "/policy/shipping-and-delivery-policy",
    label: "Shipping and Delivery Policy",
  },
  {
    to: "/policy/return-refund-and-cancellation-policy",
    label: "Return, Refund and Cancellation Policy",
  },
  { to: "/policy/editorial-policy", label: "Editorial Policy" },
];

export { list1, list2, list3, list4 };
