import React from "react";
import Footer from "../../components/Home/footer/Footer";
import { Helmet } from "react-helmet";
import ExpertNav from "../../components/Navbar/ExpertNav";

const options = [
  "Support for creating any HSPP profile (for Healthcare Providers [HSPP])",
  "Free Trial/Demo for any HSPP software (for HSPP)",
  "Channel Partnership (for HSPP)",
  "Advertising on avijo platforms (for HSPP)",
  "Career Opportunities (for Job Applicants)",
  "Others",
];

const Contact = () => {
  return (
    <>
      <ExpertNav />
      <Helmet>
        <title> Contact Us | Avijo</title>
        <meta
          name='discription'
          content="Have questions or need assistance? Contact Avijo Health Solutions. We're here to help with all your healthcare and platform-related needs."
        />
      </Helmet>
      <div className='contact mb-1 border-b-2'>
        <div className='contact-second'>
          <h1>Contact Us</h1>
          <p>
            If you have questions about our products, support services, or
            anything else, let us know and we'll respond promptly.
          </p>
        </div>
        <div className='container'>
          <div className='row mb-5 mt-5'>
            <div className='col-md-4'>
              <div className='card contact-form'>
                <div className='card-body'>
                  <h5 className='card-title'>Contact Us</h5>
                  <form>
                    <div className='mb-3'>
                      <label htmlFor='interestedIn' className='form-label'>
                        Interested In
                      </label>
                      <select className='form-select' id='interestedIn'>
                        {options.map((option, index) => (
                          <option key={index}>{option}</option>
                        ))}
                      </select>
                    </div>
                    <div className='mb-3'>
                      <label htmlFor='name' className='form-label'>
                        Name
                      </label>
                      <input
                        type='text'
                        className='form-control'
                        id='name'
                        placeholder='Enter your name'
                      />
                    </div>
                    <div className='mb-3'>
                      <label htmlFor='email' className='form-label'>
                        Email
                      </label>
                      <input
                        type='email'
                        className='form-control'
                        id='email'
                        placeholder='Enter your email'
                      />
                    </div>
                    <div className='mb-3'>
                      <label htmlFor='Mobile' className='form-label'>
                        Mobile
                      </label>
                      <input
                        type='number'
                        className='form-control'
                        id='Mobile'
                        placeholder='Enter your Mobile'
                      />
                    </div>
                    <div className='mb-3'>
                      <label htmlFor='message' className='form-label'>
                        Message
                      </label>
                      <textarea
                        className='form-control'
                        id='message'
                        rows='3'
                        placeholder='Message'
                      ></textarea>
                    </div>
                    <button type='submit' className='btn btn-primary'>
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <div className='col-md-8'>
              <div
                style={{
                  padding: "20px 20px",
                  height: "400px",
                  background: " #ECF3F5",
                }}
              >
                {" "}
                <h5 className='mb-4'>Our branches</h5>
                <form>
                  <div className='mb-3'>
                    <label htmlFor='city' className='form-label'>
                      City
                    </label>
                    <select
                      className='form-select w-50'
                      id='city'
                      // value={selectedCity}
                      // onChange={handleCityChange}
                    >
                      <option value='indore '>Indore </option>
                    </select>
                  </div>
                </form>
                <div className='card w-75 mt-4 border-0'>
                  <div className='card-body'>
                    <h6 className='card-title'>Indore </h6>
                    <p className='card-text'>
                      3rd Floor, Headquarter Building, Satya Sai Square, Indore
                    </p>
                    <a
                      href='https://www.google.com/maps'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='card-link'
                    >
                      Get Directions
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
