import React, { useState } from "react";
import Footer from "../components/Home/footer/Footer";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import ExpertNav from "../components/Navbar/ExpertNav";
// import { Modal } from "react-bootstrap";
import "../styles/Home.css";

// Main component is at the end

const InfoSection = ({ item, flag }) => {
  const {
    videoSrc,
    title,
    subtitle,
    description,
    listItems,
    link,
    linkText,
    subtitleColor,
  } = item;

  return (
    <section className='body-font relative w-full text-gray-600'>
      <div className='container mx-auto w-full pt-24'>
        <div
          className={`flex ${flag ? "flex-row-reverse" : "flex-row"} mx-auto flex-wrap justify-between gap-10`}
        >
          {/* Left section: Video */}
          <div className='mb-6 min-w-[300px] flex-1 md:w-1/2'>
            <iframe
              width='100%'
              height='540'
              src={videoSrc}
              title='YouTube video player'
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
              referrerPolicy='strict-origin-when-cross-origin'
              allowFullScreen
              loading='lazy'
            ></iframe>
          </div>

          {/* Right section: Content */}
          <div className='mb-6 min-w-[300px] flex-1 md:w-1/2'>
            <h3 className='mb-6 text-start font-[Gilroy-SemiBold] text-[44px] leading-tight text-black'>
              {title}{" "}
              <span
                className={`text-[${subtitleColor}] ml-[-3px] !font-[Gilroy-SemiBold] text-[44px] ${subtitle === "avijo" ? "non-italic" : "italic"} `}
              >
                {subtitle}
              </span>
            </h3>
            <div className='w-full'>
              <p className='userApp-para text-justify text-[15px] text-base leading-6'>
                {description}
              </p>

              <div className='health-para text-justify'>
                {listItems.map((item, index) => (
                  <p key={index}> ✔ {item}</p>
                ))}
              </div>

              <Link
                to={link}
                className='health-btn text-decoration-none block ps-3 pt-6'
              >
                {linkText}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const sectionData = [
  {
    videoSrc: "https://www.youtube.com/embed/bhjb_HHB8Ik?si=klGEALTUK9K6xRH2",
    title: "HFR by avijo",
    subtitle: "Alpha",
    subtitleColor: "#12CDB7",
    description:
      "Health Facility Registry (HFR) is a comprehensive repository. It includes public and private health facilities such as hospitals, clinics, pharmacies, and diagnostic centers. By registering your facility through Avijo Alpha, this enhances your facility's visibility and credibility. Registration facilitates better access to healthcare resources, enables seamless management & integration with other healthcare providers & patients, and ensures that patients can easily find and trust your services. Embrace the digital future of healthcare and expand your reach by registering with the Health Facility Registry (HFR) through Avijo Alpha today.",
    listItems: [
      "Visibility in Listings.",
      "Management of Facility.",
      "Smart Notifications systems.",
      "Smart Calling System.",
      "HIPAA Servers.",
    ],
    link: "/",
    linkText: "Learn More / Register as Health Id",
  },
  {
    videoSrc: "https://www.youtube.com/embed/PAIGX5n290c?si=JXkC8GQkiCMhepGN",
    title: "HPR by avijo",
    subtitle: "Expert",
    subtitleColor: "#FD7979",
    description:
      "Healthcare Professionals Registry (HPR) is a comprehensive repository of all healthcare professionals (e.g., Doctors, nurses) across both modern and traditional systems of medicine delivering healthcare services across India. By joining the HPR through Avijo Expert, you gain access to a network of registered professionals and can offer and manage your services more effectively across India.",
    listItems: [
      "Visibility in Listings.",
      "Management of Facility.",
      "Smart Notifications systems.",
      "Smart Calling System.",
      "HIPAA Servers.",
    ],
    link: "/expert",
    linkText: "Learn More / Register as HPR",
  },
  {
    videoSrc: "https://www.youtube.com/embed/5ZvLR1DcNBo?si=fvxaNiH408NPVpzh",
    title: "ABHA Creation by",
    subtitle: "avijo",
    subtitleColor: "#0097DB",
    description:
      "By creating ABHA/Health id by avijo you gain one stop solution for healthcare needs, seamless access to teleconsultations, unified health tracking and record management, quick deliveries and lab sample collection, all powered by Ai for better health management and more..",
    listItems: [
      "Find nearby doctors, hospitals & labs with a map.",
      "Health tracking: Track fitness, meds, and records.",
      "Ai assistant (Dr. Jii): Personalized health insights & reminders.",
      "Book appointments, video consultations with doctors.",
      "Instant ambulance booking and service.",
      "Instant medicine and health product delivery.",
      "Instant sample collection for lab tests.",
    ],
    link: "/",
    linkText: "Learn More / Register as Health Id",
  },
  // {
  //   videoSrc: "https://www.youtube.com/embed/ICX5Hw5shNw",
  //   title: "avijo",
  //   subtitle: "HPP",
  //   subtitleColor: "#FFCA38",
  //   description: "Avijo HPP application developed for Health Product Provider (HPP) companies, including pharmaceutical firms, medical device manufacturers, and suppliers. This platform allows HPP companies to manage their product listings, track inventory, process orders, and interact with healthcare facilities and professionals. Avijo aims to streamline the supply chain in the healthcare industry, ensuring timely delivery of essential medical products and fostering a more connected and responsive healthcare ecosystem.",
  //   listItems: [
  //     "Create Company Account.",
  //     "Add/Edit catalog.",
  //     "Live order tracking in B2B trade.",
  //     "Advertising on platform for B2B & B2C."
  //   ],
  //   link: "/hpp",
  //   linkText: "Learn More / Register as HPP Company"
  // }
];

const Home = () => {
  // Hero Component States
  const [show, setShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleModalClose = () => setModalShow(false);
  const handleModalShow = () => setModalShow(true);

  return (
    <div>
      <Helmet>
        <title>For Provider | Avijo</title>
        <meta
          name='description'
          content='Explore Avijo’s cutting-edge solutions for healthcare providers, including EMR/EHR tools, cloud software, and advertising platforms. Empower your practice with innovative technology.'
        />
      </Helmet>
      <ExpertNav isProvider={true} />
      <div className='pt-5'>
        <div className='rounded-1xl flex h-auto w-full flex-col items-center gap-10 bg-gradient-to-r from-[#0097DB] via-[#0c93ed98] to-[#feffff] px-5 md:flex-row md:gap-40'>
          <div className='w-full pl-10 text-left font-[Gilroy-SemiBold] md:w-1/2'>
            <h1 className='mb-4 font-[Gilroy-SemiBold] text-5xl leading-tight text-white md:text-8xl'>
              avijo
            </h1>
            <p className='font-[Gilroy-SemiBold] text-xl text-white md:text-4xl'>
              Empowering healthcare providers
            </p>
          </div>
          <img
            src='https://png.pngtree.com/png-clipart/20231003/original/pngtree-online-doctor-health-service-png-image_13230792.png'
            alt='Medical theme'
            className='h-auto w-[70%] max-w-xl rounded-lg md:w-[32%]'
          />
        </div>
      </div>

      <div className='container mx-auto px-5 text-center'>
        <h4 className='hero-what'>What we do?</h4>
        <p className='hero-paragraph'>
          We are on Mission to bring World Class Healthcare ecosystem by
          leveraging Technology to make healthcare accessible, affordable, Safe
          and convenient as a complaint of Ayushman Bharat Digital
          Mission(ABDM), UHI & HIPAA standards.
          <Link to='/about' className='text-decoration-none ps-4'>
            Learn more..
          </Link>
        </p>

        <h4 className='hero-what'>Ayushman Bharat Digital Mission (ABDM)</h4>
        <p className='hero-paragraph'>
          The Ayushman Bharat Digital Mission (ABDM) aims to develop the
          backbone necessary to support the integrated digital health
          infrastructure of the country. It will bridge the existing gap amongst
          different stakeholders of Healthcare ecosystem through digital
          highways.
          <Link to='/ABDM' className='text-decoration-none ps-4'>
            Learn more..
          </Link>
        </p>

        <div>{/* <LimitedParagraph text={abdmtext} limit={150} /> */}</div>
      </div>

      <div>
        {modalShow && (
          <div className='fixed inset-0 z-50 flex items-center justify-center'>
            <div
              className='absolute inset-0 bg-black opacity-50'
              onClick={handleModalClose}
            ></div>

            {/* Modal Content */}
            <div className='relative w-1/3 rounded-lg bg-white p-6 shadow-lg'>
              {/* Close Button */}
              <button
                onClick={handleModalClose}
                className='absolute right-2 top-2 text-gray-600'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  fill='none'
                  stroke='currentColor'
                  viewBox='0 0 24 24'
                  strokeWidth='2'
                  className='h-6 w-6'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M6 18L18 6M6 6l12 12'
                  />
                </svg>
              </button>
              <div className='mb-4 text-xl font-semibold'>
                Download Avijo Health app
              </div>
              <div className='text-gray-700'>
                <h6>
                  Avijo health app is now in beta version after testing we
                  notify you
                </h6>
              </div>
            </div>
          </div>
        )}
      </div>

      {sectionData.map((item, index) => (
        <InfoSection key={index} flag={index % 2} item={item} />
      ))}

      <Footer isProvider={true} />
    </div>
  );
};

export default Home;
