import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const FaviconChanger = () => {
  const location = useLocation();

  useEffect(() => {
    const changeFavicon = (newFaviconPath) => {
      const link = document.getElementById("favicon");
      if (link) {
        link.href = newFaviconPath;
      } else {
        const newLink = document.createElement("link");
        newLink.id = "favicon";
        newLink.rel = "icon";
        newLink.href = newFaviconPath;
        document.head.appendChild(newLink);
      }
    };

    // Logic to decide which favicon to show based on the current route

    if (location.pathname === "/expert") {
      changeFavicon("./avijoexpert.png");
    } else if (location.pathname === "/alpha") {
      changeFavicon("./avijoalpha.png");
    } else if (location.pathname === "/hpp") {
      changeFavicon("./avijohpp.png");
    } else if (location.pathname === "/drjii") {
      changeFavicon("./drjii.png");
    } else {
      changeFavicon("./favicon.png");
    }
  }, [location]); // This hook runs whenever the location changes

  return null; // This component doesn't render anything, it just updates the favicon
};

export default FaviconChanger;
