import React, { useEffect, useState } from "react";
import ExpertNav from "../components/Navbar/ExpertNav";
import { BaseUrl, BaseUrlAdmin, timeAgo } from "../Routes/BaseUrl";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import blogBanner from "../Assets/image/docare3.png";
import Footer from "../components/Home/footer/Footer";

export default function Blogs() {
  const [blogs, setBlogs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selected, setSelected] = useState(0);
  const navigate = useNavigate();

  const fetchBlogs = async () => {
    try {
      const response = await fetch(`${BaseUrlAdmin}/admin/All/blogs`);
      const json = await response.json();
      const getType = await json.data.filter(
        (item) => item.type === "avijo main"
      );
      setBlogs(getType);
      const uniqueCategory = [
        ...new Set(getType?.map((item) => item?.category)),
      ];
      setCategories(uniqueCategory);
    } catch (e) {
      console.log("error fetching blogs", e);
    }
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  const handleNavigate = (id, slug) => {
    navigate(`/blog/${slug}`, { state: { id: id } }); 
  };


  function extractTextContent(html) {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
  }

  function getShortenedContent(content, wordLimit = 12) {
    const text = extractTextContent(content);
    const words = text.split(' ');

    if (words.length <= wordLimit) {
      return text;
    }

    return words.slice(0, wordLimit).join(' ') + '...';
  }

  return (
    <>
      <Helmet>
        <title>Blogs | Avijo</title>

        <meta
          name='discription'
          content='Stay updated with Avijo’s blogs featuring insights on healthcare technology, wellness tips, health policies, and innovations for a healthier future.'
        />
      </Helmet>
      <div className='flex w-full flex-col items-center'>
        <ExpertNav />
        <div className='pt-5'>
          <div className='rounded-1xl flex h-auto w-full flex-col items-center gap-10 bg-gradient-to-r from-[#0097DB] via-[#0c93ed98] to-[#feffff] px-5 md:flex-row md:gap-40'>
            <div className='w-full pl-10 text-left font-[Gilroy-SemiBold] md:w-1/2'>
              <h1 className='mb-4 font-[Gilroy-SemiBold] text-4xl leading-tight text-white md:text-7xl'>
                avijo
              </h1>
              <p className='text-lg font-medium text-white md:text-2xl'>
                Learn about everything we do at avijo for healthcare
              </p>
            </div>
            <img
              src={blogBanner}
              alt='Medical theme'
              className='h-auto w-[70%] max-w-xl rounded-lg md:w-[32%]'
            />
          </div>
        </div>

        <div className='flex w-full'>
          {/* Sidebar */}
          <div className='sticky top-0 h-screen w-[200px] border-r bg-white p-4 md:w-[300px]'>
            <div className='mb-4'>
              <h2 className='text-2xl font-semibold text-black'>Categories</h2>
              {categories.length === 0 ? (
                <p>No categories available</p>
              ) : (
                categories.map((item, index) => (
                  <div
                    key={index}
                    className={`p-2 ${selected === index && "bg-gray-200"} rounded-lg hover:cursor-pointer`}
                    onClick={() => setSelected(index)}
                  >
                    <span className='text-lg text-gray-700'>{item}</span>
                  </div>
                ))
              )}
            </div>
          </div>

          {/* Main content */}
          <div className='flex-1 p-4 pb-0'>
            <div className='flex w-full flex-wrap items-start'>
              {blogs.map((item, index) => {
                if (item.category === blogs[selected].category) {
                  return (
                    <div
                      key={item.id || index}
                      className='border-1 flex w-full cursor-pointer flex-col rounded-lg p-4 py-2 transition-all duration-150 ease-in-out hover:bg-[#f5f5f5] md:w-1/2'
                      onClick={() => handleNavigate(item._id, item.slug)}
                    >
                      <h4 className='line-clamp-2 pt-2'>{item.title}</h4>
                      <span className='line-clamp-2 text-xs'>
                        {item.createdAt && timeAgo(item.createdAt)}, by - <span className="italic">{item?.postedBy}</span>
                      </span>
                      <img
                        src={item.imageUrl}
                        alt='Blog'
                        className='mt-1 h-[250px] w-full rounded-md border object-cover'
                      />
                      <div className='line-clamp-2 font-medium'>
                        {getShortenedContent(item.content)}
                      </div>
                    </div>
                  );
                }
                return null; // Return null if the condition is not met
              })}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
