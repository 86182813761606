import React, { useState, useEffect } from "react";
import HeroImage from "../../Assets/image/5093164.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { BaseUrl } from "../../Routes/BaseUrl";
import { FormProvider, useForm, useController } from "react-hook-form";
import OtpInput from "react-otp-input";
import { toastHandler } from "../../utils/toastHandler";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import {
  setMyPath,
  setUserData,
} from "../../Redux/userSlice";
import { HomeNav } from "../../components";
import { Link } from "react-router-dom";

const CreateAccount = () => {
  const methods = useForm(
    {
      defaultValues: {
        dateOfBirth: "",
      }
    }
  );
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = methods;

  const location = useLocation();
  const navigate = useNavigate();
  const [seconds, setSeconds] = useState(10);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setMyPath(window.location.pathname));
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => (prevSeconds > 0 ? prevSeconds - 1 : 0));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    setValue("dateOfBirth", getValues("dateOfBirth"));
  }, [seconds]);

  useEffect(() => {
    const mobileNumber = location.state && location.state.mobileNumber;
    const email = location.state && location.state.email;
    const fullName = location.state && location.state.fullName;
    const firebaseToken = location.state && location.state.firebaseToken;
    if (mobileNumber) setValue("mobileNumber", mobileNumber);
    if (email) setValue("email", email);
    if (fullName) setValue("fullName", fullName);
    if (firebaseToken) setValue("firebaseToken", firebaseToken);
  }, [location.state]);

  const sendOtp = async (mobileNumber) => {
    try {
      setSeconds(10);
      setLoading(true);

      const res = await axios.post(`${BaseUrl}/user/send-otp`, {
        mobileNumber,
      });
      const response = await toastHandler(
        res,
        "Sending OTP",
        "OTP Sent Successfully",
        "Failed to send OTP"
      );

      console.log("Response:", response.data);
    } catch (error) {
      toast.error("An error occurred while sending OTP");
      console.error("Error sending OTP:", error);
    } finally {
      setLoading(false);
    }
  };

  const formSubmit = async (data) => {
    try {
      console.log("This is data = ", data);
      let res;

      if (location.state && location.state.provider === "google") {
        res = await axios.post(`${BaseUrl}/user/google-signin`, data, { withCredentials: true });
      } else {
        res = await axios.post(`${BaseUrl}/user/register`, data, { withCredentials: true });
      }

      const response = await toastHandler(
        res,
        "Verifying OTP",
        "Registration Successful",
        "Failed to Verify OTP"
      );
      console.log("Verification Response:", response);

      if (response.status === 200) {
        dispatch(setUserData(response.data.data.data));
        navigate("/");
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Something went wrong");
      console.error("Error in registration:", error.response.data.message);
    }
  };

  return (
    <section>
      <HomeNav />
      <div className="container mx-auto pt-2 px-4">
        <div className="flex flex-col md:flex-row bg-white border rounded-lg gap-[2rem] shadow-md overflow-hidden">
          <div className="md:w-1/2">
            <img src={HeroImage} alt="Logo" className="w-full h-full object-cover" />
          </div>
          <div className="md:w-1/2 p-6 flex flex-col">
            <h2 className="text-2xl font-semibold mb-4 text-[#0097DB]">Create Account</h2>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(formSubmit)} className="flex flex-col gap-8">
                <InputField
                  name='fullName'
                  label='FULL NAME'
                  placeholder='Enter your Full Name'
                  type='text'
                  control={methods.control}
                  rules={{
                    required: "Full Name is required",
                    minLength: {
                      value: 3,
                      message: "Full Name must be at least 3 characters",
                    },
                    maxLength: { value: 50, message: "Full Name is too long" },
                  }}
                />

                <InputField
                  name='email'
                  label='EMAIL ID'
                  placeholder='Enter your email'
                  type='email'
                  control={methods.control}
                  rules={{
                    required: "Email is required",
                    pattern: {
                      value:
                        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                      message: "Enter a valid Email",
                    },
                  }}
                />

                <div className="flex flex-col space-y-2">
                  <div className="flex justify-between text-sm font-bold text-[#0097DB] -mb-2">
                    <p>VERIFY NUMBER +91 {getValues("mobileNumber")}</p>
                    <Link to="/user/login" className="text-red-500 no-underline">Change</Link>
                  </div>
                  <OTPInputField
                    name='otp'
                    numInputs={6}
                    control={methods.control}
                    rules={{
                      required: "OTP is required",
                      minLength: { value: 6, message: "Enter full OTP" },
                      maxLength: {
                        value: 6,
                        message: "OTP must be exactly 6 digits",
                      },
                      pattern: {
                        value: /^[0-9]{6}$/,
                        message: "OTP should contain only numbers",
                      },
                    }}
                  />
                  <label className="text-sm">
                    {seconds > 0 ? (
                      `Waiting for OTP...${seconds} Sec`
                    ) : (
                      <span
                        onClick={() => sendOtp(getValues("mobileNumber"))}
                        className="text-[#0097DB] cursor-pointer"
                      >
                        Click Here
                      </span>
                    )} to send OTP again
                  </label>
                </div>


                <button
                  className='bg-[#0097DB] text-white py-2 -mt-5 rounded-md  disabled:bg-gray-400'
                  type='submit'
                  disabled={loading}
                >
                  Verify
                </button>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </section>
  );
};

const InputField = ({
  name,
  label,
  placeholder,
  type = "text",
  control,
  rules = {},
  ...rest
}) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control, rules });

  return (
    <div className='flex flex-col'>
      <label htmlFor={name} className='font-bold text-[#0097DB]'>
        {label}
      </label>
      <input
        {...field}
        type={type}
        id={name}
        placeholder={placeholder}
        className=' border-gray-300 focus:outline-none border-b-2'
        {...rest}
      />
      {error && <p className='text-sm text-red-500'>{error.message}</p>}
    </div>
  );
};

const OTPInputField = ({
  name,
  label,
  numInputs = 6,
  control,
  rules = {},
  ...rest
}) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control, rules });

  return (
    <div className='flex flex-col space-y-2'>
      {/* <label className='font-medium text-gray-700'>{label}</label> */}
      <OtpInput
        {...field}
        numInputs={numInputs}
        containerStyle='flex space-x-2'
        renderInput={(props) => (
          <input
            {...props}
            style={{
              width: "3rem",
              height: "3rem",
              textAlign: "center",
              borderRadius: "8px",
              border: "1px solid #d1d5db", // Gray-300
              fontSize: "1.5rem",
              color: "black",
            }}
            className='focus:outline-none focus:ring-2 focus:ring-blue-500'
          />
        )}
        {...rest}
      />
      {error && <p className='text-sm text-red-500'>{error.message}</p>}
    </div>
  );
};

export default CreateAccount;
