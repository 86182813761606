import React from "react";
import ExpertNav from "../../components/Navbar/ExpertNav";
import Footer from "../../components/Home/footer/Footer";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setMyPath } from "../../Redux/userSlice";

export default function DoCare() {
  const dispatch = useDispatch();
  React.useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setMyPath(window.location.pathname));
  }, []);
  return (
    <div className='App w-[100%]'>
      <ExpertNav />
      <Header />
      <Helmet>
        <title> Docare: Healthcare Community | Avijo</title>
        <meta
          name='discription'
          content='Join Docare, the health community where patients ask questions and verified doctors provide trusted answers. Stay healthy with expert advice and health awareness content.'
        />
      </Helmet>
      <SupportData />
      <div className='m-4 mb-0 flex flex-col items-center justify-between rounded-[5px] pb-1 md:flex-row'>
        <div className='col w-[full] md:ml-10 md:w-1/2'>
          <div className='mb-4'>
            <ul className='mb-3 p-0'>
              <li className='d-flex align-items-center mb-3 gap-3'>
                <svg
                  width='32'
                  height='32'
                  viewBox='0 0 32 32'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <g clip-path='url(#clip0_1284_1665)'>
                    <path
                      d='M9.43797 15.0706C9.00036 14.633 8.28925 14.633 7.85164 15.0706C7.41404 15.5082 7.41404 16.2193 7.85164 16.6569L13.0209 21.8262C13.2397 22.045 13.5132 22.1544 13.814 22.1544C13.8414 22.1544 13.8414 22.1544 13.8687 22.1544C14.1696 22.127 14.4704 21.9903 14.6892 21.7441L27.626 6.23643C28.0089 5.77147 27.9542 5.06036 27.4893 4.65011C27.0243 4.2672 26.3132 4.3219 25.9029 4.78686L13.732 19.3646L9.43797 15.0706Z'
                      fill='#17AB13'
                    />
                    <path
                      d='M24.3712 2.54431C18.0806 -1.39415 10.0122 -0.464236 4.7609 4.78705C-1.4203 10.9682 -1.4203 21.0605 4.7609 27.2417C7.8515 30.3323 11.9267 31.8913 15.9746 31.8913C20.0224 31.8913 24.0977 30.3323 27.1882 27.2417C32.4669 21.9631 33.3694 13.8674 29.4036 7.57679C29.0754 7.05713 28.3917 6.89303 27.872 7.22123C27.3524 7.54944 27.1882 8.2332 27.5165 8.75286C30.9353 14.1682 30.1421 21.1153 25.6019 25.6554C20.2959 30.9614 11.6259 30.9614 6.31987 25.6554C1.01389 20.3494 1.01389 11.6794 6.31987 6.37337C10.86 1.86055 17.7797 1.06739 23.1951 4.43149C23.7147 4.7597 24.3985 4.59559 24.7267 4.07593C25.0549 3.55628 24.8908 2.87252 24.3712 2.54431Z'
                      fill='#4A4A4A'
                    />
                  </g>
                  <defs>
                    <clipPath id='clip0_1284_1665'>
                      <rect width='32' height='32' fill='white' />
                    </clipPath>
                  </defs>
                </svg>
                <div className='flex w-full flex-col items-start'>
                  <h6 className='w-[250px]'>Verified Expertise:</h6>
                  Our doctors are thoroughly vetted, ensuring you receive
                  accurate and trustworthy information.
                </div>
              </li>
              <li className='d-flex align-items-center mb-3 gap-3'>
                <svg
                  width='32'
                  height='32'
                  viewBox='0 0 32 32'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <g clip-path='url(#clip0_1284_1665)'>
                    <path
                      d='M9.43797 15.0706C9.00036 14.633 8.28925 14.633 7.85164 15.0706C7.41404 15.5082 7.41404 16.2193 7.85164 16.6569L13.0209 21.8262C13.2397 22.045 13.5132 22.1544 13.814 22.1544C13.8414 22.1544 13.8414 22.1544 13.8687 22.1544C14.1696 22.127 14.4704 21.9903 14.6892 21.7441L27.626 6.23643C28.0089 5.77147 27.9542 5.06036 27.4893 4.65011C27.0243 4.2672 26.3132 4.3219 25.9029 4.78686L13.732 19.3646L9.43797 15.0706Z'
                      fill='#17AB13'
                    />
                    <path
                      d='M24.3712 2.54431C18.0806 -1.39415 10.0122 -0.464236 4.7609 4.78705C-1.4203 10.9682 -1.4203 21.0605 4.7609 27.2417C7.8515 30.3323 11.9267 31.8913 15.9746 31.8913C20.0224 31.8913 24.0977 30.3323 27.1882 27.2417C32.4669 21.9631 33.3694 13.8674 29.4036 7.57679C29.0754 7.05713 28.3917 6.89303 27.872 7.22123C27.3524 7.54944 27.1882 8.2332 27.5165 8.75286C30.9353 14.1682 30.1421 21.1153 25.6019 25.6554C20.2959 30.9614 11.6259 30.9614 6.31987 25.6554C1.01389 20.3494 1.01389 11.6794 6.31987 6.37337C10.86 1.86055 17.7797 1.06739 23.1951 4.43149C23.7147 4.7597 24.3985 4.59559 24.7267 4.07593C25.0549 3.55628 24.8908 2.87252 24.3712 2.54431Z'
                      fill='#4A4A4A'
                    />
                  </g>
                  <defs>
                    <clipPath id='clip0_1284_1665'>
                      <rect width='32' height='32' fill='white' />
                    </clipPath>
                  </defs>
                </svg>
                <div className='flex w-full flex-col items-start'>
                  <h6 className='w-[250px]'>Preventive Healthcare:</h6>
                  We focus on education and awareness to help you prevent health
                  issues before they arise.
                </div>
              </li>
              <li className='d-flex align-items-center mb-3 gap-3'>
                <svg
                  width='32'
                  height='32'
                  viewBox='0 0 32 32'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <g clip-path='url(#clip0_1284_1665)'>
                    <path
                      d='M9.43797 15.0706C9.00036 14.633 8.28925 14.633 7.85164 15.0706C7.41404 15.5082 7.41404 16.2193 7.85164 16.6569L13.0209 21.8262C13.2397 22.045 13.5132 22.1544 13.814 22.1544C13.8414 22.1544 13.8414 22.1544 13.8687 22.1544C14.1696 22.127 14.4704 21.9903 14.6892 21.7441L27.626 6.23643C28.0089 5.77147 27.9542 5.06036 27.4893 4.65011C27.0243 4.2672 26.3132 4.3219 25.9029 4.78686L13.732 19.3646L9.43797 15.0706Z'
                      fill='#17AB13'
                    />
                    <path
                      d='M24.3712 2.54431C18.0806 -1.39415 10.0122 -0.464236 4.7609 4.78705C-1.4203 10.9682 -1.4203 21.0605 4.7609 27.2417C7.8515 30.3323 11.9267 31.8913 15.9746 31.8913C20.0224 31.8913 24.0977 30.3323 27.1882 27.2417C32.4669 21.9631 33.3694 13.8674 29.4036 7.57679C29.0754 7.05713 28.3917 6.89303 27.872 7.22123C27.3524 7.54944 27.1882 8.2332 27.5165 8.75286C30.9353 14.1682 30.1421 21.1153 25.6019 25.6554C20.2959 30.9614 11.6259 30.9614 6.31987 25.6554C1.01389 20.3494 1.01389 11.6794 6.31987 6.37337C10.86 1.86055 17.7797 1.06739 23.1951 4.43149C23.7147 4.7597 24.3985 4.59559 24.7267 4.07593C25.0549 3.55628 24.8908 2.87252 24.3712 2.54431Z'
                      fill='#4A4A4A'
                    />
                  </g>
                  <defs>
                    <clipPath id='clip0_1284_1665'>
                      <rect width='32' height='32' fill='white' />
                    </clipPath>
                  </defs>
                </svg>
                <div className='flex w-full flex-col items-start'>
                  <h6 className='w-[250px]'>Community-Centered:</h6>
                  Docare is more than just a Q&A platform; it’s a community
                  where individuals come together to support each other in their
                  health journeys.
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className='w-full p-4 pb-0 md:w-1/2'>
          <img
            src={require("../../Assets/image/docare3.png")}
            className='rounded-2 h-[310px] w-[500px] md:ml-10'
          />
        </div>
      </div>
      <div className='mt-10 flex w-full flex-col items-center'>
        <h1 className='text-center font-[Gilroy-SemiBold] text-[36px] text-black'>
          Join Us{" "}
        </h1>
        <p className='mt-5 w-[80%] text-center font-[Gilroy-SemiBold] text-[18px] text-[#616161]'>
          Dr Jii serves as a health information platform, bringing together
          healthcare citizens and providers. By leveraging data from the Avijo
          ecosystem, Dr Jii enhances medical consultations, supports diagnosis,
          and optimizes care delivery. Importantly, Dr Jii is designed to
          complement medical advice—not replace it. Always consult a licensed
          healthcare professional for medical decisions
        </p>
      </div>
      <div className='mt-10 flex w-full flex-col items-center'>
        <h1 className='text-center font-[Gilroy-SemiBold] text-[36px] text-black'>
          Security and Data Privacy
        </h1>
        <p className='mt-5 w-[80%] text-center font-[Gilroy-SemiBold] text-[18px] text-[#616161]'>
          Your privacy is our priority. Dr Jii operates under strict data
          security measures, ensuring that all personal and medical information
          is protected and stored securely.
        </p>
      </div>
      <div className='mt-10 flex w-full flex-col items-center'>
        <h1 className='text-center font-[Gilroy-SemiBold] text-[36px] text-black'>
          Our Vision
        </h1>
        <p className='mt-5 w-[80%] text-center font-[Gilroy-SemiBold] text-[18px] text-[#616161]'>
          We envision a global community where knowledge is the foundation of
          health, and everyone has access to the information they need to lead a
          healthy life. With Docare, we’re taking a step closer to making this
          vision a reality.
        </p>
      </div>
      <div className='mb-1 mt-10 flex w-full flex-col items-center'>
        <Link to='/downloadapp' className='no-underline'>
          <button className='border-1 ml-4 mt-4 flex h-10 w-[200px] flex-row items-center justify-center rounded-full border-[black] bg-[white]'>
            <span className='self-center font-[Gilroy-SemiBold] text-[16px] text-[black]'>
              Download the app
            </span>
            <img
              src={require("../../Assets/image/right2.png")}
              className='ml-2 h-[20px] w-[20px]'
            />
          </button>
        </Link>
        <p className='mt-5 w-[80%] text-center font-[Gilroy-SemiBold] text-[18px] text-[#616161]'>
          Together, let's build a healthier future by
        </p>
      </div>
      <Footer />
    </div>
  );
}

const Header = () => {
  return (
    <div className='mb-10 flex w-full flex-col items-center'>
      <h1 className='text-center font-[Gilroy-SemiBold] text-[36px] text-black'>
        Docare
      </h1>
      <p className='mt-5 w-[80%] text-center font-[Gilroy-SemiBold] text-[18px] text-[#616161]'>
        Welcome to Docare, your trusted health community powered by Avijo. At
        Docare, we believe in the power of knowledge and communication to
        transform healthcare. Our platform connects individuals/patients with
        verified doctors, fostering an environment where health-related
        questions are answered with expertise and care
      </p>
      <div className='flex flex-row'>
        <Link to='/' className='no-underline'>
          <button className='mt-4 flex h-10 w-[130px] flex-row items-center justify-center rounded-full bg-[#0097DB]'>
            <span className='text-sm text-white'>Start now</span>
            <img
              src={require("../../Assets/image/file.png")}
              className='ml-2 h-[20px] w-[20px]'
              alt='icon'
            />
          </button>
        </Link>
        <Link to='/downloadapp' className='no-underline'>
          {" "}
          <button className='border-1 ml-4 mt-4 flex h-10 w-[200px] flex-row items-center justify-center rounded-full border-[black] bg-[white]'>
            <span className='self-center font-[Gilroy-SemiBold] text-[16px] text-[black]'>
              Download the app
            </span>
            <img
              src={require("../../Assets/image/right2.png")}
              className='ml-2 h-[20px] w-[20px]'
            />
          </button>
        </Link>
      </div>
    </div>
  );
};

const SupportData = () => {
  const data = [
    {
      id: 0,
      heading: "A Hub for Preventive Healthcare",
      image: require("../../Assets/image/docare1.png"),
      points: [
        {
          id: 0,
          text: "Our mission is to create a world where healthcare is not just about treating illness but about preventing it. Through Docare, we provide a space for open dialogue between individual and healthcare professionals, enabling everyone to make informed decisions about their health. By promoting preventive healthcare, we aspire to empower individuals to live healthier, happier lives.",
        },
      ],
    },
    {
      id: 1,
      heading: "What We Do",
      image: require("../../Assets/image/docare2.png"),
      points: [
        {
          id: 0,
          text: " Docare offers a unique platform where you can ask health-related questions and get reliable answers from certified doctors. But it doesn’t stop there. Our doctors also create educational content designed to raise awareness about various health topics, from understanding diseases and recognizing symptoms to adopting healthy lifestyle habits.",
        },
      ],
    },
  ];

  return (
    <>
      {data.map((item, index) =>
        index % 2 === 0 ? (
          index % 2 === 0 && (
            <div
              key={index}
              className='m-4 flex flex-col items-center justify-between rounded-[5px] pb-4 md:flex-row'
            >
              <div className='flex h-full w-full flex-col p-2 md:w-1/2'>
                <div className='flex-grow'>
                  <h3 className='font-gilroy font-semiBold mt-4 text-[36px]'>
                    {item.heading}
                  </h3>
                  <ul className='list-disc pl-0'>
                    {item.points.map((point, idx) => (
                      <div
                        key={idx}
                        className='font-gilroy mt-4 text-[20px] font-medium text-[#424242]'
                      >
                        <p className='expert-paragraph'>{point.text}</p>
                      </div>
                    ))}
                  </ul>
                </div>
              </div>
              <div className='w-full p-4 pb-0 md:w-1/2'>
                <img
                  src={item.image}
                  className='rounded-2 h-[310px] w-[500px] md:ml-10'
                />
              </div>
            </div>
          )
        ) : (
          <div
            key={index}
            className='m-4 flex flex-col items-center justify-between rounded-[5px] pb-4 md:flex-row'
          >
            <div className='w-full p-4 pb-0 md:w-1/2'>
              <img src={item.image} className='rounded-2 h-[310px] w-[500px]' />
            </div>
            <div className='flex h-full w-full flex-col p-2 md:w-1/2'>
              <div className='flex-grow'>
                <h3 className='font-gilroy font-semiBold mt-4 text-[36px]'>
                  {item.heading}
                </h3>
                <ul className='list-disc pl-0'>
                  {item.points.map((point, idx) => (
                    <div
                      key={idx}
                      className='font-gilroy mt-4 text-[20px] font-medium text-[#424242]'
                    >
                      <p className='expert-paragraph'>{point.text}</p>
                    </div>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};
