import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import ExpertNav from "./Navbar/ExpertNav";
import { BaseUrl, timeAgo, BaseUrlAdmin } from "../Routes/BaseUrl";
import { Helmet } from "react-helmet";
import Footer from "./Home/footer/Footer";
import axios from "axios";

export default function Blog() {
  const location = useLocation();
  const id = (location.state && location.state.id);
  let blogTitle = location.pathname.split("/")[2];
  const [blog, setBlog] = useState({});

  const fetchBlog = async () => {
    try {
      const response = await axios.put(
        `${BaseUrlAdmin}/admin/blog/getSingleBlog`, { id: id, slug: blogTitle }); // Marked PUT because it will allow us to send data to backend as it is better to find a blog by its id or slug, as get will only allow to do this with one way also by adding query params
      const json = response.data;
      if (response.status === 200) {
        setBlog(json.data);
      }
    } catch (e) {
      console.log("error fetching blog...", e);
    }
  };

  function toTitleCase(str) {
    const smallWords = ['a', 'an', 'and', 'but', 'for', 'nor', 'of', 'on', 'or', 'so', 'the', 'to', 'up', 'yet', 'with'];
    if (!str) return null;
    return str
      .toLowerCase()
      .split(' ')
      .map((word, index) => {
        if (index === 0 || index === str.split(' ').length - 1 || !smallWords.includes(word)) {
          return word.charAt(0).toUpperCase() + word.slice(1);
        } else {
          return word;
        }
      })
      .join(' ');
  }


  useEffect(() => {
    fetchBlog();
  }, []);

  return (
    <>
      <Helmet>
        <title>{blog?.title ? blog.title : "Blog"} | Avijo</title>
        <meta
          name='discription'
          content={`Read our blog, ${blog?.title ? blog.title : "Blog"}, for valuable insights into healthcare, wellness, and technology. Stay informed and empowered with Avijo.`}
        />
      </Helmet>
      <div className='flex w-full flex-col items-center gap-1'>
        <ExpertNav />
        <h4 className='w-[80%] pt-2'>
          {toTitleCase(blog.title)}
          <span className='line-clamp-2 text-sm text-[Gilroy-SemiBold] text-black'>
            {blog.createdAt && timeAgo(blog.createdAt)}, by - <span className="italic">{blog?.postedBy}</span>
          </span>
        </h4>
        <div className='w-full bg-transparent'>
          <img
            src={blog.imageUrl}
            className='h-[400px] w-2/4 mx-auto object-fit'
          />
        </div>
        <div
          className='mt-3 w-[80%]'
          dangerouslySetInnerHTML={{ __html: blog.content }}
        />
      </div>

      <Footer />
    </>
  );
}
