import React, { useEffect, useState } from "react";
import HomeNav from "../../components/Users/navbar/HomeNav";
import SecondNavbar from "../../components/Users/navbar/SecondNavbar";
import SearchSection from "../../components/Users/Ui/SearchSection";
import toast from "react-hot-toast";
import FindLocation from "../FindLocation";
import { IoLocationOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { HomeDetailedCard, HomeSimpleCard, HomeSlider } from "../../components";
import {
  commonHealthIssue,
  commonSymptoms,
  ourSpecialistConcern,
  serviceData,
} from "./data";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setMyPath } from "../../Redux/userSlice";

const Home = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const storedLocation = useSelector((state) => state.user.userLocationAvijo);

  const handleSubmit = () => {
    console.log(storedLocation);
    if (storedLocation === null || storedLocation.trim() === "") {
      toast.error("Please enter your location.");
    } else {
      setIsModalOpen(false);
      setTimeout(() => {
        navigate("/user");
      }, 100);
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "scroll";
    }
    return () => {
      document.body.style.overflowY = "scroll";
    };
  }, [isModalOpen]);

  useEffect(() => {
    if (
      !storedLocation ||
      storedLocation.trim() === "" ||
      storedLocation === "null" ||
      storedLocation === "undefined"
    )
      setIsModalOpen(true);
    else {
      navigate("/user");
      setIsModalOpen(false);
    }
    dispatch(setMyPath(window.location.pathname));
  }, []);

  return (
    <div className='bg-gradient-to-b from-[#D4EAF2] to-[#EDF4F6]'>
      <HomeNav />
      <SecondNavbar />
      <div className='mx-auto flex w-11/12 flex-col'>
        <SearchSection />

        <ServiceSlider />

        <MostCommonSymptom />

        <CommonHealthIssue />

        <SpecialistConcern />
      </div>

      {isModalOpen && (<FindLocation handleSubmit={handleSubmit} />
      )}
    </div>
  );
};

export default Home;

const ServiceSlider = () => {
  return (
    <div>
      <div className='flex justify-end'>
        <button className='border-1 rounded-lg border-[#0097DB] bg-blue-100 p-1 px-3 text-[#0097DB]'>
          View All
        </button>
      </div>

      <div>
        <HomeSlider CardComponent={HomeDetailedCard} data={serviceData} />
      </div>
    </div>
  );
};

const MostCommonSymptom = () => {
  return (
    <div>
      <h2>Most Common Symptoms</h2>

      <div>
        <HomeSlider CardComponent={HomeDetailedCard} data={commonSymptoms} />
      </div>
    </div>
  );
};

const CommonHealthIssue = () => {
  return (
    <div>
      <h2>Common Health Issues</h2>

      <div>
        <HomeSlider CardComponent={HomeDetailedCard} data={commonHealthIssue} />
      </div>
    </div>
  );
};

const SpecialistConcern = () => {
  return (
    <div>
      <HomeSlider
        CardComponent={HomeDetailedCard}
        data={ourSpecialistConcern}
      />
    </div>
  );
};
