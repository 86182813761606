import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../../../axiosConfig";
import OTPInput from "react-otp-input";
import { BaseUrl } from "../../../Routes/BaseUrl";
import { toastHandler } from "../../../utils/toastHandler";
import { useDispatch } from "react-redux";
import { setUserData } from "../../../Redux/userSlice";
import HomeNav from "../navbar/HomeNav";
import { Link } from "react-router-dom";

const UserLoginOtp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { control, handleSubmit, setValue } = useForm();

  const [phone, setPhone] = useState("");
  const [seconds, setSeconds] = useState(60);

  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => Math.max(prevSeconds - 1, 0));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const mobileNumber = location.state?.mobileNumber;
    if (mobileNumber) {
      setPhone(mobileNumber);
    }
  }, [location.state]);

  const onSubmit = async (data) => {
    if (data.otp.length !== 6) {
      return alert("Please enter a 6-digit OTP");
    }
    try {
      const res = await axios.post(`/user/verifyLogin`, {
        mobileNumber: phone,
        otp: data.otp,
      });
      const response = await toastHandler(
        res,
        "Verifying OTP",
        "OTP Verified Successfully",
        "Failed to Verify OTP"
      );
      if (response.status === 200) {
        dispatch(setUserData(response.data.data));
        navigate("/");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
    }
  };

  const handleResendOtp = async () => {
    try {
      const res = axios.post(`${BaseUrl}/user/send-otp`, {
        mobileNumber: phone,
      });
      await toastHandler(res, "Sending OTP", "OTP Sent Successfully", "Failed to send OTP");
      setSeconds(60);
    } catch (error) {
      console.error("Error resending OTP:", error);
    }
  };

  return (
    <section>
      <HomeNav />
      <div className="container pt-4">
        <div className="row rounded border bg-white">
          <div className="col-md-6 ps-0">
            <img src={require("../../../Assets/image/5093164.jpg")} alt="Logo" className="hero-w" />
          </div>
          <div className="flex flex-col col-md-6 hero_left pt-5">
            <h2>Sign in</h2>
            <p>Sign in to access your account and exclusive offers.</p>

            <label>PHONE NUMBER</label>
            <br />
            <div className="flex flex-row items-center">
              <span className="border-b border-gray-300 -mt-[6px] pb-[10px]">+91 |</span>
              <input
                type="text"
                className="phone-input mb-3"
                placeholder="Enter Your Mobile"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              <Link to={"/user/login"} className="border-b no-underline border-gray-300 text-red-600 -mt-[6px] pb-[10px]">Change</Link>
            </div>

            <label className="mb-3 mt-0">Enter the 6-digit OTP</label>

            <Controller
              name="otp"
              control={control}
              rules={{
                required: "OTP is required",
                minLength: { value: 6, message: "OTP must be 6 digits" },
                maxLength: { value: 6, message: "OTP must be 6 digits" },
              }}
              render={({ field, fieldState: { error } }) => (
                <div className="flex flex-col space-y-2">
                  <OTPInput
                    {...field}
                    numInputs={6}

                    shouldAutoFocus
                    containerStyle="d-flex gap-2"
                    inputStyle={{ width: "50px", height: "50px", textAlign: "center", fontSize: "20px", border: "1px solid #ccc", }}
                    renderInput={(props) => <input {...props} />}
                    onChange={(otp) => field.onChange(otp)}
                  />
                  {error && <p className="text-sm text-red-500">{error.message}</p>}
                </div>
              )}
            />

            {seconds > 0 ? (
              <label>Waiting for OTP... {seconds} Sec</label>
            ) : (
              <label
                className="cursor-pointer font-semibold text-[#00C1DE]"
                onClick={handleResendOtp}
              >
                Resend OTP
              </label>
            )}

            <button className="my-2 text-center" onClick={handleSubmit(onSubmit)}>
              Verify
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UserLoginOtp;
