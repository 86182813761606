import axios from 'axios';
import { BaseUrl } from './Routes/BaseUrl';

const instance = axios.create({
  baseURL: BaseUrl,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default instance; 