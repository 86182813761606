import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ExpertNav from "../components/Navbar/ExpertNav";
import Footer from "../components/Home/footer/Footer";
import { Helmet } from "react-helmet";
const HppHome = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='App w-[100%]'>
      <ExpertNav />
      <Header />
      <Helmet>
        <title>Avijo HPP | Avijo</title>
        <meta
          name='discription'
          content='Connect with healthcare professionals and facilities through Avijo HPP. Promote health products and medicines, advertise effectively, and build partnerships in the healthcare ecosystem.'
        />
        <link rel='icon' href='./avijohpp.ico' />
      </Helmet>
      <Carousel />
      <Section1 />
      <Section2 />
      <Footer />
    </div>
  );
};

const Header = () => {
  const navigate = useNavigate();
  return (
    <header className='hero bg-gradient-to-r from-[#F6F6F6] to-[#C8C8C8]'>
      <div class='flex flex-col justify-between p-4 md:flex-row'>
        <div className='flex h-full w-full flex-col p-2 md:w-1/2'>
          <div className='flex-grow'>
            <span className='font-gilroy text-[16px] font-[600] text-[#535353]'>
              AI-POWERED SUPPLY CHAIN MANAGEMENT PLATFORM AUTOMATE & OPTIMIZE
              END-TO-END SUPPLY CHAIN OPERATIONS
            </span>
            <div className='flex flex-row items-end'>
              <h1 className='font-semiBold font-[Gilroy-SemiBold] text-[45px] text-[#535353]'>
                avijo
              </h1>
              <h6 className='text-xl italic text-[#FFCA38]'>HPP</h6>
            </div>
            <span className='mb-5 text-xl font-[500] text-[#535353]'>
              Kickstart your digitalisation journey today!
            </span>
          </div>
          <span className='font-gilroy mt-2 text-[16px] font-[400] text-[#535353]'>
            Struggling with inefficient outdated manual processes, error-prone
            data management, and lack real-time visibility.
          </span>

          <button className='mt-9 flex h-12 w-[130px] items-center justify-center rounded-lg bg-[#0097DB]'>
            <a
              href='https://hpp.avijo.in/'
              target='_blank'
              rel='noopener noreferrer'
              style={{ textDecoration: "none", color: "white" }}
              className='flex items-center gap-2 text-lg'
            >
              Get Started
              <img
                src={require("../Assets/image/diagonal_arrowWhite.png")}
                className='h-[14px] w-[18px]'
              />
            </a>
          </button>
        </div>
        <div className='w-full p-4 md:w-1/2'>
          <img
            src='https://www.mynuvancehealthbenefits.org/commonImages/login_main.png'
            alt='header'
            className='h-80 w-auto pl-12 md:ml-20'
          />
        </div>
      </div>
    </header>
  );
};

const Carousel = () => {
  return (
    <div id='carouselExample' class='carousel slide'>
      <div class='carousel-inner'>
        <div class='carousel-item active flex flex-col items-center bg-[#FFF0F0]'>
          <h2 className='ml-[20%] w-[60%] self-center pt-5 text-center text-4xl'>
            Efficiency, Visibility, Control.
          </h2>
          <div className='ml-10 mt-2 flex w-[90%] flex-row items-center justify-center'>
            <span className='text-[32px] font-semibold text-black'>
              Optimise your operations and elevate your success with avijo
              HPP.{" "}
            </span>
          </div>
          <div class='flex flex-col items-center justify-between p-4 md:flex-row'>
            <div className='flex h-full w-full flex-col p-2 md:w-[60%]'>
              <div className='flex-grow'>
                <h1 className='ml-4 text-2xl text-black'>
                  Comprehensive Patient Management
                </h1>
                <h1 className='ml-4 text-lg text-black'>
                  Real-Time Visibility
                </h1>
                <div className='mt-2 flex flex-row items-start'>
                  <img
                    src={require("../Assets/image/arrow.png")}
                    className='mr-2 mt-1 h-[13px] w-[9px]'
                  />
                  <span className='text-sm font-[400] text-black'>
                    Gain transparency & control of your operations with
                    end-to-end visibility of upply chain, from order to
                    delivery.
                  </span>
                </div>
                <h1 className='ml-4 text-lg text-black'>
                  Enhanced Business Agility & Efficiency
                </h1>
                <div className='mt-2 flex flex-row items-start'>
                  <img
                    src={require("../Assets/image/arrow.png")}
                    className='mr-2 mt-1 h-[13px] w-[9px]'
                  />
                  <span className='text-sm font-[400] text-black'>
                    Provides a unified dashboard for real-time monitoring of
                    healthcare operations
                  </span>
                </div>
                <h1 className='ml-4 text-lg text-black'>
                  Seamless Collaboration on One Platform
                </h1>
                <div className='mt-2 flex flex-row items-start'>
                  <img
                    src={require("../Assets/image/arrow.png")}
                    className='mr-2 mt-1 h-[13px] w-[9px]'
                  />
                  <span className='text-sm font-[400] text-black'>
                    Ensures compliance with national and international
                    healthcare regulations
                  </span>
                </div>
              </div>
              <div className='flex flex-row'>
                <button className='w-1/8 border-1 mr-4 mt-4 rounded-lg border-[#0097DB] bg-[#0097DB] p-2'>
                  <a
                    href='https://hpp.avijo.in/'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='flex items-center gap-2 text-lg text-white no-underline'
                  >
                    <span className='text-sm text-white'>Try it</span>
                    <img
                      src={require("../Assets/image/diagonal_arrowWhite.png")}
                      className='h-[14px] w-[18px]'
                      alt='arrow'
                    />
                  </a>
                </button>
                <button className='mt-4 w-40 rounded-lg bg-[#0097DB] p-2'>
                  <a
                    href='https://hpp.avijo.in/'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='flex items-center gap-2 text-lg text-white no-underline'
                  >
                    <span className='text-sm text-white'>
                      {" "}
                      Register for Demo
                    </span>
                    <img
                      src={require("../Assets/image/diagonal_arrowWhite.png")}
                      className='h-[14px] w-[18px]'
                      alt='arrow'
                    />
                  </a>
                </button>
              </div>
            </div>
            <div className='h-[260px] w-[500px] p-4 md:w-[40%]'>
              <img
                src='https://s3-alpha-sig.figma.com/img/93e8/2579/bc268683ab32d11186aa2ebc29108500?Expires=1739145600&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=B7abdQGCzdsYpFgjDARgjf9gpu66gmMhtD-2jlTCmdVmJfChk98h69PuFYQvp7bQ4vWMoF157VTNLn6EXi8OeCjLB7O49yH53JfCXilmaQphPzZR3~9hhBy4z2Ca7wag2tdmiIU9orM58rPDbhafvo5mwOphangqQFyAVWBlvdTaGgLO9mQuWLOPvKiAXtLspmaEEIjYlSsSNj22M5eKwN5qJcTpwqIiDp7qtKbWI7drGW5SNw7NSgoSEEOvY0i3i0Wa1aqXitH1CrRhEU1oeup-LiByRFcpee5Gi3YTh9wrkBqW~yYiqr5jAyvMjI7oYifqtj~3N4cE7RkbvrhGUQ__'
                alt='header'
                className='h-[240px] w-[100%]'
              />
            </div>
          </div>
        </div>
        <div class='carousel-item flex flex-col items-center bg-[#FAEFD4]'>
          <h2 className='ml-[20%] w-[60%] self-center pt-5 text-center text-4xl'>
            SUPPLY CHAIN MANAGEMENT SOLUTIONS
          </h2>
          <div className='ml-10 mt-2 flex w-[90%] flex-row items-center justify-center'>
            <span className='text-center text-[32px] font-semibold text-black'>
              Optimize your operations and grow your business with our
              comprehensive solutions.
            </span>
          </div>
          <div class='flex flex-col items-center justify-between p-4 md:flex-row'>
            <div className='flex h-full w-full flex-col p-2 md:w-[60%]'>
              <div className='flex-grow'>
                <h1 className='ml-4 text-2xl text-black'>
                  AI-Powered Operations
                </h1>
                <div className='mt-2 flex flex-row items-start'>
                  <img
                    src={require("../Assets/image/arrow.png")}
                    className='mr-2 mt-1 h-[13px] w-[9px]'
                  />
                  <span className='font-gilroy text-[20px] font-medium text-black'>
                    Leveraging advanced technology and AI capabilities, we
                    offer seamless connection, greater visibility, and meet
                    operational needs in the Industry 4.0 era. Our user-friendly
                    modular solutions cater to client needs, offering a dynamic,
                    scalable, and flexible platform.
                  </span>
                </div>
                <div className='mt-2 flex flex-row items-start'>
                  <img
                    src={require("../Assets/image/arrow.png")}
                    className='mr-2 mt-1 h-[13px] w-[9px]'
                  />
                  <span className='font-gilroy text-[20px] font-medium text-black'>
                    With deep domain expertise and extensive knowledge, as a
                    global trading powerhouse in the automotive industry, avijo
                    constantly progresses and builds capabilities to provide a
                    one-stop supply chain management (SCM) solution.
                  </span>
                </div>
              </div>
              <div>
                <button className='w-1/8 border-1 mr-4 mt-4 rounded-lg border-[#0097DB] bg-[#0097DB] p-2'>
                  <a
                    href='https://hpp.avijo.in/'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='flex items-center gap-2 text-lg text-white no-underline'
                  >
                    <span className='text-sm text-white'>Try it</span>
                    <img
                      src={require("../Assets/image/diagonal_arrowWhite.png")}
                      className='h-[14px] w-[18px]'
                      alt='arrow'
                    />
                  </a>
                </button>
              </div>
            </div>
            <div className='md:w-50 h-[350px] w-[350] p-4'>
              <img
                src='https://s3-alpha-sig.figma.com/img/e612/975c/eb894bd953f066e804a816ce902ec273?Expires=1739145600&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=Qw1oiIw~jw-7vtn6XIdXcsxn1OQhmbFAXDM2rSjrSDSCOkJnktbQJAtroOsOvnCmI9sB-azXHDiuh3A9tLBc2vva49EhacoymDjJIhMJImX6JpojJCLIp2SdRCcf~DFOLxWkw1bk4JRd-eChjErUb~TY1UC-WLWBfxJYxFTZLmfw2kJAoT2vmrhnO1vHeNuNcalU4mUx~AOu71ftjfFhRH7rGSv3Vwc4j3n5u5wMRD18MRlXvjDetOcqQkwYNcvJP5B3tAxvQhDL9-xj7eBnV5UD1~BGuNiwbA1e7jhqwJ~SBMJ0PusPtP1d49JWGUOzcH3RTnUeWH~cG57sBGovUQ__'
                alt='header'
                className='h-full w-full'
              />
            </div>
          </div>
        </div>
      </div>
      <button
        className='absolute left-0 top-1/2 -translate-y-1/2 transform p-2'
        data-bs-target='#carouselExample'
        data-bs-slide='prev'
        style={{ backgroundColor: "transparent" }}
      >
        <img
          src={require("../Assets/image/left.png")}
          alt='Previous'
          className='h-8 w-8'
        />
      </button>
      <button
        className='absolute right-0 top-1/2 -translate-y-1/2 transform p-2'
        data-bs-target='#carouselExample'
        data-bs-slide='next'
        style={{ backgroundColor: "transparent" }}
      >
        <img
          src={require("../Assets/image/right.png")}
          alt='Next'
          className='h-8 w-8'
        />
      </button>
    </div>
  );
};

const Section1 = () => {
  return (
    <section className='border-1 flex w-[100%] flex-col items-center pb-5'>
      <img
        src='https://s3-alpha-sig.figma.com/img/33df/b09e/9697362488f1500d7ef6147437d5ae27?Expires=1739145600&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=MllaVu~m55mSiADyrSeubrh7ZN7g5J-6MvSdPy9U85NkTjFrUq0oLIudeNOBurh~SoHi9v-w-6n6my5QThdBmqahdcVT7uXbaATKaNrBmyOIj6mP0TSYQFKPRxkLX2Gw76xSwBf8CW0hqUIJd0wqDhIzciv6CznlqOP9~AjxX6GxqAqI0SPZzoeEIlkEIQ5yhT2cVl~rOQJsAt9f6VhBei~3f9bX1-Qr8L6yqTSulS8LhAxqlBgKE5aMhSL6So6bxKN5P29bLSW2G3I7rvoEAog8cgSKh5u3g9LN9xd9ugNq2z9rZ~8ltNk4RhegOD9WTRyDR04jhaXhRW9xpKUz6A__'
        className='mt-5 h-[272px] w-[90%]'
        alt='img'
      />
      <span className='mt-3 w-[90%] text-[27px] text-lg font-[500]'>
        Integrate your data sources (internal & external) on avijo HPP to gain
        insights on your production and stimulate your order process to better
        understand your production capacity.
      </span>
      <div className='ml-10 mt-5 flex w-[90%] flex-col items-start justify-between md:flex-row'>
        <div className='flex-grow'>
          <h1 className='ml-4 text-2xl text-black'>Shipment Management</h1>
          <div className='mt-2 flex flex-row items-start'>
            <img
              src={require("../Assets/image/arrow.png")}
              className='mr-2 mt-1 h-[13px] w-[9px]'
            />
            <span className='text-sm font-[400] text-black'>
              Intelligent Cargo Tracking
            </span>
          </div>
          <div className='mt-2 flex flex-row items-start'>
            <img
              src={require("../Assets/image/arrow.png")}
              className='mr-2 mt-1 h-[13px] w-[9px]'
            />
            <span className='text-sm font-[400] text-black'>
              Real-time ETA predictions
            </span>
          </div>
        </div>
        <div className='flex-grow'>
          <h1 className='ml-4 text-2xl text-black'>Inventory Management</h1>
          <div className='mt-2 flex flex-row items-start'>
            <img
              src={require("../Assets/image/arrow.png")}
              className='mr-2 mt-1 h-[13px] w-[9px]'
            />
            <span className='text-sm font-[400] text-black'>
              {" "}
              Demand/Forecast Planning
            </span>
          </div>
          <div className='mt-2 flex flex-row items-start'>
            <img
              src={require("../Assets/image/arrow.png")}
              className='mr-2 mt-1 h-[13px] w-[9px]'
            />
            <span className='text-sm font-[400] text-black'>
              Centralized Inventory Monitoring
            </span>
          </div>
          <div className='mt-2 flex flex-row items-start'>
            <img
              src={require("../Assets/image/arrow.png")}
              className='mr-2 mt-1 h-[13px] w-[9px]'
            />
            <span className='text-sm font-[400] text-black'>
              {" "}
              Inventory Simulation
            </span>
          </div>
          <button className='w-1/8 border-1 mr-4 mt-4 rounded-lg border-[#0097DB] bg-[#0097DB] p-2'>
            <a
              href='https://hpp.avijo.in/'
              target='_blank'
              rel='noopener noreferrer'
              className='flex items-center gap-2 text-lg text-white no-underline'
            >
              <span className='text-sm text-white'>Try it</span>
              <img
                src={require("../Assets/image/diagonal_arrowWhite.png")}
                className='h-[14px] w-[18px]'
                alt='arrow'
              />
            </a>
          </button>
        </div>
        <div className='flex-grow'>
          <h1 className='ml-4 text-2xl text-black'>
            Order, Delivery & Invoice Management
          </h1>
          <div className='mt-2 flex flex-row items-start'>
            <img
              src={require("../Assets/image/arrow.png")}
              className='mr-2 mt-1 h-[13px] w-[9px]'
            />
            <span className='text-sm font-[400] text-black'>
              Order & Delivery Process Management
            </span>
          </div>
          <div className='mt-2 flex flex-row items-start'>
            <img
              src={require("../Assets/image/arrow.png")}
              className='mr-2 mt-1 h-[13px] w-[9px]'
            />
            <span className='text-sm font-[400] text-black'>
              {" "}
              Supplier Management
            </span>
          </div>
          <div className='mt-2 flex flex-row items-start'>
            <img
              src={require("../Assets/image/arrow.png")}
              className='mr-2 mt-1 h-[13px] w-[9px]'
            />
            <span className='text-sm font-[400] text-black'>
              SKU/Parts Management & Visualization
            </span>
          </div>
          <div className='mt-2 flex flex-row items-start'>
            <img
              src={require("../Assets/image/arrow.png")}
              className='mr-2 mt-1 h-[13px] w-[9px]'
            />
            <span className='text-sm font-[400] text-black'>
              {" "}
              Automated Invoice Generation
            </span>
          </div>
          <div className='mt-2 flex flex-row items-start'>
            <img
              src={require("../Assets/image/arrow.png")}
              className='mr-2 mt-1 h-[13px] w-[9px]'
            />
            <span className='text-sm font-[400] text-black'>
              Online Communication
            </span>
          </div>
          <button className='mt-4 w-40 rounded-lg bg-[#0097DB] p-2'>
            <a
              href='https://hpp.avijo.in/'
              target='_blank'
              rel='noopener noreferrer'
              className='flex items-center gap-2 text-lg text-white no-underline'
            >
              <span className='text-sm text-white'> Register for Demo</span>
              <img
                src={require("../Assets/image/diagonal_arrowWhite.png")}
                className='h-[14px] w-[18px]'
                alt='arrow'
              />
            </a>
          </button>
        </div>
      </div>
    </section>
  );
};

const Section2 = () => {
  return (
    <div className='border-1 flex w-[100%] flex-col items-center pb-1'>
      <h2 className='w-[60%] self-center pt-5 text-center text-4xl'>
        Simplify, Integrate, Optimise.
      </h2>
      <div className='mt-2 flex w-[90%] flex-row items-center justify-center'>
        <span className='text-[20px] font-semibold text-black'>
          Unlock the benefits of an interconnected and streamlined supply chain
          with avijo HPP!
        </span>
      </div>
      <div className='ml-10 mt-5 flex w-[90%] flex-col items-start justify-between md:flex-row'>
        <div className='flex w-full flex-col md:w-1/2'>
          <h1 className='text-2xl text-black'>Distribution Centre</h1>
          <span className='pr-2 text-sm font-[400] text-black'>
            Gain clear visualisation & effectively manage all pending orders,
            billings and shipment statuses, with real-time data updates, on a
            single platform.
          </span>
          <span className='text-sm font-[400] text-black'>
            Real-Time Incoming Cargo Updates
          </span>
          <div className='mt-2 flex flex-row items-start'>
            <img
              src={require("../Assets/image/arrow2.png")}
              className='mr-2 h-[20px] w-[20px]'
            />
            <span className='text-sm font-[400] text-black'>
              Intelligent Cargo Tracking
            </span>
          </div>
        </div>
        <div className='flex w-full flex-col md:w-1/2'>
          <h1 className='text-2xl text-black'>Business Unit</h1>
          <span className='pr-2 text-sm font-[400] text-black'>
            Enhance the interoperability of your systems and stay in sync with
            real-time integrated updates.
          </span>
          <span className='text-sm font-[400] text-black'>
            Manage internal resources & operations
          </span>
          <div className='mt-2 flex flex-row items-start'>
            <img
              src={require("../Assets/image/arrow2.png")}
              className='mr-2 h-[20px] w-[20px]'
            />
            <span className='text-sm font-[400] text-black'>
              Enterprise Resource Systems
            </span>
          </div>
        </div>
      </div>
      <div className='ml-10 mt-5 flex w-[90%] flex-col items-start justify-between md:flex-row'>
        <div className='flex w-full flex-col md:w-1/2'>
          <h1 className='text-2xl text-black'>Customer</h1>
          <span className='pr-2 text-sm font-[400] text-black'>
            Mitigate communication breakdown and save the hassle of multi-logins
            & manual information input by collaborating & integrating with all
            your suppliers on a single platform
          </span>
          <span className='text-sm font-[400] text-black'>
            Real-Time Incoming Cargo Updates
          </span>
          <div className='mt-2 flex flex-row items-start'>
            <img
              src={require("../Assets/image/arrow2.png")}
              className='mr-2 h-[20px] w-[20px]'
            />
            <span className='text-sm font-[400] text-black'>
              Customer Ordering system
            </span>
          </div>
        </div>
        <div className='flex w-full flex-col md:w-1/2'>
          <h1 className='text-2xl text-black'>Supplier</h1>
          <span className='pr-2 text-sm font-[400] text-black'>
            Gain data-driven insight & calculate optimal order points to reduce
            safety inventory required, allowing for better cashflow.
          </span>
          <span className='text-sm font-[400] text-black'>
            Monitor Balance Orders, Shipment Status & Billing Details
          </span>
          <div className='mt-2 flex flex-row items-start'>
            <img
              src={require("../Assets/image/arrow2.png")}
              className='mr-2 h-[20px] w-[20px]'
            />
            <span className='text-sm font-[400] text-black'>
              Supplier Ordering System
            </span>
          </div>
        </div>
      </div>
      <button className='mt-4 w-40 rounded-lg bg-[#0097DB] p-2'>
        <a
          href='https://hpp.avijo.in/'
          target='_blank'
          rel='noopener noreferrer'
          className='flex items-center gap-2 text-lg text-white no-underline'
        >
          <span className='text-sm text-white'> Register for Demo</span>
          <img
            src={require("../Assets/image/diagonal_arrowWhite.png")}
            className='h-[14px] w-[18px]'
            alt='arrow'
          />
        </a>
      </button>
    </div>
  );
};

export default HppHome;
