import React, { useState } from "react";
import ExpertNav from "../../components/Navbar/ExpertNav";
import Footer from "../../components/Home/footer/Footer";
import { Helmet } from "react-helmet";
import { FaTimes } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { setMyPath } from "../../Redux/userSlice";

const imageArr1 = [
  {
    image: require("../../Assets/image/app1.png"),
    description: "Find nearby doctors, hospitals. & Lab w/map.",
  },
  {
    image: require("../../Assets/image/app2.png"),
    description: "Book appointments, video consultation with Doctor.",
  },
  {
    image: require("../../Assets/image/app3.png"),
    description: "Multi-vendor medicine and health product ordering.",
  },
  {
    image: require("../../Assets/image/app4.png"),
    description: "Book appointments home sample collection for Lab test.",
  },
  {
    image: require("../../Assets/image/app5.png"),
    description: " Health Emergency services",
  },
  {
    image: require("../../Assets/image/app5.png"),
    description: "Dr. Jii (AI Assistance)",
  },
  {
    image: require("../../Assets/image/app6.png"),
    description: "Health Tracking ",
  },
];

const imageArr2 = [
  {
    image: require("../../Assets/image/app1.png"),
    description: "Comprehensive Profile",
  },
  {
    image: require("../../Assets/image/app2.png"),
    description: "Online reach",
  },
  {
    image: require("../../Assets/image/app3.png"),
    description: "patient management",
  },
  {
    image: require("../../Assets/image/app4.png"),
    description: "Ai assistance(Dr Jii)",
  },
  {
    image: require("../../Assets/image/app5.png"),
    description: "Community support(Docare)",
  },
  {
    image: require("../../Assets/image/app6.png"),
    description: "Earning Growth",
  },
  {
    image: require("../../Assets/image/app7.png"),
    description: "Earning Growth",
  },
];

export default function DownloadApp() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setMyPath(window.location.pathname));
  }, []);

  return (
    <>
      <Helmet>
        <title>Download Apps | Avijo</title>
        <meta
          name='discription'
          content='Download now for desktop, Android, and iOS.'
        />
      </Helmet>
      <div className='App w-[100%]'>
        <ExpertNav />
        <Header />
        <Types setIsModalOpen={setIsModalOpen} />
        <Footer />
        {isModalOpen && (
          <div className='fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50'>
            <div className='relative w-full max-w-lg rounded-lg bg-white p-6 shadow-lg'>
              <button
                className='absolute right-2 top-2 text-gray-600 hover:text-black'
                onClick={() => setIsModalOpen(false)}
              >
                <FaTimes size={24} />
              </button>
              <p className='p-1 text-center text-xl text-black'>
                Join the Avijo beta program and get exclusive early access to
                key features plus free avijo health plan with discount and bonus
                Help us improve Avijo and be rewarded for your feedback.
                <br />
                <a
                  href='https://docs.google.com/forms/d/e/1FAIpQLSdB8tYXJhDRZJ90oHZVkglIu8z2Wx4WcCA4Zpej8pt0pnxDUQ/viewform?usp=header'
                  target='_blank'
                >
                  Sign up now
                </a>
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

const Header = () => {
  return (
    <div className='mb-10 mt-[5%] flex w-full flex-col items-center'>
      <h1 className='text-center font-[Gilroy-SemiBold] text-[26px] text-black'>
        Download Apps For Free
      </h1>
      <p className='mt-2 w-[80%] text-center font-[Gilroy-SemiBold] text-[18px] text-[#616161]'>
        Get avijo app on Mobile or Desktop
      </p>
    </div>
  );
};

const Types = ({ setIsModalOpen }) => {
  return (
    <div className='m-4 mb-[5%] flex flex-col items-start justify-between rounded-[5px] pb-4 md:flex-row'>
      <div className='col border-1 w-[full] p-6 md:ml-10 md:w-1/2'>
        <h3
          className='text-[40px] text-[#0097DB]'
          style={{ fontFamily: "Gilroy-SemiBold" }}
        >
          avijo
        </h3>
        <h3
          className='mt-10 text-[24px] text-[black]'
          style={{ fontFamily: "Gilroy-SemiBold" }}
        >
          Your Health Guardian
        </h3>
        <p className='mt-2 w-[80%] font-[Gilroy-SemiBold] text-[16px] text-[#616161]'>
          It helps the users in locating the best specialist around them, also
          can order medicine, find a hospital and Lab, etc.
        </p>
        <div className='mb-4 mt-6'>
          <ul className='mb-3 p-0'>
            {imageArr1.map((item, index) => (
              <li key={index} className='d-flex align-items-center mb-8 gap-3'>
                <img src={item.image} className='h-[24px] w-[24px]' />
                <div className='flex w-full flex-col items-start'>
                  {item.description}
                </div>
              </li>
            ))}
          </ul>
        </div>
        <button
          className='border-1 mb-[4%] ml-[2%] mt-4 flex h-10 w-[240px] flex-row items-center justify-center rounded-full border-[black] bg-[white]'
          onClick={() => setIsModalOpen(true)}
        >
          <span className='self-center font-[Gilroy-SemiBold] text-[16px] text-[black]'>
            Download On App Store
          </span>
          <img
            src={require("../../Assets/image/diagonal_arrow.png")}
            className='ml-2 h-[20px] w-[20px]'
          />
        </button>
        <button
          className='border-1 mb-[10%] ml-[2%] flex h-10 w-[270px] flex-row items-center justify-center rounded-full border-[black] bg-[white]'
          onClick={() => setIsModalOpen(true)}
        >
          <span className='self-center font-[Gilroy-SemiBold] text-[16px] text-[black]'>
            Download On Google Play
          </span>
          <img
            src={require("../../Assets/image/diagonal_arrow.png")}
            className='ml-2 h-[20px] w-[20px]'
          />
        </button>
      </div>
      <div className='col border-1 w-[full] p-6 md:ml-10 md:w-1/2'>
        <div className='flex flex-row items-end'>
          <h1
            className='font-semiBold text-[45px] text-[#535353]'
            style={{ fontFamily: "Gilroy-SemiBold" }}
          >
            avijo
          </h1>
          <h6 className='text-xl italic text-[#FD7979]'>Expert</h6>
        </div>
        <h3
          className='mt-10 text-[24px] text-[black]'
          style={{ fontFamily: "Gilroy-SemiBold" }}
        >
          For Professionals
        </h3>
        <p className='mt-2 w-[80%] font-[Gilroy-SemiBold] text-[16px] text-[#616161]'>
          A practice & profile management tool for Doctors
        </p>
        <div className='mb-4'>
          <ul className='mb-3 p-0'>
            {imageArr2.map((item, index) => (
              <li key={index} className='d-flex align-items-center mb-8 gap-3'>
                <img src={item.image} className='h-[24px] w-[24px]' />
                <div className='flex w-full flex-col items-start'>
                  {item.description}
                </div>
              </li>
            ))}
          </ul>
        </div>
        <button
          className='border-1 mb-[4%] ml-[2%] mt-4 flex h-10 w-[240px] flex-row items-center justify-center rounded-full border-[black] bg-[white]'
          onClick={() => setIsModalOpen(true)}
        >
          <span className='self-center font-[Gilroy-SemiBold] text-[16px] text-[black]'>
            Download On App Store
          </span>
          <img
            src={require("../../Assets/image/diagonal_arrow.png")}
            className='ml-2 h-[20px] w-[20px]'
          />
        </button>
        <button
          className='border-1 mb-[10%] ml-[2%] flex h-10 w-[270px] flex-row items-center justify-center rounded-full border-[black] bg-[white]'
          onClick={() => setIsModalOpen(true)}
        >
          <span className='self-center font-[Gilroy-SemiBold] text-[16px] text-[black]'>
            Download On Google Play
          </span>
          <img
            src={require("../../Assets/image/diagonal_arrow.png")}
            className='ml-2 h-[20px] w-[20px]'
          />
        </button>
      </div>
    </div>
  );
};
