import React, { useRef, useState } from "react";
import axios from "axios";
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserLocationAvijo } from "../Redux/userSlice";
import { useSelector } from "react-redux";
import { IoLocationOutline } from "react-icons/io5";

const apiKey = "AIzaSyCkWyB3a0UJS91wJntXf1OC4HV-qhqgb8I";
const libraries = ["places"];

const FindLocation = ({ handleSubmit, setOpenLocationModal }) => {
  const userLocationAvijo = useSelector(
    (state) => state.user.userLocationAvijo
  );
  const [address, setAddress] = useState(userLocationAvijo);
  const [finalAddress, setFinalAddress] = useState("");
  const autoCompleteRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: apiKey,
    libraries: libraries,
  });

  const addressHandler = (add) => {
    setAddress(add);
    dispatch(setUserLocationAvijo(add));
  };

  async function getLocation() {
    if (navigator.geolocation) {
      const loadingToast = toast.loading("Fetching location...");
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          try {
            const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${apiKey}`;
            const response = await axios.get(url);
            console.log("Response:", response.data);

            if (response.data?.results?.[0]?.formatted_address) {
              const detectedLocation =
                response.data.results[0].formatted_address;
              addressHandler(detectedLocation);
              setFinalAddress(detectedLocation);
              if (setOpenLocationModal) setOpenLocationModal(false);
              navigate("/user");
              toast.success("Location fetched successfully!", {
                id: loadingToast,
              });
            } else {
              throw new Error("No address found.");
            }
          } catch (error) {
            toast.error("Failed to fetch location.", { id: loadingToast });
            console.error("Error fetching location:", error.message);
          }
        },
        (error) => {
          let errorMessage = "An unknown error occurred.";
          switch (error.code) {
            case error.PERMISSION_DENIED:
              errorMessage = "User denied the request for Geolocation.";
              break;
            case error.POSITION_UNAVAILABLE:
              errorMessage = "Location information is unavailable.";
              break;
            case error.TIMEOUT:
              errorMessage = "The request to get user location timed out.";
              break;
            default:
              errorMessage = error.message;
              break;
          }
          toast.error(errorMessage, { id: loadingToast });
          console.error(errorMessage);
        },
        { enableHighAccuracy: true, timeout: 10000, maximumAge: 0 }
      );
    } else {
      toast.error("Geolocation is not supported by this browser.");
    }
  }

  const handlePlaceChanged = () => {
    if (autoCompleteRef.current) {
      const place = autoCompleteRef.current.getPlace();
      if (place && place.formatted_address) {
        addressHandler(place.formatted_address);
        setFinalAddress(place.formatted_address);
        if (setOpenLocationModal) setOpenLocationModal(false);
        navigate("/user");
      } else {
        console.error("Place or formatted_address is not available.");
      }
    } else {
      console.error("Autocomplete is not loaded yet.");
    }
  };

  const handleFormSubmit = () => {
    if (!finalAddress && address) {
      toast.error("Please select a location from the suggestions.");
    } else {
      handleSubmit(finalAddress || address);
    }
  };

  return (
    <div className='fixed inset-0 z-50 flex bg-black bg-opacity-50'>
      <div className='ml-[4rem] mt-[4rem] h-fit w-full max-w-md rounded-2xl bg-white p-6 shadow-lg'>
        <div className='mb-4 text-2xl font-semibold'>
          Welcome to <span className='text-[#0097DB] font-[Gilroy-SemiBold]'>avijo</span>
        </div>

        <div className='mb-4 flex items-center'>
          <div className='mr-3 text-6xl text-[#0097DB]'>
            <IoLocationOutline />
          </div>

          <div>
            {isLoaded ? <div>
              <h3 className='mb-2 text-lg font-medium'>
                Please provide your delivery location
              </h3>

              <div className='flex flex-col space-y-2'>
                <button
                  onClick={getLocation}
                  className='rounded-lg bg-[#0097DB] px-4 py-2 text-white shadow transition hover:bg-[#0077b3]'
                >
                  Detect my location
                </button>

                <div className='text-center text-gray-500'>Or</div>
                <label className='font-normal'>
                  <p className='mb-1 text-lg font-medium'>
                    Enter nearest location manually
                  </p>
                  <Autocomplete
                    onLoad={(autocomplete) => {
                      autoCompleteRef.current = autocomplete;
                    }}
                    onPlaceChanged={handlePlaceChanged}
                  >
                    <input
                      type='text'
                      className='w-full rounded-lg border border-none border-blue-500 px-4 py-2 transition hover:bg-blue-50 focus:outline-none'
                      placeholder='Search my location'
                      onChange={(e) => addressHandler(e.target.value)}
                      value={address || ""}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") toast.error("Please select location from suggestoin");
                      }}
                    />
                  </Autocomplete>
                </label>
              </div>
            </div>
              :
              <p>Loading Google Maps...</p>
            }
          </div>
        </div>
      </div>
    </div>
  )

};

export default FindLocation;
