import React, { useState } from "react";
import App from "../components/Users/header/Header";
import Footer from "../components/Home/footer/Footer";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Login from "./Users/Login";
import { IoLocationOutline } from "react-icons/io5";
import { HomeNav } from "../components";
import FindLocation from "./FindLocation";
import { setUserLocationAvijo } from "../Redux/userSlice";
import { useDispatch } from "react-redux";

export default function AvijoHome() {
  const [openLocationModal, setOpenLocationModal] = useState(false);

  return (
    <>
      <Helmet>
        <title>Avijo: The Health App</title>
        <meta
          name='description'
          content='Download the Avijo Health App for seamless access to healthcare services. Book consultations, order medicines, track health records, and manage your well-being conveniently.'
        />
        <link rel='icon' href='./avijomain.ico' data-react-helmet='true' />
      </Helmet>

      <Link to='/downloadapp' className='no-underline'>
        <div className='cursor-pointer bg-gradient-to-r from-blue-600 to-red-500 p-3 text-center text-white transition-all duration-300 hover:opacity-90'>
          🎉 Early Sign-Up Bonus: Get Free Membership Plans & Exclusive Offers!
          🚀 Click Here!
        </div>
      </Link>

      <div className={`flex h-full w-full flex-col items-center`}>
        <div className='w-full'>
          <HomeNav />
        </div>

        <div
          className='mb-[3rem] flex w-full flex-col items-center md:flex-row'
          id='container.main'
        >
          <div className='justify-content-end flex w-full flex-row items-center md:w-[50%]'>
            {
              openLocationModal ? (
                <FindLocation setOpenLocationModal={setOpenLocationModal} />
              ) :
                (<LocationNotServiceable setOpenLocationModal={setOpenLocationModal} />)
            }
          </div>

          <div className='justify-content-center flex w-full items-center md:w-[50%]'>
            <img
              className='h-[80%] w-[80%]'
              src='https://dbnc.in/img/new-im6-1.png'
              alt='Image'
            />
          </div>
        </div>

        <div style={{ display: "none" }}>
          <Login />
        </div>
      </div>
      <Footer />
    </>
  );
}

const LocationNotServiceable = ({ setOpenLocationModal }) => {
  const dispatch = useDispatch();
  return (
    <div className='my-[3rem] flex w-full max-w-sm flex-col items-center justify-center rounded-2xl bg-white p-6 text-center shadow-lg'>
      <div className='mb-3 text-6xl text-[#0097DB]'>
        <IoLocationOutline />
      </div>

      <h2 className='text-lg font-semibold text-black'>
        Location not serviceable
      </h2>
      <p className='mt-1 text-sm text-gray-600'>
        Our team is working tirelessly to bring 10-minute deliveries to your
        location.
      </p>

      <button
        onClick={() => { dispatch(setUserLocationAvijo("")); setOpenLocationModal(true) }}
        className='mt-4 w-full rounded-lg bg-[#0097DB] px-4 py-2 text-center text-sm font-semibold text-white no-underline shadow-md transition-all duration-300 hover:bg-[#0077b3]'
      >
        Try Changing Location
      </button>
    </div>
  );
};
