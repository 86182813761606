import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faAddressCard } from "@fortawesome/free-solid-svg-icons";

function Navbar() {
  return (
    <nav className='border-b bg-white'>
      <div className='mx-auto flex max-w-screen-xl items-center justify-between px-4 py-2'>
        <div className='flex items-center'>
          <Link
            to='/'
            className='text-[calc(10vw+10px)] font-semibold leading-none text-black'
          >
            avijo
          </Link>
        </div>

        {/* Search Input */}
        <div className='mx-4 flex-1'>
          <input
            type='text'
            className='w-full rounded-md border px-4 py-2 text-sm placeholder-gray-500'
            placeholder='Deliver To     |   Search for : Medicine & wellness products'
          />
        </div>

        {/* Navigation Links */}
        <div className='flex items-center gap-6'>
          <Link
            to='/provider'
            className='flex items-center text-sm text-white hover:text-blue-500'
          >
            <FontAwesomeIcon icon={faUser} />
            <span className='ml-2'>For Providers</span>
          </Link>

          <Link
            to='/contact'
            className='flex items-center text-sm text-white hover:text-blue-500'
          >
            <FontAwesomeIcon icon={faAddressCard} className='text-white' />
            <span className='ml-2'>Contact</span>
          </Link>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
