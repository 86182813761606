import React from "react";
import ScrollContainer from "react-indiana-drag-scroll";

const HomeSlider = ({ CardComponent, data }) => {
  return (
    <ScrollContainer className='custom-scrollbar flex gap-4 overflow-x-auto p-4'>
      {data.map((item, index) => (
        <CardComponent key={index} {...item} />
      ))}
    </ScrollContainer>
  );
};

export default HomeSlider;
