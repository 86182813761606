import React from "react";

const BrandLogo = ({ subTitle }) => {
  let subTitleColor;

  if (subTitle === "Alpha") subTitleColor = "text-[#12CDB7]";
  else if (subTitle === "Expert") subTitleColor = "text-[#FD7979]";
  else subTitleColor = "text-[#FFCA38]";

  return (
    <div className='relative font-[Gilroy-SemiBold] text-[64px] text-[#535353]'>
      avijo
      <span
        className={`${subTitleColor} absolute bottom-[-2px] font-[Gilroy-SemiBold] text-[32px] italic`}
        style={{ left: subTitle === "Alpha" ? "90%" : "95%" }}
      >
        {subTitle}
      </span>
    </div>
  );
};

export default BrandLogo;
