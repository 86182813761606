import React from "react";
import { Link } from "react-router-dom";
import "../../../styles/SearchSection.css";
import { FaClipboardList, FaSearch } from "react-icons/fa";
import { IoLocationOutline } from "react-icons/io5";

const SearchSection = () => {
  return (
    <div className='mb-[5rem] flex flex-col space-y-4'>
      <h2 className='ml-[20%] mt-3 text-[2rem] font-semibold text-gray-500'>
        Find what you need?
      </h2>

      <div className='mx-auto flex items-center space-x-4'>
        <button className='flex items-center rounded-full border border-gray-300 bg-white px-4 py-2 text-gray-500 shadow-md'>
          <IoLocationOutline className='mr-2 text-lg' /> Search by Location
        </button>

        <div className='flex items-center overflow-hidden rounded-full border border-gray-300 bg-white shadow-md'>
          <span className='px-3 text-gray-500'>
            <FaSearch />
          </span>
          <input
            type='text'
            placeholder='Search'
            className='flex-grow bg-white px-2 py-2 outline-none'
          />
          <button className='m-1 rounded-full bg-[#0097DB] px-4 py-2 text-white transition'>
            Search
          </button>
        </div>
      </div>

      {/* Order with Prescription */}
      <div className='ml-[40%] flex items-center space-x-2 text-lg text-gray-500'>
        <FaClipboardList />
        <span>Order With Prescription</span>
        <Link
          to={"/user"}
          className='flex items-center font-semibold text-[#0097DB] no-underline'
        >
          <span>UPLOAD NOW </span> <span>&gt;</span>
        </Link>
      </div>
    </div>
  );
};

export default SearchSection;
