import React from "react";
import { Link } from "react-router-dom";

const FeesAndPaymentsPolicy = () => {
  return (
    <div>
      {" "}
      <div>
        <div className='container mt-5'>
          <div className='row team-row'>
            <div className='col-md-8'>
              <h4>FEES AND PAYMENTS POLICY</h4>
              <h4>Bohore Aggregator Pvt Ltd</h4>
              <p>
                A company incorporated under the laws of India, having its
                registered address at 50 SANJAY COLONY, WARD NO -07, DANDAPURA,
                Vidisha, Vidisha, Vidisha- 464001, Madhya Pradesh (“we”, “us” or
                “our”), manages and operates{" "}
                <a href='www.avijo.in'>www.avijo.in</a> and its corresponding
                mobile application under the brand name Avijo (collectively
                referred to as the “Website”) where you can place orders to
                purchase the Products and/or Services listed/available on the
                Website offered by us to the users of the Website (“User” or
                “Users” or “you”).
              </p>

              <h4>FEES AND PAYMENTS POLICY</h4>

              <p>
                This Fees and Payments Policy (“FPP Policy”), together with the{" "}
                <a href='https://www.avijo.in/policy/Terms-and-Conditions'>
                  Terms and Conditions
                </a>
                , describes Avijo’s accepted payment methods, and Avijo’s
                policies and procedures in relation to the acceptance of fees
                and payments towards the services offered through the Website.
                Avijo’s primary objective is to ensure that the Website is
                user-friendly and equipped with reasonably expected security
                infrastructure to protect any financial information which may be
                shared by User.
              </p>

              <p>
                User(s) are required to peruse and understand the terms of this
                FPP Policy. If you do not agree to the terms contained in this
                FPP Policy, you are advised not to accept the Terms of Use and
                this FPP Policy and may forthwith leave and stop using the
                Website. The terms contained in this FPP Policy shall be
                accepted without modification and also, by using, browsing,
                accessing, or purchasing on the Website, you agree to be bound
                by the terms contained herein.
              </p>

              <h4>FEES AND PAYMENT OPTIONS</h4>

              <p>
                Avijo does not levy any fee for browsing the Website. Avijo may,
                in the future, consider levying fees on the User for using the
                Website as a whole, or for the use of certain features of the
                Website. In such an event, you agree to pay any such fees, as
                applicable. Avijo does not covenant or guarantee providing you
                with notice prior to enforcing such a levy of fees. Your
                continued usage of the Website after such change in the fees
                will be considered your acceptance of such changes.
              </p>

              <p>
                In order to ensure User convenience, Avijo offers multiple
                payment options to User. Avijo without prior notice to User,
                reserves the right to add or delete payment options from the
                ones listed below:
              </p>
              <ol type='a'>
                <li>payment through net banking facilities;</li>
                <li>payment through select credit cards;</li>
                <li>payment through select debit cards;</li>
                <li>payment through Avijo Wallet;</li>
                <li>payments through cash on delivery;</li>
                <li>
                  payments through prepaid payment instruments and electronic
                  wallets;
                </li>
                <li>
                  Any other payment option as may be provided by Avijo from time
                  to time.
                </li>
              </ol>
              <p>
                The payment options referred to above shall hereinafter
                collectively be referred to as “Payment Options”. While
                reasonable endeavors are made to offer the Payment Options
                through varied banking channels, presently, Avijo accepts
                payments only from major select banking avenues. Avijo does not
                accept payments made through international debit/credit cards.
              </p>
              <h4>3. Payment Acceptance Discretion</h4>
              <p>
                It is expressly clarified that accepting a User’s payment
                through the Payment Options is solely at Avijo's discretion.
                Avijo reserves the right to reject payment from a User through
                the Payment Options for any reason whatsoever. In order to
                further validate a User’s transaction, Avijo may request the
                User to submit a copy of the User’s photo identity proof (such
                as the User’s PAN card), failing which, Avijo reserves the right
                to reject a User’s payment made through the Payment Options.
              </p>
              <h4>4. User Responsibility for Financial Information</h4>
              <p>
                While using the Payment Options, User agrees to provide correct,
                complete, and accurate financial information such as
                credit/debit card details or prepaid payment instrument account
                details which may be stored by Avijo’s third-party payment
                gateway provider. User(s) shall not use a credit/debit card or
                prepaid instrument which is not lawfully owned by him/her or
                which the User has not been lawfully authorized to use. The User
                shall solely be responsible for the security and confidentiality
                of his/her financial information. Avijo disclaims all
                liabilities that may arise as a consequence of any unauthorized
                use of the User’s financial information and/or identity,
                including, details relating to the Payment Options.
              </p>
              <h4>5. Limitation of Liability</h4>
              <p>
                Avijo shall not be held responsible and shall not assume any
                liability in respect of any loss or damage suffered by a User
                owing to:
                <ul>
                  <li>
                    Lack of authorization for any transaction, including such
                    transactions carried out on gift cards and vouchers issued
                    to User(s) as a part of Avijo promotions.
                  </li>
                  <li>
                    Exceeding of the credit/debit limit mutually agreed between
                    the User and the issuing bank.
                  </li>
                  <li>
                    Any payment issue arising out of technical glitches
                    occurring during a transaction.
                  </li>
                  <li>
                    Transactions being declined for reasons beyond Avijo’s
                    control.
                  </li>
                </ul>
              </p>
              <h4>6. Transaction Limits and Refusal</h4>
              <p>
                Avijo reserves the right to impose limits on the number of
                transactions a User may undertake over the Website. Should the
                User exceed such transaction limits imposed by Avijo, Avijo
                reserves the right to refuse to process such transactions. Avijo
                may refuse to process transactions emanating from User(s) who
                have suspicious or questionable transaction history on the
                Website. Should Avijo be dissatisfied with the credibility of a
                User or the genuineness of a transaction carried out on the
                Website, Avijo shall have the right to reject such transactions.
                Avijo may also choose to delay the dispatch of Products
                purchased by such User or cancel the entire purchase at its sole
                discretion. The User agrees that Avijo shall not be liable for
                any damage, interests or claims resulting from Avijo's decision
                to not process a transaction or delay in the processing of a
                transaction on account of a User’s suspicious activity on the
                Website.
              </p>
              <h4>7. Tax Responsibility and Price Discrepancies</h4>
              <p>
                All the Products listed on the Platform are inclusive of all
                applicable taxes. You will be responsible for payment of all
                fees/costs/charges associated with the purchase of Products from
                us and you agree to bear any and all applicable taxes including
                but not limited to VAT/CST, service tax, GST, duties and cesses
                etc. Despite Avijo’s best efforts, there may be instances of
                mispricing of Products on the Website during or after the
                process of purchase of Products has been carried out by the
                User. While thorough processes of verification are carried out
                prior to the publication of Product prices on the Website,
                errors and discrepancies arising out of technical glitches and
                time lags are unavoidable. For avoidance of doubt, Avijo’s
                curative actions that will arise in the event of a price
                difference arising on the actual maximum retail price of a
                Products against the price published on the Website is set out
                below:
              </p>
              <h4>8. Refund Process</h4>
              <p>
                <strong>i.</strong> If the maximum retail price of the Products
                delivered to the User is lower than the price published on the
                Website, the User shall, within 2 (two) days from the time when
                he/she received the Products, intimate us at{" "}
                <a href='mailto:cs@avijo.in'>cs@avijo.in</a> / 7067635032 in
                each case resulting in an acknowledgment. Upon Avijo being
                satisfied of the User’s claim, the difference amount in the
                purchase price against the maximum retail price shall be
                refunded to User’s Wallet account or transferred to the User’s
                bank account within 10 (ten) to 12 (twelve) working days from
                the date of receiving the written request from the User.
              </p>
              <p>
                <strong>ii.</strong> If the maximum retail price of the Products
                listed on the Website is higher than what has been published and
                if Avijo is not providing an offer or discount on such Products,
                Avijo shall have the right to reserve the dispatch of the
                purchased Products until the error on the Website has been
                rectified and the User pays the difference amount. If the User
                refuses to pay the difference amount, the User is entitled to
                seek a cancellation and the amount paid by the User shall be
                refunded to Wallet account or transferred into the User’s bank
                account within 10 (ten) working days by Avijo.
              </p>
              <p>
                <strong>iii.</strong> In order to process such refunds, the User
                will be required to send an email to us at{" "}
                <a href='mailto:cs@avijo.in'>cs@avijo.in</a> providing the
                details of the bank account where the amount needs to be
                refunded. Thereafter, the User will receive a refund if the
                initial mode of payment for the order was ‘cash on delivery’ on
                users account through net banking. If the initial mode of
                payment for the order was through a payment option other than
                the ‘cash on delivery’ option, we may transfer the refund to the
                User’s source account, which was used to make the initial
                payment.
              </p>
              <h4>9. Additional Terms</h4>
              <p>
                To enable User to make payments for purchasing Products on the
                Website, in addition to this FPP Policy, the terms and
                conditions of the User’s bank, applicable financial institution
                and/or card issuing association may be applicable. The User’s
                bank, financial institution or card issuing association may
                decline or prevent the User from making electronic payments for
                buying the Products on the Website and Avijo does not control
                the same and hence, shall not be at any point in time be held
                liable.
              </p>
              <h4>10. Third Party Payment Providers</h4>
              <p>
                Avijo may, from time to time contract with third party payment
                service providers including banks under applicable Indian laws.
              </p>
              <h4>11. Unauthorized Charges</h4>
              <p>
                If it is brought to the User’s notice that a charge has been
                created on his/her payment instrument(s) for purchase of
                Products on the Website and the User is not aware of such
                purchase on the Website, the User is first, required to verify
                if his/her family members, friends or business colleagues were
                authorized to carry out the purchase of Products on the Website.
                If, despite this, the User continues to be unable to identify
                the charge created on his/her payment instrument(s) for purchase
                of Products on the Website, the User may report such
                unauthorized purchase to us within 2 (two) days from the date on
                which the unauthorized purchase was carried out on the Website
                in order to enable Avijo to initiate investigations.
              </p>
              <h4>12. Invalidated Payment</h4>
              <p>
                If the User carries out a purchase on the Website and the
                payment in lieu thereof has been invalidated, Avijo reserves the
                right to retrieve the invalidated payment (“Invalidated
                Payment”) from the User’s Wallet Account. Should the User have
                insufficient Wallet amount in order to facilitate such
                redemption of the invalidated payment, the User shall within 2
                (two) to 4 (four) working days from the date of receiving a
                written notice from Avijo, remit the Invalidated Payment into
                Avijo’s bank account, details of which shall be set out in the
                notice. If the User fails to remit the Invalidated Payment into
                Avijo’s bank account despite having received the notice, Avijo
                shall be entitled to initiate civil and/or criminal legal action
                against the defaulting User at his/her cost and peril.
              </p>
              <h4>13. Cash on Delivery Option</h4>
              <p>
                The ‘cash on delivery’ payment option allows Users to make a
                cash-only payment to our delivery executive or logistic partner
                at the time of delivery of the purchased Products to the User.
                Presently, we offer a maximum order value of INR 10,000 (Indian
                Rupees Ten Thousand) under the cash on delivery payment option.
                Avijo reserves the right not to provide the option of cash on
                delivery payment for certain Products (these could be Products
                specified by Avijo or Products with value exceeding a specified
                amount) or locations. User(s) are required to peruse and accept
                the terms set out under the Return Refund and Cancellation
                Policy which sets out the terms of refunds for transactions
                carried out using the cash on delivery payment option.
              </p>
              <h4>14. Avijo Wallet</h4>
              <p>
                If a User cancels an order for which payment has already been
                made to Avijo, the User shall have an option to convert the
                amount paid towards the order into Avijo Wallet.
              </p>
              <p>
                You may direct any queries or concerns relating to the Fees and
                Payment policy to our customer support team who can be contacted
                at:
              </p>

              <p>Contact Details:</p>

              <p>
                E-mail id - <a href='mailto:cs@Avijo.in'>cs@Avijo.in</a>
              </p>

              <p>
                Contact No - <a href='tel:07067635032'>070676-35032</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeesAndPaymentsPolicy;
