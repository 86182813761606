import React from "react";
import { Link } from "react-router-dom";
const EditorialPolicy = () => {
  return (
    <div>
      <div>
        <div>
          <div className='container mt-5'>
            <div className='row team-row'>
              <div className='col-md-8'>
                <h4>Editorial Policy </h4>

                <p>
                  Our Content Curation Involves a 10 Stage Review and Editorial
                  Procedure as follows:
                </p>

                <p>
                  <strong> 1.Comprehensive Research</strong> All content on our
                  platform must undergo extensive research conducted by
                  qualified professionals, including pharmacists, medical
                  writers, and subject matter experts. Research methodologies
                  are transparently outlined, showcasing a commitment to a
                  robust information-gathering process.1. location.
                </p>

                <p>
                  <strong>2.Scientific Alignment</strong>, Accuracy and
                  Transparency Alignment: Ensure that all information provided
                  aligns with the latest scientific knowledge, industry
                  guidelines, and regulatory standards. Accuracy: Cross-verify
                  data from multiple reputable and authoritative sources, such
                  as Government Websites, Patient Information Leaflets, Package
                  Inserts, Journals and Research Articles, Government Health
                  Agencies, and Official Manufacturing Databases. Transparency:
                  Provide detailed information about the sources of data and
                  references used in creating the content
                </p>

                <p>
                  <strong> 3.Content Generation</strong> <br />
                  Ethical Standards & Guidelines Followed to Deliver Accurate
                  and Complaint Medical Information:
                  <br /> Trust and Authenticity: We prioritize accuracy and
                  reliability in all our content. Information is thoroughly
                  researched, fact-checked, and verified by reliable sources
                  before publication. Attribution and Plagiarism: Proper
                  attribution is essential. Any content derived from external
                  sources will be credited appropriately, and plagiarism is
                  strictly prohibited. Neutral and Objective Content Structure:
                  Creating content that maintains neutrality and does not favour
                  any particular product or service Patient-Centric Approach: In
                  healthcare-related content, we prioritize the well-being of
                  patients. Information provided shall empower individuals to
                  make informed decisions about their health. Compliance with
                  Legal and Regulatory Standards: Our content adheres to all
                  applicable legal and regulatory standards in the regions where
                  it is published. This includes compliance with pharmaceutical
                  regulations, copyright laws, and any other relevant
                  guidelines. Aligned with the most recent advancements: Ensure
                  that the content is consistently refreshed with the most
                  recent medical research findings and reports. Recognition: We
                  acknowledge and attribute the author's qualifications and
                  sources of the content. Respect for Diversity and Inclusivity:
                  We are committed to creating content that respects and
                  represents diverse perspectives, cultures, and communities.
                  Discrimination or bias based on race, gender, religion, or any
                  other factor is strictly prohibited
                </p>
                <p>
                  <strong>4.5.Regulatory Compliance & Text scrutiny</strong> We
                  are mindful in creating content, which is compliant as per
                  Pharmacy Act 1945, Drugs and Cosmetics Act 1940 & Rules 1945,
                  Drugs and Magic Remedies Act (Objectionable Advertisement Act,
                  1954) and other regulatory authorities of India.
                </p>

                <p>
                  <strong>5.Content Curation and Development</strong> Trained
                  medical professionals, pharmacists, subject matter experts
                  perform extensive research on the medical topic and draft the
                  content with utmost authenticity and compliance.
                </p>

                <p>
                  <strong>6.Tone and Style</strong>A consistent editorial style,{" "}
                  encompassing language, tone, and formatting, is maintained
                  throughout all content.
                </p>

                <p>
                  <strong>7.Citation & Sourcing</strong> Provide citations and
                  references transparently within the content to allow users to
                  verify the information independently.
                </p>

                <p>
                  <strong>8.Content Review & Evaluation</strong> For scientific
                  articles and research-based content, a peer review process
                  involving external experts are employed to validate the
                  accuracy and scientific validity of the information presented.
                </p>

                <p>
                  <strong>9.Continuous Training and Development</strong> Our
                  team members undergo continuous training to stay updated on
                  ethical standards, industry best practices, and any changes in
                  regulations that may impact content creation.
                </p>

                <p>
                  <strong>10.Updates and Revisions</strong> Regularly update
                  content to reflect any new developments or changes in the
                  pharmaceutical landscape. We take utmost care in reflecting
                  new information, changes in regulations, and emerging industry
                  trends.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditorialPolicy;
