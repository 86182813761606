import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BaseUrl } from "../Routes/BaseUrl";
import axios from "axios";
import Cookies from "js-cookie";

export const checkAuth = createAsyncThunk("auth/checkAuth", async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${BaseUrl}/user/get-user`, { withCredentials: true });
    return response.data.data;
  } catch (error) {
    
    const cookieOptions = { 
      path: "/",
      secure: true,
      sameSite: "None"
    };
    Cookies.remove("tokenUser", cookieOptions);
    Cookies.remove("token", cookieOptions);
    return rejectWithValue(error.response?.data || "Authentication failed");
  }
});

const initialState = {
  myPath: localStorage.getItem("myPath") || null,
  userLocationAvijo: localStorage.getItem("userLocationAvijo") || null,
  userData: null,
  isProfilePanelOpen: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setMyPath: (state, action) => {
      state.myPath = action.payload;
      localStorage.setItem("myPath", action.payload);
    },

    setUserLocationAvijo: (state, action) => {
      state.userLocationAvijo = action.payload;
      localStorage.setItem("userLocationAvijo", action.payload);
    },
    setUserData: (state, action) => {
      state.userData = action.payload;
    },

    setUserLoggedOut: (state) => {
      state.userLocationAvijo = null;
      state.userData = null;
      state.isProfilePanelOpen = false;
      localStorage.removeItem("userLocationAvijo");
      localStorage.removeItem("myPath");
    },
    setIsProfilePanelOpen: (state, action) => {
      state.isProfilePanelOpen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkAuth.fulfilled, (state, action) => {
        state.userData = action.payload;
      })
      .addCase(checkAuth.rejected, (state) => {
        state.userData = null;
      })
  }
});

export const {
  setMyPath,
  setUserLocationAvijo,
  setUserData,
  setUserLoggedOut,
  setIsProfilePanelOpen,
} = userSlice.actions;

export default userSlice.reducer;
