import React, { useState } from "react";
import axios from "../../../axiosConfig";
import { useNavigate } from "react-router-dom";
import HeroImage from "../../../Assets/image/5093164.jpg";
import Swal from "sweetalert2";
import { BaseUrl } from "../../../Routes/BaseUrl";
import { GoogleSignUpRegister } from "../../../Pages";
import { toastHandler } from "../../../utils/toastHandler";

const HeroSection = () => {
  const navigate = useNavigate();
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSendOtp = async () => {
    const mobileNumber = phone;
    setLoading(true); // Set loading state to true
    const res = axios.post(`/user/send-otp`, {
      mobileNumber,
    });
    const response = await toastHandler(
      res,
      "Sending OTP",
      "OTP Sent Successfully",
      "Failed to send OTP"
    );
    console.log("Response:", response);
    if (response && response.status === 200) {
      if (response.data.data === true)
        navigate("/user/otp-verify", { state: { mobileNumber } });
      else navigate("/user/create-account", { state: { mobileNumber } });
    }
    setLoading(false);
  };

  return (
    <section>
      <div className='container pt-4'>
        <div className='row rounded border bg-white'>
          <div className='col-md-6 ps-0'>
            <img src={HeroImage} alt='Logo' className='hero-w' />
          </div>
          <div className='col-md-6 hero_left pt-5'>
            <h2 className="font-bold">Login / Create Account</h2>
            <p>
              Sign up or Sign in to access your orders, special offers, health
              tips and more!
            </p>
            <label>PHONE NUMBER</label>
            <br />
            <span className="border-b border-gray-300 pb-[13px]">+91 |</span>
            <input
              type='text'
              className='phone-input mb-3 w-full'
              placeholder='Enter Your Mobile'
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <div>
              <button
                className='mt-2 text-center w-[80%]'
                onClick={handleSendOtp}
                disabled={loading} // Disable button when loading
              >
                {loading ? (
                  <>
                    <span
                      className='spinner-border spinner-border-sm'
                      role='status'
                      aria-hidden='true'
                    ></span>
                  </>
                ) : (
                  "USE OTP"
                )}
              </button>
              <div className='d-flex mt-3 gap-4 pb-2'>
                <GoogleSignUpRegister />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
