import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { Link } from "react-router-dom";
import ExpertNav from "../components/Navbar/ExpertNav";
import Footer from "../components/Home/footer/Footer";
import { Helmet } from "react-helmet";
import {
  AI,
  grow,
  healthDay,
  HFR,
  linkage,
  takeStep,
  teleConsultation,
  unifiedSoftware,
} from "../Assets";
import { BrandLogo } from "../components";

const AvijoAlpha = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='App'>
      <ExpertNav price={true} />
      <Header />
      <Helmet>
        <link rel='icon' href='./avijoalpha.ico' />
        <title>Avijo Alpha | Avijo</title>
        <meta
          name='discription'
          content='Empower your healthcare facility with Avijo Alpha, the cloud-based software system designed for hospitals, clinics, pharmacies, and diagnostic centers. Enhance efficiency and patient care with cutting-edge solutions.'
        />
      </Helmet>
      <Carousel />
      <SupportData />
      <Footer />
    </div>
  );
};

const Header = () => (
  <header className='hero bg-gradient-to-r from-[#F6F6F6] to-[#C8C8C8]'>
    <div className='flex flex-col justify-between p-4 md:flex-row'>
      {/* Left Content */}
      <div className='flex h-full w-full flex-col gap-y-6 p-2 md:w-1/2'>
        <div className='flex-grow'>
          <div className='flex flex-row items-end'>
            <BrandLogo subTitle={"Alpha"} />
          </div>
          <span className='font-gilroy text-justify text-xl font-[500] text-[#535353]'>
            Avijo Alpha is a cutting-edge, AI-powered Software platform designed
            to revolutionize the way healthcare facilities operate. As a
            unified, ABDM-enabled software solution, Avijo Alpha brings together
            the critical components of healthcare management into a single,
            streamlined system. This platform is specifically built for
            healthcare facilities like doctors clinics, hospitals, Diagnosis
            centres and pharmacies, enabling them to deliver exceptional care
            while optimizing their daily operations.
          </span>
        </div>
        {/* Button */}
        <button className='flex h-12 w-[130px] items-center justify-center rounded-lg bg-[#0097DB]'>
          <a
            href='https://alpha.avijo.in/'
            target='_blank'
            rel='noopener noreferrer'
            className='flex items-center gap-2 text-lg text-white no-underline'
          >
            Get Started
            <img
              src={require("../Assets/image/diagonal_arrowWhite.png")}
              className='h-[14px] w-[18px]'
              alt='arrow'
            />
          </a>
        </button>
      </div>

      {/* Right Image Section */}
      <div className='rounded-2lg flex items-center justify-center p-0 md:w-1/2'>
        <img src={HFR} alt='header' className='h-[400px] w-auto' />
      </div>
    </div>
  </header>
);

const CarouselItem = ({
  active,
  index,
  title,
  content,
  buttonText,
  imgSrc,
  bgColor,
  setActive,
}) => (
  <div
    className={`${active === index ? "active" : ""} carousel-item flex flex-col items-center`}
    style={{ backgroundColor: bgColor }}
  >
    <h2 className='ml-[20%] w-[60%] self-center pt-5 text-center text-4xl'>
      Transforming Healthcare Operations with AI-Powered Solutions
    </h2>
    <div className='ml-10 mt-4 flex w-[90%] flex-col items-center justify-between text-center md:flex-row'>
      {[
        "ABDM Enabled",
        "AI optimize",
        "Integrated Environment",
        "Online Reach",
        "Docare",
      ].map((text, idx) => (
        <div key={idx} onClick={() => setActive(idx)}>
          <span
            className={`cursor-pointer text-center text-[22px] font-semibold hover:text-white ${active === idx ? "text-[#0097DB]" : "text-black"}`}
          >
            {text}
          </span>
        </div>
      ))}
    </div>
    <div className='flex flex-col items-center justify-between p-4 pt-2 md:flex-row'>
      <div className='flex h-full w-full flex-col p-2 md:w-[65%]'>
        <div className='flex-grow'>
          <h1 className='font-gilroy font-semiBold ml-4 text-[36px] text-black'>
            {title}
          </h1>
          {content.map((item, idx) => (
            <div key={idx} className='mt-2 flex flex-row items-start'>
              <img
                src={require("../Assets/image/arrow.png")}
                className='mr-2 mt-1 h-[13px] w-[9px]'
                alt='arrow'
              />
              <span className='font-gilroy text-[20px] font-medium text-black'>
                {item}
              </span>
            </div>
          ))}
        </div>

        <button className='mt-4 w-[220px] rounded-lg bg-[#0097DB] p-2'>
          <a
            href='https://alpha.avijo.in/'
            target='_blank'
            rel='noopener noreferrer'
            className='flex items-center gap-2 text-lg text-white no-underline'
          >
            <span className='text-sm text-white'>{buttonText}</span>
            <img
              src={require("../Assets/image/diagonal_arrowWhite.png")}
              className='h-[14px] w-[18px]'
              alt='arrow'
            />
          </a>
        </button>
      </div>
      <div className='flex h-[350px] w-full justify-center p-4 md:w-[500px]'>
        <img
          src={imgSrc}
          alt={title}
          loading='lazy'
          className='h-full w-[100%]'
        />
      </div>
    </div>
  </div>
);

const Carousel = () => {
  const [active, setActive] = useState(0);

  const items = [
    {
      index: 0,
      title: "Unified Software Platform",
      content: [
        "Seamlessly integrates with other Avijo products and applications.",
        "Supports end-to-end healthcare management, from patient records to billing",
        "Provides a unified dashboard for real-time monitoring of healthcare operations",
        "Ensures compliance with national and international healthcare regulations",
      ],
      buttonText: "Register as HFR for demo",
      imgSrc: unifiedSoftware,
      bgColor: "#E7F4FD",
    },
    {
      index: 1,
      title: "AI-Powered Operations",
      content: [
        "Automates routine tasks such as appointment scheduling and patient follow-ups",
        "Provides predictive analytics for patient outcomes and resource management.",
        "Enhances diagnostic accuracy with AI-driven recommendations.",
        "Facilitates personalized patient care by analyzing health data patterns",
      ],
      buttonText: "Register as HFR for demo",
      imgSrc: AI,
      bgColor: "#FFFBD4",
    },
    {
      index: 2,
      title: "Linkage Features",
      content: [
        "Enables collaboration between different healthcare facilities and professionals.",
        "Simplifies patient referrals and sharing of medical records.",
        "Supports multi-facility management for hospital chains",
        "Enhances coordination in patient care by linking specialists and general practitioners.",
      ],
      buttonText: "Register as HFR for demo",
      imgSrc: linkage,
      bgColor: "#E1FFD3",
    },
    {
      index: 3,
      title: "Teleconsultation & Appointment Booking",
      content: [
        "Offers a user-friendly interface for patients to book appointments online.",
        "Supports video, audio, and chat-based consultations",
        "Provides a secure platform for sharing medical reports and prescriptions.",
        "Integrates with electronic health records for seamless consultations",
      ],
      buttonText: "Register as HPR for demo",
      imgSrc: teleConsultation,
      bgColor: "#E0EBFF",
    },
    {
      index: 4,
      title: "Doctor & Patient Community Integration",
      content: [
        "Enables doctors to build their professional network within the healthcare community",
        "Facilitates case discussions and peer reviews in a secure environment",
        "Offers a platform for collaborative learning and knowledge sharing",
        "Encourages patient engagement through Q&A sessions with doctors.",
      ],
      buttonText: "Register as HPR for demo",
      imgSrc: healthDay,
      bgColor: "#FADCFF",
    },
  ];

  const handlePrev = () => {
    setActive((prev) => (prev > 0 ? prev - 1 : items.length - 1));
  };

  const handleNext = () => {
    setActive((prev) => (prev < items.length - 1 ? prev + 1 : 0));
  };

  return (
    <div id='carouselExample' className='relative'>
      <div className='carousel-inner'>
        {items.map((item) => (
          <CarouselItem
            key={item.index}
            active={active}
            index={item.index}
            title={item.title}
            content={item.content}
            buttonText={item.buttonText}
            imgSrc={item.imgSrc}
            bgColor={item.bgColor}
            setActive={setActive} // Pass setActive function
          />
        ))}
      </div>
      <button
        className='absolute left-0 top-1/2 -translate-y-1/2 transform p-2'
        onClick={handlePrev}
        style={{ backgroundColor: "transparent" }}
      >
        <img
          src={require("../Assets/image/left.png")}
          alt='Previous'
          className='h-8 w-8'
        />
      </button>
      <button
        className='absolute right-0 top-1/2 -translate-y-1/2 transform p-2'
        onClick={handleNext}
        style={{ backgroundColor: "transparent" }}
      >
        <img
          src={require("../Assets/image/right.png")}
          alt='Next'
          className='h-8 w-8'
        />
      </button>
    </div>
  );
};

const SupportData = () => {
  const data = [
    {
      id: 0,
      heading: "Get Started Today",
      image: grow,
      points: [
        {
          id: 0,
          text: "Discover how Avijo Alpha can transform your practice. Contact us to learn more, request a demo, or sign up to start benefiting from our AI-powered unified software solution. Join the Avijo ecosystem and be part of the healthcare revolution.",
        },
      ],
    },
    {
      id: 1,
      heading: "Take the Next Step",
      image: takeStep,
      points: [
        {
          id: 0,
          text: "Experience the future of healthcare management with Avijo Alpha.Platform is designed to empower healthcare professionals and facilities by providing the tools needed to operate more efficiently, connect with other professionals, and enhance patient care. Whether you’re a doctor, clinics,pharmacy or hospital etc.. Avijo Alpha is your partner in delivering top-tier healthcare.",
        },
      ],
    },
  ];

  return (
    <>
      {data.map((item, index) =>
        index % 2 === 0 ? (
          index % 2 === 0 && (
            <div
              key={index}
              className='border-1 m-4 flex flex-col items-center justify-between rounded-[5px] pb-4 md:flex-row'
            >
              <div className='w-full p-4 pb-0 md:w-1/2'>
                <img
                  src={item.image}
                  className='rounded-2 h-[310px] w-[500px]'
                  loading='lazy'
                />
              </div>
              <div className='flex h-full w-full flex-col p-2 md:w-1/2'>
                <div className='flex-grow'>
                  <h3 className='font-gilroy font-semiBold pl-4 text-[36px]'>
                    {item.heading}
                  </h3>
                  <ul className='list-disc pl-0'>
                    {item.points.map((point, idx) => (
                      <div
                        key={idx}
                        className='font-gilroy mt-2 pl-4 text-[20px] font-medium text-[#424242]'
                      >
                        <p className='expert-paragraph'>{point.text}</p>
                      </div>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          )
        ) : (
          <div
            key={index}
            className='border-1 m-4 flex flex-col items-center justify-between rounded-[5px] pb-4 md:flex-row'
          >
            <div className='flex h-full w-full flex-col p-2 md:w-1/2'>
              <div className='flex-grow'>
                <h3 className='font-gilroy font-semiBold pl-4 text-[36px]'>
                  {item.heading}
                </h3>
                <ul className='list-disc pl-0'>
                  {item.points.map((point, idx) => (
                    <div
                      key={idx}
                      className='font-gilroy mt-2 pl-4 text-[20px] font-medium text-[#424242]'
                    >
                      <p className='expert-paragraph'>{point.text}</p>
                    </div>
                  ))}
                </ul>
              </div>
            </div>
            <div className='w-full p-4 pb-0 md:ml-28 md:w-1/2'>
              <img
                src={item.image}
                className='rounded-2 h-[310px] w-[500px]'
                loading='lazy'
              />
            </div>
          </div>
        )
      )}
    </>
  );
};

export default AvijoAlpha;
