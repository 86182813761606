import React from "react";
import ExpertNav from "../../components/Navbar/ExpertNav";
import Footer from "../../components/Home/footer/Footer";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setMyPath } from "../../Redux/userSlice";

const data1 = [
  {
    title: "Daily Operations Assistant:",
    description:
      "Automate routine tasks like patient follow-ups, appointment scheduling, and data management, giving you more time to focus on care.",
  },
  {
    title: "Diagnosis Support:",
    description:
      "Get a third view on diagnosis reports and prescriptions, reducing human error and increasing diagnostic accuracy.",
  },
  {
    title: "Deep Analysis:",
    description:
      "Analyze complex patient data with AI insights, helping you make informed decisions faster.",
  },
  {
    title: "Global Medical Updates:",
    description:
      "Stay informed with the latest medical knowledge and breakthroughs from around the world, ensuring you are always up to date on the latest advancements in healthcare.",
  },
];

const data2 = [
  {
    title: "24/7 Availability:",
    description:
      "Dr Jii is available anytime, ensuring round-the-clock support for both patients and providers.",
  },
  {
    title: "Preventive Healthcare Insights:",
    description:
      "Dr Jii promotes preventive healthcare by analyzing your history and providing suggestions on lifestyle changes, screenings, and preventive treatments.",
  },
  {
    title: "Medical Knowledge Base:",
    description:
      "Tap into a global medical database updated in real time, providing the latest treatments, medications, and healthcare advancements.",
  },
  {
    title: "Emotional Support:",
    description:
      "For patients, Dr Jii offers comforting responses in times of stress or uncertainty, ensuring that you feel supported on every step of your healthcare journey.",
  },
];

export default function HealthAssistant() {
  const dispatch = useDispatch();
  React.useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setMyPath(window.location.pathname));
  }, []);
  return (
    <div className='App w-[100%]'>
      <ExpertNav />
      <Header />
      <Helmet>
        <title>Dr Jii: Ai Health Assistant | Avijo</title>
        <meta
          name='discription'
          content='Meet Dr Jii, your intelligent health assistant for personalized medical advice, emotional support, and expert guidance. Available anytime, tailored to your needs.'
        />
      </Helmet>
      <div className='mt-10 flex w-full flex-col items-center'>
        <h1 className='text-center font-[Gilroy-SemiBold] text-[36px] text-black'>
          For Patients: Your Personal Health Companion
        </h1>
        <p className='mt-5 w-[80%] text-center font-[Gilroy-SemiBold] text-[18px] text-[#616161]'>
          Dr Jii is more than just a health assistant—it's your 24/7 companion,
          providing expert medical information tailored to your personal health
          profile and history. Whether you have a simple health question or need
          advice on a medical condition, Dr Jii is available anytime to support
          you.
        </p>
      </div>
      <div className='m-4 flex flex-col items-center justify-between rounded-[5px] pb-4 md:flex-row'>
        <div className='col w-[full] md:ml-10 md:w-1/2'>
          <div className='mb-4'>
            <ul className='mb-3 p-0'>
              {[
                {
                  title: "Personalized Information:",
                  description:
                    "Based on your health history with Avijo, Dr Jii delivers precise, relevant medical information.",
                },
                {
                  title: "Complement Your Consultation:",
                  description:
                    "Dr Jii works alongside your doctors, providing additional insights and helping you better understand your health and treatment options.",
                },
                {
                  title: "Emotional Companion:",
                  description:
                    "Not just clinical, Dr Jii is empathetic, offering support and guidance to help you feel more confident and cared for throughout your health journey.",
                },
              ].map((item, index) => (
                <li
                  key={index}
                  className='d-flex align-items-center mb-3 gap-3'
                >
                  <svg
                    width='32'
                    height='32'
                    viewBox='0 0 32 32'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <g clipPath='url(#clip0_1284_1665)'>
                      <path
                        d='M9.43797 15.0706C9.00036 14.633 8.28925 14.633 7.85164 15.0706C7.41404 15.5082 7.41404 16.2193 7.85164 16.6569L13.0209 21.8262C13.2397 22.045 13.5132 22.1544 13.814 22.1544C13.8414 22.1544 13.8414 22.1544 13.8687 22.1544C14.1696 22.127 14.4704 21.9903 14.6892 21.7441L27.626 6.23643C28.0089 5.77147 27.9542 5.06036 27.4893 4.65011C27.0243 4.2672 26.3132 4.3219 25.9029 4.78686L13.732 19.3646L9.43797 15.0706Z'
                        fill='#17AB13'
                      />
                      <path
                        d='M24.3712 2.54431C18.0806 -1.39415 10.0122 -0.464236 4.7609 4.78705C-1.4203 10.9682 -1.4203 21.0605 4.7609 27.2417C7.8515 30.3323 11.9267 31.8913 15.9746 31.8913C20.0224 31.8913 24.0977 30.3323 27.1882 27.2417C32.4669 21.9631 33.3694 13.8674 29.4036 7.57679C29.0754 7.05713 28.3917 6.89303 27.872 7.22123C27.3524 7.54944 27.1882 8.2332 27.5165 8.75286C30.9353 14.1682 30.1421 21.1153 25.6019 25.6554C20.2959 30.9614 11.6259 30.9614 6.31987 25.6554C1.01389 20.3494 1.01389 11.6794 6.31987 6.37337C10.86 1.86055 17.7797 1.06739 23.1951 4.43149C23.7147 4.7597 24.3985 4.59559 24.7267 4.07593C25.0549 3.55628 24.8908 2.87252 24.3712 2.54431Z'
                        fill='#4A4A4A'
                      />
                    </g>
                    <defs>
                      <clipPath id='clip0_1284_1665'>
                        <rect width='32' height='32' fill='white' />
                      </clipPath>
                    </defs>
                  </svg>
                  <div className='flex w-full flex-col items-start'>
                    <h6 className='w-[250px]'>{item.title}</h6>
                    {item.description}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className='w-full p-4 pb-0 md:w-1/2'>
          <img
            src={require("../../Assets/image/healthAssist1.png")}
            alt='banner'
            className='rounded-2 h-[310px] w-[500px] md:ml-10'
          />
        </div>
      </div>
      <div className='mt-10 flex w-full flex-col items-center'>
        <h1 className='text-center font-[Gilroy-SemiBold] text-[36px] text-black'>
          For Providers: Optimizing Healthcare Practices
        </h1>
        <p className='mt-5 w-[80%] text-center font-[Gilroy-SemiBold] text-[18px] text-[#616161]'>
          Healthcare professionals can rely on Dr Jii to automate and streamline
          their daily operations, offering vital assistance to enhance patient
          care and improve workflow efficiency.
        </p>
      </div>
      <div className='m-4 flex flex-col items-center justify-between rounded-[5px] pb-4 md:flex-row'>
        <div className='w-full p-4 pb-0 md:w-1/2'>
          <img
            src={require("../../Assets/image/healthAssist2.png")}
            className='rounded-2 h-[310px] w-[500px] md:ml-10'
            alt='banner2'
          />
        </div>
        <div className='col w-[full] md:ml-10 md:w-1/2'>
          <div className='mb-4'>
            <ul className='mb-3 p-0'>
              {data1.map((item, index) => (
                <li
                  key={index}
                  className='d-flex align-items-center mb-3 gap-3'
                >
                  <svg
                    width='32'
                    height='32'
                    viewBox='0 0 32 32'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <g clipPath='url(#clip0_1284_1665)'>
                      <path
                        d='M9.43797 15.0706C9.00036 14.633 8.28925 14.633 7.85164 15.0706C7.41404 15.5082 7.41404 16.2193 7.85164 16.6569L13.0209 21.8262C13.2397 22.045 13.5132 22.1544 13.814 22.1544C13.8414 22.1544 13.8414 22.1544 13.8687 22.1544C14.1696 22.127 14.4704 21.9903 14.6892 21.7441L27.626 6.23643C28.0089 5.77147 27.9542 5.06036 27.4893 4.65011C27.0243 4.2672 26.3132 4.3219 25.9029 4.78686L13.732 19.3646L9.43797 15.0706Z'
                        fill='#17AB13'
                      />
                      <path
                        d='M24.3712 2.54431C18.0806 -1.39415 10.0122 -0.464236 4.7609 4.78705C-1.4203 10.9682 -1.4203 21.0605 4.7609 27.2417C7.8515 30.3323 11.9267 31.8913 15.9746 31.8913C20.0224 31.8913 24.0977 30.3323 27.1882 27.2417C32.4669 21.9631 33.3694 13.8674 29.4036 7.57679C29.0754 7.05713 28.3917 6.89303 27.872 7.22123C27.3524 7.54944 27.1882 8.2332 27.5165 8.75286C30.9353 14.1682 30.1421 21.1153 25.6019 25.6554C20.2959 30.9614 11.6259 30.9614 6.31987 25.6554C1.01389 20.3494 1.01389 11.6794 6.31987 6.37337C10.86 1.86055 17.7797 1.06739 23.1951 4.43149C23.7147 4.7597 24.3985 4.59559 24.7267 4.07593C25.0549 3.55628 24.8908 2.87252 24.3712 2.54431Z'
                        fill='#4A4A4A'
                      />
                    </g>
                    <defs>
                      <clipPath id='clip0_1284_1665'>
                        <rect width='32' height='32' fill='white' />
                      </clipPath>
                    </defs>
                  </svg>
                  <div className='flex w-full flex-col items-start'>
                    <h6 className='w-[250px]'>{item.title}</h6>
                    {item.description}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className='mt-10 flex w-full flex-col items-center'>
        <h1 className='text-center font-[Gilroy-SemiBold] text-[36px] text-black'>
          Bridging the Gap Between Patients and Providers
        </h1>
        <p className='mt-5 w-[80%] text-center font-[Gilroy-SemiBold] text-[18px] text-[#616161]'>
          Dr Jii serves as a health information platform, bringing together
          healthcare patients and providers. By leveraging data from the Avijo
          ecosystem, Dr Jii enhances medical consultations, supports diagnosis,
          and optimizes care delivery. Importantly, Dr Jii is designed to
          complement medical advice—not replace it. Always consult a licensed
          healthcare professional for medical decisions
        </p>
      </div>
      <div className='mt-10 flex w-full flex-col items-center'>
        <h1 className='text-center font-[Gilroy-SemiBold] text-[36px] text-black'>
          Going Beyond Health Assistance
        </h1>
        <p className='mt-5 w-[80%] text-center font-[Gilroy-SemiBold] text-[18px] text-[#616161]'>
          Healthcare professionals can rely on Dr Jii to automate and streamline
          their daily operations, offering vital assistance to enhance patient
          care and improve workflow efficiency.
        </p>
      </div>
      <div className='m-4 flex flex-col items-center justify-between rounded-[5px] pb-1 md:flex-row'>
        <div className='col w-[full] md:ml-10 md:w-1/2'>
          <div className='mb-4'>
            <ul className='mb-3 p-0'>
              {data2.map((item, index) => (
                <li
                  key={index}
                  className='d-flex align-items-center mb-3 gap-3'
                >
                  <svg
                    width='32'
                    height='32'
                    viewBox='0 0 32 32'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <g clipPath='url(#clip0_1284_1665)'>
                      <path
                        d='M9.43797 15.0706C9.00036 14.633 8.28925 14.633 7.85164 15.0706C7.41404 15.5082 7.41404 16.2193 7.85164 16.6569L13.0209 21.8262C13.2397 22.045 13.5132 22.1544 13.814 22.1544C13.8414 22.1544 13.8414 22.1544 13.8687 22.1544C14.1696 22.127 14.4704 21.9903 14.6892 21.7441L27.626 6.23643C28.0089 5.77147 27.9542 5.06036 27.4893 4.65011C27.0243 4.2672 26.3132 4.3219 25.9029 4.78686L13.732 19.3646L9.43797 15.0706Z'
                        fill='#17AB13'
                      />
                      <path
                        d='M24.3712 2.54431C18.0806 -1.39415 10.0122 -0.464236 4.7609 4.78705C-1.4203 10.9682 -1.4203 21.0605 4.7609 27.2417C7.8515 30.3323 11.9267 31.8913 15.9746 31.8913C20.0224 31.8913 24.0977 30.3323 27.1882 27.2417C32.4669 21.9631 33.3694 13.8674 29.4036 7.57679C29.0754 7.05713 28.3917 6.89303 27.872 7.22123C27.3524 7.54944 27.1882 8.2332 27.5165 8.75286C30.9353 14.1682 30.1421 21.1153 25.6019 25.6554C20.2959 30.9614 11.6259 30.9614 6.31987 25.6554C1.01389 20.3494 1.01389 11.6794 6.31987 6.37337C10.86 1.86055 17.7797 1.06739 23.1951 4.43149C23.7147 4.7597 24.3985 4.59559 24.7267 4.07593C25.0549 3.55628 24.8908 2.87252 24.3712 2.54431Z'
                        fill='#4A4A4A'
                      />
                    </g>
                    <defs>
                      <clipPath id='clip0_1284_1665'>
                        <rect width='32' height='32' fill='white' />
                      </clipPath>
                    </defs>
                  </svg>
                  <div className='flex w-full flex-col items-start'>
                    <h6 className='w-[250px]'>{item.title}</h6>
                    {item.description}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className='w-full p-4 pb-0 md:w-1/2'>
          <img
            src={require("../../Assets/image/healthAssist3.png")}
            className='rounded-2 h-[310px] w-[500px] md:ml-10'
          />
        </div>
      </div>
      <div className='mt-10 flex w-full flex-col items-center'>
        <h1 className='text-center font-[Gilroy-SemiBold] text-[36px] text-black'>
          Security and Data Privacy
        </h1>
        <p className='mt-5 w-[60%] text-center font-[Gilroy-SemiBold] text-[18px] text-[#616161]'>
          Your privacy is our priority. Dr Jii operates under strict data
          security measures, ensuring that all personal and medical information
          is protected and stored securely.
        </p>
      </div>
      <div className='mt-10 flex w-full flex-col items-center'>
        <h1 className='text-center font-[Gilroy-SemiBold] text-[36px] text-black'>
          Dr Jii: A Health Revolution with Avijo
        </h1>
        <p className='mt-5 w-[90%] text-center font-[Gilroy-SemiBold] text-[18px] text-[#616161]'>
          Dr Jii is more than just a health assistant—it’s a tool for
          empowerment. For healthcare patients, it provides peace of mind
          through instant, personalized information. For providers, it
          transforms daily practice with automation, deep analysis, and access
          to cutting-edge medical updates. With Dr Jii, healthcare becomes more
          accessible, efficient, and supportive—driving better outcomes for
          everyone.
        </p>
        {/* <p className=" text-start w-[90%] font-[Gilroy-SemiBold] text-[20px] text-[#0097DB]">
                    Join the healthcare revolution with Dr Jii—empowering health by
                </p> */}
      </div>
      <Link to='/downloadapp' className='no-underline'>
        <button className='border-1 mb-[1%] ml-[10%] mt-4 flex h-10 w-[200px] flex-row items-center justify-center rounded-full border-[black] bg-[white]'>
          <span className='self-center font-[Gilroy-SemiBold] text-[16px] text-[black]'>
            Download the app
          </span>
          <img
            src={require("../../Assets/image/right2.png")}
            className='ml-2 h-[20px] w-[20px]'
          />
        </button>
      </Link>
      <Footer />
    </div>
  );
}

const Header = () => {
  return (
    <div className='mb-10 mt-[5%] flex w-full flex-col items-center'>
      <h1 className='text-center font-[Gilroy-SemiBold] text-[36px] text-black'>
        Meet Dr Jii: Your AI Health Assistant
      </h1>
      <p className='mt-5 w-[80%] text-center font-[Gilroy-SemiBold] text-[18px] text-[#616161]'>
        At Avijo, we are revolutionizing healthcare with Dr Jii—an advanced, AI
        health assistant designed for both healthcare patients and providers. Dr
        Jii is here to enhance your healthcare journey by providing expert
        guidance, optimized support, and valuable insights—right at your
        fingertips
      </p>
      <div className='flex flex-row'>
        <Link to='/' className='no-underline'>
          <button className='mt-4 flex h-10 w-[130px] flex-row items-center justify-center rounded-full bg-[#0097DB]'>
            <span className='text-sm text-white'>Start now</span>
            <img
              src={require("../../Assets/image/file.png")}
              className='ml-2 h-[20px] w-[20px]'
              alt='icon'
            />
          </button>
        </Link>
        <Link to='/downloadapp' className='no-underline'>
          {" "}
          <button className='border-1 ml-4 mt-4 flex h-10 w-[200px] flex-row items-center justify-center rounded-full border-[black] bg-[white]'>
            <span className='self-center font-[Gilroy-SemiBold] text-[16px] text-[black]'>
              Download the app
            </span>
            <img
              src={require("../../Assets/image/right2.png")}
              className='ml-2 h-[20px] w-[20px]'
            />
          </button>
        </Link>
      </div>
    </div>
  );
};
