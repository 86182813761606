import React from "react";
import { Link } from "react-router-dom";

const HomeDetailedCard = ({ image, title, link, description }) => {
  const containerClass = description
    ? "w-[260px] h-[286px] gap-[32px]"
    : "w-[166px] h-[212px] gap-[10px]";

  const imageClass = description
    ? "w-[260px] h-[177px] object-cover rounded-t-[20px]"
    : "w-[158px] h-[139px] object-cover rounded-[16px]";

  const titleClass = description
    ? "w-[254px] h-[75px] font-lato font-bold text-[28px] leading-[100%] tracking-[0%] text-gray-800 text-center"
    : "mt-2 font-lato font-semibold text-[18px] leading-[136%] tracking-[0.5px] text-gray-800 text-center align-bottom";

  const descriptionClass = description
    ? "w-[254px] h-[75px] font-lato font-semibold text-[16px] leading-[100%] tracking-[0%] text-gray-600 text-center"
    : "text-gray-600 text-sm mt-1 text-center";

  return (
    <Link to={link} className='block no-underline'>
      <div
        className={`transform rounded-[20px] border border-gray-300 bg-white p-[4px] shadow-sm transition-transform hover:scale-[1.02] hover:shadow-sm ${containerClass}`}
      >
        <img src={image} alt={title} className={imageClass} />
        <h3 className={titleClass}>{title}</h3>
        {description && <p className={descriptionClass}>{description}</p>}
      </div>
    </Link>
  );
};

export default HomeDetailedCard;
