import { useState } from "react";
import {
  FaUser,
  FaClipboardList,
  FaShoppingBag,
  FaMapMarkerAlt,
  FaClock,
  FaAmbulance,
  FaHeadset,
  FaSignOutAlt,
} from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import {
  setIsProfilePanelOpen,
  setUserLoggedOut,
} from "../../../Redux/userSlice";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import { toastHandler } from "../../../utils/toastHandler";
import axios from "../../../axiosConfig";
import Cookies from "js-cookie";

const menuItems = [
  { name: "ABHA", icon: <FaClipboardList />, link: window.location.pathname },
  {
    name: "Order & Booking",
    icon: <FaShoppingBag />,
    link: window.location.pathname,
  },
  {
    name: "Addresses",
    icon: <FaMapMarkerAlt />,
    link: window.location.pathname,
  },
  { name: "Reminders", icon: <FaClock />, link: window.location.pathname },
  {
    name: "Ambulance",
    icon: <FaAmbulance />,
    emergency: true,
    link: window.location.pathname,
  },
  {
    name: "Support and Help",
    icon: <FaHeadset />,
    link: window.location.pathname,
  },
];

const profileOptions = [
  { name: "Edit Profile", link: window.location.pathname },
  { name: "Set password", link: window.location.pathname },
  { name: "Update mobile number", link: window.location.pathname },
  { name: "Re-Kyc verification", link: window.location.pathname },
  { name: "Deactivate/Delete ABHA", link: window.location.pathname },
];

const ProfilePanel = () => {
  const [showProfileModal, setShowProfileModal] = useState(false);
  const isProfilePanelOpen = useSelector(
    (state) => state.user.isProfilePanelOpen
  );
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userData);

  const logoutHandler = async () => {
    try {
      // First make the logout request
      const res = await axios.put("/user/logout");
      await toastHandler(res, "Logging Out", "Logout Successful", "Logout Failed");
      
      // If logout was successful, remove cookies and update state
      const cookieOptions = { 
        path: "/",
        secure: true,
        sameSite: "None"
      };
      
      // Clear both cookies
      Cookies.remove("tokenUser", cookieOptions);
      Cookies.remove("token", cookieOptions);
      
      dispatch(setUserLoggedOut());
    } catch (error) {
      console.error("Logout failed:", error);
    }
  }

  return (
    <>
      {/* Profile Panel */}
      {isProfilePanelOpen && (
        <div className='absolute right-[-3rem] z-50 h-fit w-[17rem] border-r border-gray-300 bg-white p-4 shadow-lg'>
          {/* Profile Header */}
          <div
            className='flex cursor-pointer items-center gap-3 rounded-lg p-2 hover:bg-gray-100'
            onMouseEnter={() => setShowProfileModal(true)}
            onMouseLeave={() => setShowProfileModal(false)}
          >
            <img
              src={userData && userData.userImage}
              alt='Profile'
              className='h-10 w-10 rounded-full'
            />
            <span className='font-semibold'>{userData?.fullName}</span>
            <MdKeyboardArrowRight />
          </div>

          {/* Profile Dropdown */}
          {/* {showProfileModal && (
            <div
              className="absolute left-[80%] top-[4rem] -mt-4 w-48 rounded-lg bg-white p-3 shadow-lg border border-gray-200"
              onMouseEnter={() => setShowProfileModal(true)}
              onMouseLeave={() => setShowProfileModal(false)}
            >
              {profileOptions.map((option, index) => (
                <Link to={option.link} key={index} className="no-underline">
                  <div
                    onClick={() => dispatch(setIsProfilePanelOpen(false))}
                    className="cursor-pointer rounded-lg p-2 text-gray-700 hover:bg-gray-200"
                  >
                    {option.name}
                  </div>
                </Link>
              ))}
            </div>
          )} */}

          {/* Sidebar Menu */}
          <div className='mt-4'>
            {menuItems.map((item, index) => (
              <Link to={item.link} key={index} className='no-underline'>
                <div
                  onClick={() => dispatch(setIsProfilePanelOpen(false))}
                  className='flex cursor-pointer items-center gap-3 rounded-lg p-2 text-gray-700 hover:bg-gray-100'
                >
                  <span className='text-gray-600'>{item.icon}</span>
                  <span>{item.name}</span>
                  {item.emergency && (
                    <span className='ml-[-4px] rounded-md border-2 border-red-500 bg-white px-1 text-[10px] font-bold text-red-500'>
                      EMERGENCY
                    </span>
                  )}
                </div>
              </Link>
            ))}
            <div
              className='flex cursor-pointer items-center gap-3 rounded-lg p-2 hover:bg-gray-100'
              onClick={() => logoutHandler()}
            >
              <span className='text-gray-600'>
                <FaSignOutAlt />
              </span>
              <span>Logout</span>
            </div>
          </div>
        </div>
      )}

      {/* Click Outside to Close */}
      {isProfilePanelOpen && (
        <div
          className='fixed inset-0 z-40'
          onClick={() => dispatch(setIsProfilePanelOpen(false))}
        />
      )}
    </>
  );
};

export default ProfilePanel;
