import React, { useState } from "react";
import { MdOutlineDriveFolderUpload, MdVerified } from "react-icons/md";
import { FaChevronDown } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import CollapsibleDropdown from "../components/CollapsibleDropdown";
import {
  FaLandmarkDome,
  FaLocationDot,
  FaLocationPin,
  FaLocationPinLock,
} from "react-icons/fa6";

export default function HfrRegister() {
  const [tab, setTab] = useState(0);

  const [trackingId, setTrackingId] = useState("");
  const [facilityName, setFacilityName] = useState("Eye Care Center");
  const [ownershipCode, setOwnershipCode] = useState("G");
  const [ownershipSubTypeCode, setOwnershipSubTypeCode] = useState("");
  const [ownershipSubTypeCode2, setOwnershipSubTypeCode2] = useState("MOHF");

  const [loading, setLoading] = useState(false);

  const [facilityAddressDetails, setFacilityAddressDetails] = useState({
    country: "India",
    stateLGDCode: "35",
    districtLGDCode: "",
    subDistrictLGDCode: "4023",
    facilityRegion: "",
    villageCityTownLGDCode: "534829",
    addressLine1: "SushrutMultispeciality Clinic",
    addressLine2: "Gandhi chowk,opp.Dr.Kazi Hospital",
    pincode: "441304",
    latitude: "2",
    longitude: "7",
  });

  const [facilityContactInformation, setFacilityContactInformation] = useState({
    facilityContactNumber: "",
    facilityEmailId: "",
    facilityLandlineNumber: "",
    facilityStdCode: "",
    websiteLink: "",
  });

  const [systemOfMedicineCode, setSystemOfMedicineCode] = useState("M");
  const [facilityTypeCode, setFacilityTypeCode] = useState("5");
  const [facilitySubType, setFacilitySubType] = useState("");
  const [specialityTypeCode, setSpecialityTypeCode] = useState("");
  const [typeOfServiceCode, setTypeOfServiceCode] = useState("OPD");
  const [facilityOperationalStatus, setFacilityOperationalStatus] =
    useState("");

  const [facilityUploads, setFacilityUploads] = useState({
    facilityBoardPhoto: {
      name: "",
      value: "",
    },
    facilityBuildingPhoto: {
      name: "",
      value: "",
    },
  });

  const [facilityAddressProof, setFacilityAddressProof] = useState([
    {
      addressProofType: "",
      addressProofAttachment: {
        name: "",
        value: "",
      },
    },
  ]);

  const [timingsOfFacility, setTimingsOfFacility] = useState([
    {
      workingDays: "Tue",
      openingHours: "1:00 AM - 2:00 pm",
    },
    {
      workingDays: "Wed",
      openingHours: "1:00 AM - 2:00 pm",
    },
  ]);

  const [abdmCompliantSoftware, setAbdmCompliantSoftware] = useState([
    {
      existingSoftwares: ["12121221", "12121111"],
      anyOther: "",
    },
  ]);
  const data = {
    trackingId: "",
    facilityInformation: {
      facilityName: "Eye Care Center",
      ownershipCode: "G",
      ownershipSubTypeCode: "",
      ownershipSubTypeCode2: "MOHF",
      facilityAddressDetails: {
        facilityAddressDetails,
      },
      facilityContactInformation: {
        facilityContactInformation,
      },
      systemOfMedicineCode: "M",
      facilityTypeCode: "5",
      facilitySubType: "",
      specialityTypeCode: "",
      typeOfServiceCode: "OPD",
      facilityOperationalStatus: "",
      facilityUploads: {
        facilityBoardPhoto: {
          name: "",
          value: "",
        },
        facilityBuildingPhoto: {
          name: "",
          value: "",
        },
      },
      facilityAddressProof: [
        {
          addressProofType: "",
          addressProofAttachment: {
            name: "",
            value: "",
          },
        },
      ],
      timingsOfFacility: [
        {
          workingDays: "Tue",
          openingHours: "1:00 AM - 2:00 pm",
        },
        {
          workingDays: "Wed",
          openingHours: "1:00 AM - 2:00 pm",
        },
      ],
      abdmCompliantSoftware: [
        {
          existingSoftwares: ["12121221", "12121111"],
          anyOther: "",
        },
      ],
    },
  };

  const handleMobileNumber = (e) => {
    setFacilityContactInformation({
      ...facilityContactInformation,
      facilityContactNumber: e.target.value,
    });
  };

  const handleEmail = (e) => {
    setFacilityContactInformation({
      ...facilityContactInformation,
      facilityEmailId: e.target.value,
    });
  };

  const handleFacilityName = (e) => {
    setFacilityName(e.target.value);
  };

  const handleCountry = (e) => {
    setFacilityAddressDetails({
      ...facilityAddressDetails,
      country: e.target.value,
    });
  };

  const handleState = (e) => {
    setFacilityAddressDetails({
      ...facilityAddressDetails,
      stateLGDCode: e.target.value,
    });
  };
  const handleRegion = (e) => {
    setFacilityAddressDetails({
      ...facilityAddressDetails,
      facilityRegion: e.target.value,
    });
  };

  const handleCity = (e) => {
    setFacilityAddressDetails({
      ...facilityAddressDetails,
      villageCityTownLGDCode: e.target.value,
    });
  };

  const handleAddress1 = (e) => {
    setFacilityAddressDetails({
      ...facilityAddressDetails,
      addressLine1: e.target.value,
    });
  };
  const handleAddress2 = (e) => {
    setFacilityAddressDetails({
      ...facilityAddressDetails,
      addressLine2: e.target.value,
    });
  };

  const handlePincode = (e) => {
    setFacilityAddressDetails({
      ...facilityAddressDetails,
      pincode: e.target.value,
    });
  };

  const handleLandlineNumber = (e) => {
    e.preventDefault();
    setFacilityContactInformation({
      ...facilityContactInformation,
      facilityLandlineNumber: e.target.value,
    });
  };
  const handleWebsite = (e) => {
    setFacilityContactInformation({
      ...facilityContactInformation,
      websiteLink: e.target.value,
    });
  };

  const handleDistrict = (option) => {
    setFacilityAddressDetails({
      ...facilityAddressDetails,
      districtLGDCode: option,
    });
  };

  const handleSubDistrict = (option) => {
    setFacilityAddressDetails({
      ...facilityAddressDetails,
      subDistrictLGDCode: option,
    });
  };

  const postData = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `https://facilitysbx.abdm.gov.in/FacilityManagement/v1.5/facility/basic-information`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // or any other type depending on your needs
          },
          body: JSON.stringify(data), // converting JS object to JSON string
        }
      );

      // Check if the response status is OK (200-299)
      if (response.ok) {
        const responseData = await response.json(); // Parse JSON response
        console.log("Success:", responseData);
        alert("success...");
      } else {
        console.log("Error:", response.statusText);
        alert("error posting...");
      }
    } catch (error) {
      console.error("Request failed:", error);
    } finally {
      setLoading(false);
    }
  };

  const Details = () => {
    return (
      <div className='mb-2 mt-4 flex w-full flex-col items-center bg-white pb-4 pt-4'>
        <div className='flex w-full flex-row items-center justify-center'>
          <div className='flex h-[15px] w-[15px] flex-col items-center justify-center rounded-full bg-[#0095D9]'>
            <TiTick className='text-regular self-center text-[14px] text-white' />
          </div>
          <div className='h-1 w-[15%] bg-[#0095D9]' />
          <div className='flex h-[15px] w-[15px] flex-col items-center justify-center rounded-full bg-[#0095D9]'>
            <span className='text-semibold text-[10px] text-white'>2</span>
          </div>
          <div className='h-1 w-[20%] bg-gray-200' />
          <div className='flex h-[15px] w-[15px] flex-col items-center justify-center rounded-full bg-gray-200'>
            <span className='text-semibold text-[10px] text-gray-500'>3</span>
          </div>
          <div className='h-1 w-[15%] bg-gray-200' />
          <div className='flex h-[15px] w-[15px] flex-col items-center justify-center rounded-full bg-gray-200'>
            <span className='text-semibold text-[10px] text-gray-500'>4</span>
          </div>
          <div className='h-1 w-[15%] bg-gray-200' />
        </div>
        <div className='mr-[10%] mt-2 flex w-[65%] flex-row items-center justify-between'>
          <span className='text-semibold w-[25%] text-[10px] text-[#0095D9]'>
            Personal Details
          </span>
          <span className='w-[25%] text-[10px] text-gray-500'>
            Qualification & Registration Details
          </span>
          <span className='w-[25%] text-center text-[10px] text-gray-500'>
            Work Details
          </span>
          <span className='w-[25%] text-center text-[10px] text-gray-500'>
            Preview Profile
          </span>
        </div>
        <div className='mt-[5%] flex w-full flex-col items-start'>
          <div className='ml-[5%] flex h-[40px] w-[70%] flex-col justify-center rounded-md bg-[#0095D9] md:w-[30%]'>
            <span className='text-semiBold ml-4 text-[18px] text-white'>
              System of Medicine
            </span>
          </div>
        </div>
        <div className='md: mt-4 flex w-[90%] flex-col items-center justify-between md:flex-row'>
          <div className='d-flex flex-column w-[90%] md:w-[30%]'>
            <span className='mt-2 text-[12px] font-semibold text-gray-500'>
              Category
            </span>
            <div className='mt-2 flex h-[50px] w-full flex-row rounded-[10px] border border-gray-500'>
              <span className='h-40 w-[90%] pl-2 pt-2.5 text-start text-[14px] text-black'>
                Doctor
              </span>
              <div className='w-[10%]'>
                <FaChevronDown className='mt-3 text-[15px]' />
              </div>
            </div>
          </div>
          <div className='d-flex flex-column w-[90%] md:w-[30%]'>
            <span className='mt-2 text-[12px] font-semibold text-gray-500'>
              System of medicine
            </span>
            <div className='mt-2 flex h-[50px] w-full flex-row rounded-[10px] border border-gray-500'>
              <span className='h-40 w-[90%] pl-2 pt-2.5 text-start text-[14px] text-black'>
                Modern Medicine
              </span>
              <div className='w-[10%]'>
                <FaChevronDown className='mt-3 text-[15px]' />
              </div>
            </div>
          </div>
          <div className='d-flex flex-column w-[90%] md:w-[30%]'></div>
        </div>
        <div className='mt-[5%] flex w-full flex-col items-start'>
          <div className='ml-[5%] flex h-[40px] w-[70%] flex-col justify-center rounded-md bg-[#0095D9] md:w-[30%]'>
            <span className='text-semiBold ml-4 text-[18px] text-white'>
              Registration Details
            </span>
          </div>
        </div>
        <div className='md: mt-4 flex w-[90%] flex-col items-center justify-between md:flex-row'>
          <div className='d-flex flex-column w-[90%] md:w-[30%]'>
            <span className='mt-2 text-[12px] font-semibold text-gray-500'>
              Register with council
            </span>
            <div className='mt-2 flex h-[50px] w-full flex-row rounded-[10px] border border-gray-500'>
              <span className='h-40 w-[90%] pl-2 pt-2.5 text-start text-[14px] text-black'>
                Doctor
              </span>
              <div className='w-[10%]'>
                <FaChevronDown className='mt-3 text-[15px]' />
              </div>
            </div>
          </div>
          <div className='d-flex flex-column mt-2 w-[90%] md:w-[30%]'>
            <span className='text-[12px] font-semibold text-gray-500'>
              Registration Number
            </span>
            <div className='mt-2 flex h-[50px] w-full flex-row items-center rounded-[10px] border border-gray-500 focus:border-[#0097DB]'>
              <input
                type='text'
                // name="fullName"
                // value={formData.fullName}
                // onChange={handleInputChange}
                // required
                className='h-[40px] w-full pl-2 text-[14px] text-black placeholder-black focus:outline-none'
              />
              {/* <div className="w-[10%]">
                                <MdVerified className="text-[#12DB00] self-center" />
                            </div> */}
            </div>
          </div>
          <div className='d-flex flex-column w-[90%] md:w-[30%]'>
            <span className='mt-2 text-[12px] font-semibold text-gray-500'>
              Date of first Registration
            </span>
            <div className='mt-2 flex h-[50px] w-full flex-row rounded-[10px] border border-gray-500'>
              <span className='h-40 w-[90%] pl-2 pt-2.5 text-start text-[14px] text-black'>
                DD/MM/YYYY
              </span>
              <div className='w-[10%]'>
                <FaChevronDown className='mt-3 text-[15px]' />
              </div>
            </div>
          </div>
        </div>
        <span className='mt-4 w-[90%] text-[12px] font-semibold text-gray-500'>
          Is the registration permanent or renewal?
        </span>
        <div className='flex w-[90%] flex-row items-center'>
          <div className='d-flex mt-2 flex-row items-center'>
            {" "}
            <input
              type='radio'
              name='gender'
              className='input-radio mt-0 h-[15px] w-[15px]'
              value='Male'
              // checked={formData.gender === "Male"}
              // onChange={handleChange}
            />{" "}
            <span className='text-[12px] font-semibold text-gray-400'>
              Renewal
            </span>
          </div>
          <div className='d-flex ml-4 mt-2 flex-row items-center'>
            {" "}
            <input
              type='radio'
              name='gender'
              className='input-radio mt-0 h-[15px] w-[15px]'
              value='Male'
              // checked={formData.gender === "Male"}
              // onChange={handleChange}
            />{" "}
            <span className='text-[12px] font-semibold text-gray-400'>
              Permanent
            </span>
          </div>
        </div>
        <span className='mt-4 w-[90%] text-[12px] font-semibold text-gray-500'>
          Registration Certificate Attachment
        </span>
        <div className='mt-4 w-[90%] items-start'>
          <div className='flex h-[45px] w-[90%] flex-row items-center justify-center rounded-md border border-gray-500 md:w-[60%]'>
            <MdOutlineDriveFolderUpload className='text-xl text-blue-500' />
            <span className='pl-2 text-[12px] font-semibold text-gray-400'>
              Drag and Drop File or Browse
            </span>
          </div>
        </div>
        <span className='mt-4 w-[90%] text-[12px] font-semibold text-gray-500'>
          Is your name in registration certificate the same as your name in
          Aadhar?
        </span>
        <div className='flex w-[90%] flex-row items-center'>
          <div className='d-flex mt-2 flex-row items-center'>
            {" "}
            <input
              type='radio'
              name='gender'
              className='input-radio mt-0 h-[15px] w-[15px]'
              value='Male'
              // checked={formData.gender === "Male"}
              // onChange={handleChange}
            />{" "}
            <span className='text-[12px] font-semibold text-gray-400'>Yes</span>
          </div>
          <div className='d-flex ml-4 mt-2 flex-row items-center'>
            {" "}
            <input
              type='radio'
              name='gender'
              className='input-radio mt-0 h-[15px] w-[15px]'
              value='Male'
              // checked={formData.gender === "Male"}
              // onChange={handleChange}
            />{" "}
            <span className='text-[12px] font-semibold text-gray-400'>No</span>
          </div>
        </div>
        <div className='mt-[2%] flex w-full flex-col items-start'>
          <div className='ml-[5%] flex h-[40px] w-[70%] flex-col justify-center rounded-md bg-[#0095D9] md:w-[30%]'>
            <span className='text-semiBold ml-4 text-[18px] text-white'>
              Qualification Details
            </span>
          </div>
        </div>
        <div className='mt-2 flex w-[90%] flex-row items-center'>
          <div className='d-flex mt-2 flex-row items-center'>
            {" "}
            <input
              type='radio'
              name='gender'
              className='input-radio mt-0 h-[15px] w-[15px]'
              value='Male'
              // checked={formData.gender === "Male"}
              // onChange={handleChange}
            />{" "}
            <span className='text-[12px] font-semibold text-gray-400'>
              India
            </span>
          </div>
          <div className='d-flex ml-4 mt-2 flex-row items-center'>
            {" "}
            <input
              type='radio'
              name='gender'
              className='input-radio mt-0 h-[15px] w-[15px]'
              value='Male'
              // checked={formData.gender === "Male"}
              // onChange={handleChange}
            />{" "}
            <span className='text-[12px] font-semibold text-gray-400'>
              Other
            </span>
          </div>
        </div>
        <div className='md: mt-4 flex w-[90%] flex-col items-center justify-between md:flex-row'>
          <div className='d-flex flex-column w-[90%] md:w-[68%]'>
            <span className='mt-2 text-[12px] font-semibold text-gray-500'>
              Name of Degree/Diploma
            </span>
            <div className='mt-2 flex h-[50px] w-full flex-row rounded-[10px] border border-gray-500'>
              <span className='h-40 w-[95%] pl-2 pt-2.5 text-start text-[14px] text-black'></span>
              <div className='w-[5%]'>
                <FaChevronDown className='mt-3 text-[15px]' />
              </div>
            </div>
          </div>
          <div className='d-flex flex-column w-[90%] md:w-[30%]'>
            <span className='mt-2 text-[12px] font-semibold text-gray-500'>
              Country
            </span>
            <div className='mt-2 flex h-[50px] w-full flex-row rounded-[10px] border border-gray-500'>
              <span className='h-40 w-[90%] pl-2 pt-2.5 text-start text-[14px] text-black'>
                India
              </span>
              <div className='w-[10%]'>
                <FaChevronDown className='mt-3 text-[15px]' />
              </div>
            </div>
          </div>
        </div>
        <div className='md: mt-4 flex w-[90%] flex-col items-center justify-between md:flex-row'>
          <div className='d-flex flex-column w-[90%] md:w-[30%]'>
            <span className='mt-2 text-[12px] font-semibold text-gray-500'>
              State
            </span>
            <div className='mt-2 flex h-[50px] w-full flex-row rounded-[10px] border border-gray-500'>
              <span className='h-40 w-[90%] pl-2 pt-2.5 text-start text-[14px] text-black'></span>
              <div className='w-[10%]'>
                <FaChevronDown className='mt-3 text-[15px]' />
              </div>
            </div>
          </div>
          <div className='d-flex flex-column w-[90%] md:w-[30%]'>
            <span className='mt-2 text-[12px] font-semibold text-gray-500'>
              College
            </span>
            <div className='mt-2 flex h-[50px] w-full flex-row rounded-[10px] border border-gray-500'>
              <span className='h-40 w-[90%] pl-2 pt-2.5 text-start text-[14px] text-black'></span>
              <div className='w-[10%]'>
                <FaChevronDown className='mt-3 text-[15px]' />
              </div>
            </div>
          </div>
          <div className='d-flex flex-column w-[90%] md:w-[30%]'>
            <span className='mt-2 text-[12px] font-semibold text-gray-500'>
              University
            </span>
            <div className='mt-2 flex h-[50px] w-full flex-row rounded-[10px] border border-gray-500'>
              <span className='h-40 w-[90%] pl-2 pt-2.5 text-start text-[14px] text-black'>
                University
              </span>
              <div className='w-[10%]'>
                <FaChevronDown className='mt-3 text-[15px]' />
              </div>
            </div>
          </div>
        </div>
        <div className='md: mt-4 flex w-[90%] flex-col items-center justify-between md:flex-row'>
          <div className='d-flex flex-column w-[90%] md:w-[30%]'>
            <span className='mt-2 text-[12px] font-semibold text-gray-500'>
              Passing month
            </span>
            <div className='mt-2 flex h-[50px] w-full flex-row rounded-[10px] border border-gray-500'>
              <span className='h-40 w-[90%] pl-2 pt-2.5 text-start text-[14px] text-black'></span>
              <div className='w-[10%]'>
                <FaChevronDown className='mt-3 text-[15px]' />
              </div>
            </div>
          </div>
          <div className='d-flex flex-column w-[90%] md:w-[30%]'>
            <span className='mt-2 text-[12px] font-semibold text-gray-500'>
              Passing year
            </span>
            <div className='mt-2 flex h-[50px] w-full flex-row rounded-[10px] border border-gray-500'>
              <span className='h-40 w-[90%] pl-2 pt-2.5 text-start text-[14px] text-black'></span>
              <div className='w-[10%]'>
                <FaChevronDown className='mt-3 text-[15px]' />
              </div>
            </div>
          </div>
          <div className='d-flex flex-column w-[90%] md:w-[30%]'></div>
        </div>
        <span className='mt-4 w-[90%] text-[12px] font-semibold text-gray-500'>
          Upload Degree/Diploma
        </span>
        <div className='mt-4 w-[90%] items-start'>
          <div className='flex h-[45px] w-[90%] flex-row items-center justify-center rounded-md border border-gray-500 md:w-[60%]'>
            <MdOutlineDriveFolderUpload className='text-xl text-blue-500' />
            <span className='pl-2 text-[12px] font-semibold text-gray-400'>
              Drag and Drop File or Browse
            </span>
          </div>
        </div>
        <span className='mt-4 w-[90%] text-[12px] font-semibold text-gray-500'>
          Is your name in the Degree same as your name in Aadhar?
        </span>
        <div className='flex w-[90%] flex-row items-center'>
          <div className='d-flex mt-2 flex-row items-center'>
            {" "}
            <input
              type='radio'
              name='gender'
              className='input-radio mt-0 h-[15px] w-[15px]'
              value='Male'
              // checked={formData.gender === "Male"}
              // onChange={handleChange}
            />{" "}
            <span className='text-[12px] font-semibold text-gray-400'>Yes</span>
          </div>
          <div className='d-flex ml-4 mt-2 flex-row items-center'>
            {" "}
            <input
              type='radio'
              name='gender'
              className='input-radio mt-0 h-[15px] w-[15px]'
              value='Male'
              // checked={formData.gender === "Male"}
              // onChange={handleChange}
            />{" "}
            <span className='text-[12px] font-semibold text-gray-400'>No</span>
          </div>
        </div>
        <div className='mt-4 w-[90%] items-start'>
          <div className='flex h-[45px] w-[90%] flex-row items-center justify-center rounded-md border border-gray-500 md:w-[60%]'>
            <MdOutlineDriveFolderUpload className='text-xl text-blue-500' />
            <span className='pl-2 text-[12px] font-semibold text-gray-400'>
              Drag and Drop File or Browse
            </span>
          </div>
        </div>
        <div className='mt-8 flex w-[90%] flex-row items-center justify-between pb-4'>
          <button
            onClick={() => setTab(1)}
            className='flex h-[40px] w-[100px] flex-row items-center justify-center rounded-md bg-[#B8B8B8]'
          >
            <span className='text-sm text-[white]'>Back</span>
          </button>
          <button
            onClick={() => setTab(0)}
            className='ml-4 flex h-[40px] w-[100px] flex-row items-center justify-center rounded-md bg-[#0095D9]'
          >
            <span className='text-sm text-[white]'>Save as Draft</span>
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className='flex w-full flex-col items-center bg-gray-100'>
      <div className='item-center mt-4 flex w-full flex-row bg-white pb-2 pt-4'>
        <div className='ml-4 h-[70px] w-[70px] rounded-full border border-[grey] bg-gray-200'></div>
        <div className='ml-5 flex flex-col items-start'>
          <span className='text-[14px] font-semibold text-gray-500'>
            Jane Cooper
          </span>
          <span className='text-[14px] text-gray-400'>Gender: Male</span>
          <span className='text-[14px] text-gray-400'>
            Date of Birth: 12-04-2001
          </span>
        </div>
      </div>
      {tab === 0 && (
        <PersonalDetail
          facilityContactNumber={
            facilityContactInformation.facilityContactNumber
          }
          handleMobileNumber={handleMobileNumber}
          facilityEmailId={facilityContactInformation.facilityEmailId}
          handleEmail={handleEmail}
          districtLGDCode={facilityAddressDetails.districtLGDCode}
          handleDistrict={handleDistrict}
          subDistrictLGDCode={facilityAddressDetails.subDistrictLGDCode}
          handleSubDistrict={handleSubDistrict}
          onSubmit={() => setTab(1)}
        />
      )}
      {tab === 1 && (
        <Registration
          facilityName={facilityName}
          handleFacilityName={handleFacilityName}
          country={facilityAddressDetails.country}
          handleCountry={handleCountry}
          stateLGDCode={facilityAddressDetails.stateLGDCode}
          handleState={handleState}
          // districtLGDCode={facilityAddressDetails.districtLGDCode}
          // handleDistrict={handleDistrict}
          // subDistrictLGDCode={facilityAddressDetails.subDistrictLGDCode}
          // handleSubDistrict={handleSubDistrict}
          facilityRegion={facilityAddressDetails.facilityRegion}
          handleRegion={handleRegion}
          villageCityTownLGDCode={facilityAddressDetails.villageCityTownLGDCode}
          handleCity={handleCity}
          addressLine1={facilityAddressDetails.addressLine1}
          handleAddress1={handleAddress1}
          addressLine2={facilityAddressDetails.addressLine2}
          handleAddress2={handleAddress2}
          pincode={facilityAddressDetails.pincode}
          handlePincode={handlePincode}
          facilityLandlineNumber={
            facilityContactInformation.facilityLandlineNumber
          }
          handleLandlineNumber={handleLandlineNumber}
          facilityContactNumber={
            facilityContactInformation.facilityContactNumber
          }
          handleMobileNumber={handleMobileNumber}
          facilityEmailId={facilityContactInformation.facilityEmailId}
          handleEmail={handleEmail}
          websiteLink={facilityContactInformation.websiteLink}
          handleWebsite={handleWebsite}
          loading={loading}
          onSave={postData}
        />
      )}
      {tab === 2 && <Details />}
      {tab === 0 && (
        <div className='mt-8 flex w-full flex-col items-center justify-center pb-8'>
          <h2 className='text-black'>Need Help?</h2>
          <span className='mt-2 w-[90%] text-center text-[10px] font-semibold text-gray-500 md:w-[25%]'>
            If you have questions regarding healthcare Professional ID, Please
            go through our <span className='text-[#0095D9]'>FAQS section</span>
          </span>
          <span className='mt-2 w-[90%] text-center text-[10px] font-semibold text-gray-500 md:w-[25%]'>
            If you are not able to register or are facing other issues with
            registration, please contact us at{" "}
            <span className='text-[#0095D9]'>avijo.in</span>
          </span>
          <span className='mt-2 w-[90%] text-center text-[10px] font-semibold text-gray-500 md:w-[25%]'>
            Or call us at our toll free number -{" "}
            <span className='text-black'>1800-11-4477 / 14477</span>
          </span>
          <button className='ml-4 mt-4 flex h-[40px] w-[100px] flex-row items-center justify-center rounded-md bg-[#0095D9]'>
            <span className='pl-2 text-sm text-[white]'>Get Help</span>
          </button>
        </div>
      )}
    </div>
  );
}

// facilityContactInformation

const PersonalDetail = (props) => {
  return (
    <div className='mb-2 mt-4 flex w-full flex-col items-center bg-white pb-4 pt-4'>
      <span className='w-[95%] text-[26px] font-semibold text-[#0097DB]'>
        Registration From (Mobile verification is required)
      </span>
      <div className='md: mt-4 flex w-[95%] flex-col items-center justify-between md:flex-row'>
        <div className='d-flex flex-column mt-2 w-[90%] md:w-[30%]'>
          <span className='text-[12px] font-semibold text-gray-500'>
            Mobile Number
          </span>
          <div className='mt-2 flex h-[50px] w-full flex-row items-center rounded-[10px] border border-gray-500 focus:border-[#0097DB]'>
            <input
              type='text'
              name='fullName'
              placeholder='6478392982'
              value={props.facilityContactNumber}
              onChange={props.handleMobileNumber}
              className='h-[40px] w-[90%] pl-2 text-[14px] text-black placeholder-black focus:outline-none'
            />
            <div className='w-[10%]'>
              <MdVerified className='self-center text-[#12DB00]' />
            </div>
          </div>
        </div>
        <div className='d-flex flex-column w-[90%] md:w-[30%]'>
          <span className='mt-2 text-[12px] font-semibold text-gray-500'>
            Email
          </span>
          <div className='mt-2 flex h-[50px] w-full flex-row items-center rounded-[10px] border border-gray-500 focus:border-[#0097DB]'>
            <input
              type='email'
              // name="fullName"
              placeholder='example.com'
              value={props.facilityEmailId}
              onChange={props.handleEmail}
              // required
              className='h-[40px] w-[85%] pl-2 text-[14px] text-black placeholder-black focus:outline-none'
            />
            <div className='w-[15%] items-center'>
              <span className='mt-2 text-[14px] text-[#0097DB]'>verify</span>
            </div>
          </div>
        </div>
        <div className='d-flex flex-column w-[90%] md:w-[30%]'>
          <span className='mt-2 text-[12px] font-semibold text-gray-500'>
            Date of Birth
          </span>
          <div className='mt-2 flex h-[50px] w-full flex-row items-center rounded-[10px] border border-gray-500 focus:border-[#0097DB]'>
            <input
              type='text'
              // name="fullName"
              placeholder='00-00-0000'
              // value={formData.fullName}
              // onChange={handleInputChange}
              // required
              className='h-[40px] w-[90%] pl-2 text-[14px] text-black placeholder-black focus:outline-none'
            />
            {/* <div className="w-[10%]">
                            <MdVerified className="text-[#12DB00] self-center" />
                        </div> */}
          </div>
        </div>
      </div>
      <div className='md: mt-4 flex w-[95%] flex-col items-center justify-between md:flex-row'>
        <div className='flex-column w-[90%] md:w-[30%]'>
          <CollapsibleDropdown
            label='District'
            content={props.districtLGDCode}
            options={["Option 1", "Option 2", "Option 3"]}
            onSelect={props.handleDistrict}
          />
        </div>
        <div className='flex-column w-[90%] md:w-[30%]'>
          <CollapsibleDropdown
            label='Sub District'
            content={props.subDistrictLGDCode}
            options={["Option 1", "Option 2", "Option 3"]}
            onSelect={props.handleSubDistrict}
          />
        </div>
        <div className='flex-column w-[90%] md:w-[30%]'></div>
      </div>
      <div className='mt-4 flex w-[95%] flex-col items-start'>
        <h5 className='text-gray-500'>Roles</h5>
        <div className='d-flex flex-row items-center'>
          {" "}
          <input
            type='radio'
            name='gender'
            className='input-radio mt-1 h-[15px] w-[15px]'
            value='Male'
            // checked={formData.gender === "Male"}
            // onChange={handleChange}
          />{" "}
          <span className='text-[14px] font-semibold text-gray-400'>
            {" "}
            I am a Healthcare Professional
          </span>
        </div>
        <div className='d-flex mt-2 flex-row items-center'>
          {" "}
          <input
            type='radio'
            name='gender'
            className='input-radio mt-1 h-[15px] w-[15px]'
            value='Male'
            // checked={formData.gender === "Male"}
            // onChange={handleChange}
          />{" "}
          <span className='text-[14px] font-semibold text-gray-400'>
            I am a Facility Manager / Administrator
          </span>
        </div>
        <div className='d-flex mt-2 flex-row items-center'>
          {" "}
          <input
            type='radio'
            name='gender'
            className='input-radio mt-1 h-[15px] w-[15px]'
            value='Male'
            // checked={formData.gender === "Male"}
            // onChange={handleChange}
          />{" "}
          <span className='text-[14px] font-semibold text-gray-400'>
            I am a Healthcare Professional & Facility Manager
          </span>
        </div>
      </div>
      <div className='md: mt-4 flex w-[95%] flex-col items-center justify-between md:flex-row'>
        <div className='d-flex flex-column w-[90%] md:w-[30%]'>
          <span className='text-[12px] font-semibold text-gray-500'>
            Category
          </span>
          <div className='mt-2 flex h-[50px] w-full flex-row rounded-[10px] border border-gray-500'>
            <span className='h-40 w-[90%] pl-2 pt-2.5 text-start text-[14px] text-black'>
              example
            </span>
            <div className='w-[10%]'>
              <FaChevronDown className='mt-3 text-[15px]' />
            </div>
          </div>
        </div>
        <div className='d-flex flex-column w-[90%] md:w-[30%]'>
          <span className='mt-2 text-[12px] font-semibold text-gray-500'>
            Sub Category
          </span>
          <div className='mt-2 flex h-[50px] w-full flex-row rounded-[10px] border border-gray-500'>
            <span className='h-40 w-[90%] pl-2 pt-2.5 text-start text-[14px] text-black'>
              example
            </span>
            <div className='w-[10%]'>
              <FaChevronDown className='mt-3 text-[15px]' />
            </div>
          </div>
        </div>
        <div className='d-flex flex-column w-[90%] md:w-[30%]'></div>
      </div>
      <div className='md: mt-4 flex w-[95%] flex-col items-center justify-between md:flex-row'>
        <div className='d-flex flex-column mt-2 w-[90%] md:w-[30%]'>
          <span className='text-[12px] font-semibold text-gray-500'>
            Healthcare Professional ID/Username
          </span>
          <div className='mt-2 flex h-[50px] w-full flex-row items-center rounded-[10px] border border-gray-500 focus:border-[#0097DB]'>
            <input
              type='text'
              // name="fullName"
              placeholder='@hpr.abdm'
              // value={formData.fullName}
              // onChange={handleInputChange}
              // required
              className='h-[40px] w-full pl-2 text-[14px] text-black placeholder-black focus:outline-none'
            />
            {/* <div className="w-[10%]">
                            <MdVerified className="text-[#12DB00] self-center" />
                        </div> */}
          </div>
        </div>
        <div className='d-flex flex-column w-[90%] md:w-[30%]'>
          <span className='mt-2 text-[12px] font-semibold text-gray-500'>
            Password
          </span>
          <div className='mt-2 flex h-[50px] w-full flex-row items-center rounded-[10px] border border-gray-500 focus:border-[#0097DB]'>
            <input
              type='password'
              // name="fullName"
              placeholder='Password'
              // value={formData.fullName}
              // onChange={handleInputChange}
              // required
              className='h-[40px] w-full pl-2 text-[14px] text-black placeholder-black focus:outline-none'
            />
            {/* <div className="w-[15%] items-center">
                            <span className="text-[14px] text-[#0097DB] mt-2">verify</span>
                        </div> */}
          </div>
        </div>
        <div className='d-flex flex-column w-[90%] md:w-[30%]'>
          <span className='mt-2 text-[12px] font-semibold text-gray-500'>
            Confirm Password
          </span>
          <div className='mt-2 flex h-[50px] w-full flex-row items-center rounded-[10px] border border-gray-500 focus:border-[#0097DB]'>
            <input
              type='password'
              // name="fullName"
              placeholder='Confirm Password'
              // value={formData.fullName}
              // onChange={handleInputChange}
              // required
              className='h-[40px] w-full pl-2 text-[14px] text-black placeholder-black focus:outline-none'
            />
          </div>
        </div>
      </div>
      <div className='mt-8 flex w-[95%] flex-row items-center justify-start pb-4'>
        <button className='flex h-[50px] w-[40%] flex-row items-center justify-center rounded-md bg-[#B8B8B8]'>
          <span className='pl-2 text-lg text-[white]'>Reset</span>
        </button>
        <button
          onClick={props.onSubmit}
          className='ml-4 flex h-[50px] w-[40%] flex-row items-center justify-center rounded-md bg-[#0095D9]'
        >
          <span className='pl-2 text-lg text-[white]'>Submit</span>
        </button>
      </div>
    </div>
  );
};

const Registration = (props) => {
  return (
    <div className='mb-2 mt-4 flex w-full flex-col items-center bg-white pb-4 pt-4'>
      <div className='mt-[5%] flex w-full flex-col items-start'>
        <div className='ml-[5%] flex h-[40px] w-[70%] flex-col justify-center rounded-md bg-white md:w-[30%]'>
          <span className='text-bold text-[26px] text-gray-500'>
            Facility Details
          </span>
        </div>
      </div>
      <div className='md: mt-4 flex w-[90%] flex-col items-center justify-between md:flex-row'>
        <div className='d-flex flex-column mt-2 w-[90%] md:w-[49%]'>
          <span className='text-[12px] font-semibold text-gray-500'>
            Facility Name
          </span>
          <div className='mt-2 flex h-[50px] w-full flex-row items-center rounded-[10px] border border-gray-500 focus:border-[#0097DB]'>
            <input
              type='text'
              // name="fullName"
              value={props.facilityName}
              onChange={props.handleFacilityName}
              // required
              className='h-[40px] w-full pl-2 text-[14px] text-black placeholder-black focus:outline-none'
            />
          </div>
        </div>
        <div className='d-flex flex-column mt-2 w-[90%] md:w-[49%]'>
          <span className='text-[12px] font-semibold text-gray-500'>
            Country
          </span>
          <div className='mt-2 flex h-[50px] w-full flex-row items-center rounded-[10px] border border-gray-500 focus:border-[#0097DB]'>
            <input
              type='text'
              // name="fullName"
              value={props.country}
              onChange={props.handleCountry}
              // required
              className='h-[40px] w-full pl-2 text-[14px] text-black placeholder-black focus:outline-none'
            />
          </div>
        </div>
      </div>
      <div className='md: mt-4 flex w-[90%] flex-col items-center justify-between md:flex-row'>
        <div className='d-flex flex-column mt-2 w-[90%] md:w-[49%]'>
          <span className='text-[12px] font-semibold text-gray-500'>
            State/UT
          </span>
          <div className='mt-2 flex h-[50px] w-full flex-row items-center rounded-[10px] border border-gray-500 focus:border-[#0097DB]'>
            <input
              type='text'
              // name="fullName"
              value={props.stateLGDCode}
              onChange={props.handleState}
              // required
              className='h-[40px] w-full pl-2 text-[14px] text-black placeholder-black focus:outline-none'
            />
          </div>
        </div>
        <div className='d-flex flex-column mt-2 w-[90%] md:w-[49%]'>
          <span className='text-[12px] font-semibold text-gray-500'>
            District
          </span>
          <div className='mt-2 flex h-[50px] w-full flex-row items-center rounded-[10px] border border-gray-500 focus:border-[#0097DB]'>
            <input
              type='text'
              // name="fullName"
              value={props.districtLGDCode}
              onChange={props.handleDistrict}
              // required
              className='h-[40px] w-full pl-2 text-[14px] text-black placeholder-black focus:outline-none'
            />
          </div>
        </div>
      </div>
      <div className='md: mt-4 flex w-[90%] flex-col items-center justify-between md:flex-row'>
        <div className='d-flex flex-column mt-2 w-[90%] md:w-[49%]'>
          <span className='text-[12px] font-semibold text-gray-500'>
            Sub-District
          </span>
          <div className='mt-2 flex h-[50px] w-full flex-row items-center rounded-[10px] border border-gray-500 focus:border-[#0097DB]'>
            <input
              type='text'
              // name="fullName"
              value={props.subDistrictLGDCode}
              onChange={props.handleSubDistrict}
              // required
              className='h-[40px] w-full pl-2 text-[14px] text-black placeholder-black focus:outline-none'
            />
          </div>
        </div>
        <div className='d-flex flex-column mt-2 w-[90%] md:w-[49%]'>
          <span className='text-[12px] font-semibold text-gray-500'>
            Facility Region
          </span>
          <div className='mt-2 flex h-[50px] w-full flex-row items-center rounded-[10px] border border-gray-500 focus:border-[#0097DB]'>
            <input
              type='text'
              // name="fullName"
              value={props.facilityRegion}
              onChange={props.handleRegion}
              // required
              className='h-[40px] w-full pl-2 text-[14px] text-black placeholder-black focus:outline-none'
            />
          </div>
        </div>
      </div>
      <div className='md: mt-4 flex w-[90%] flex-col items-center justify-between md:flex-row'>
        <div className='d-flex flex-column mt-2 w-[90%] md:w-[49%]'>
          <span className='text-[12px] font-semibold text-gray-500'>
            Village/City/town
          </span>
          <div className='mt-2 flex h-[50px] w-full flex-row items-center rounded-[10px] border border-gray-500 focus:border-[#0097DB]'>
            <input
              type='text'
              // name="fullName"
              value={props.villageCityTownLGDCode}
              onChange={props.handleCity}
              // required
              className='h-[40px] w-full pl-2 text-[14px] text-black placeholder-black focus:outline-none'
            />
          </div>
        </div>
        <div className='d-flex flex-column mt-2 w-[90%] md:w-[49%]'>
          <span className='text-[12px] font-semibold text-gray-500'>
            Address No.1
          </span>
          <div className='mt-2 flex h-[50px] w-full flex-row items-center rounded-[10px] border border-gray-500 focus:border-[#0097DB]'>
            <input
              type='text'
              // name="fullName"
              value={props.addressLine1}
              onChange={props.handleAddress1}
              // required
              className='h-[40px] w-full pl-2 text-[14px] text-black placeholder-black focus:outline-none'
            />
          </div>
        </div>
      </div>
      <div className='md: mt-4 flex w-[90%] flex-col items-center justify-between md:flex-row'>
        <div className='d-flex flex-column mt-2 w-[90%] md:w-[49%]'>
          <span className='text-[12px] font-semibold text-gray-500'>
            Address No.2
          </span>
          <div className='mt-2 flex h-[50px] w-full flex-row items-center rounded-[10px] border border-gray-500 focus:border-[#0097DB]'>
            <input
              type='text'
              // name="fullName"
              value={props.addressLine2}
              onChange={props.handleAddress2}
              // required
              className='h-[40px] w-full pl-2 text-[14px] text-black placeholder-black focus:outline-none'
            />
          </div>
        </div>
        <div className='d-flex flex-column mt-2 w-[90%] md:w-[49%]'>
          <span className='text-[12px] font-semibold text-gray-500'>
            Pin Code
          </span>
          <div className='mt-2 flex h-[50px] w-full flex-row items-center rounded-[10px] border border-gray-500 focus:border-[#0097DB]'>
            <input
              type='text'
              // name="fullName"
              value={props.pincode}
              onChange={props.handlePincode}
              // required
              className='h-[40px] w-full pl-2 text-[14px] text-black placeholder-black focus:outline-none'
            />
          </div>
        </div>
      </div>
      <div className='md: mt-4 flex w-[90%] flex-col items-center justify-between md:flex-row'>
        <div className='d-flex flex-column mt-2 w-[90%] md:w-[49%]'>
          <span className='text-[12px] font-semibold text-gray-500'>
            Landline Number
          </span>
          <div className='mt-2 flex h-[50px] w-full flex-row items-center rounded-[10px] border border-gray-500 focus:border-[#0097DB]'>
            <input
              type='text'
              // name="fullName"
              value={props.facilityLandlineNumber}
              onChange={props.handleLandlineNumber}
              // required
              className='h-[40px] w-full pl-2 text-[14px] text-black placeholder-black focus:outline-none'
            />
          </div>
        </div>
        <div className='d-flex flex-column mt-2 w-[90%] md:w-[49%]'>
          <span className='text-[12px] font-semibold text-gray-500'>
            Mobile Number (For Public Display)
          </span>
          <div className='flex h-[50px] w-full flex-row items-center'>
            <div className='mt-2 flex h-[50px] w-[80%] flex-row items-center rounded-[10px] border border-gray-500 focus:border-[#0097DB]'>
              <input
                type='text'
                // name="fullName"
                value={props.facilityContactNumber}
                onChange={props.handleMobileNumber}
                // required
                className='h-[40px] w-full pl-2 text-[14px] text-black placeholder-black focus:outline-none'
              />
            </div>
            <div className='ml-[2%] mt-2 flex h-[50px] w-[18%] flex-col items-center justify-center rounded-lg bg-[#0095D9]'>
              <span className='text-[14px] font-semibold text-white'>
                Verify
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className='md: mt-4 flex w-[90%] flex-col items-center justify-between md:flex-row'>
        <div className='d-flex flex-column mt-2 w-[90%] md:w-[49%]'>
          <span className='text-[12px] font-semibold text-gray-500'>
            Facility Email (For Public Display)
          </span>
          <div className='flex h-[50px] w-full flex-row items-center'>
            <div className='mt-2 flex h-[50px] w-[80%] flex-row items-center rounded-[10px] border border-gray-500 focus:border-[#0097DB]'>
              <input
                type='text'
                // name="fullName"
                value={props.facilityEmailId}
                onChange={props.handleEmail}
                // required
                className='h-[40px] w-full pl-2 text-[14px] text-black placeholder-black focus:outline-none'
              />
            </div>
            <div className='ml-[2%] mt-2 flex h-[50px] w-[18%] flex-col items-center justify-center rounded-lg bg-[#2CBA20]'>
              <span className='text-[14px] font-semibold text-white'>
                Verify
              </span>
            </div>
          </div>
        </div>
        <div className='d-flex flex-column mt-2 w-[90%] md:w-[49%]'>
          <span className='text-[12px] font-semibold text-gray-500'>
            Website (For Public Display)
          </span>
          <div className='mt-2 flex h-[50px] w-full flex-row items-center rounded-[10px] border border-gray-500 focus:border-[#0097DB]'>
            <input
              type='text'
              // name="fullName"
              value={props.websiteLink}
              onChange={props.handleWebsite}
              // required
              className='h-[40px] w-full pl-2 text-[14px] text-black placeholder-black focus:outline-none'
            />
          </div>
        </div>
      </div>
      <div className='md: mt-4 flex w-[90%] flex-col items-center justify-between md:flex-row'>
        <div className='d-flex flex-column mt-2 w-[90%] md:w-[49%]'>
          <span className='text-[12px] font-semibold text-gray-500'>
            Geographic Location
          </span>
          <div className='flex h-[50px] w-full flex-row items-center'>
            <div className='mt-2 flex h-[50px] w-[85%] flex-row items-center rounded-[10px] border border-gray-500 focus:border-[#0097DB]'>
              <input
                type='text'
                // name="fullName"
                // value={formData.fullName}
                // onChange={handleInputChange}
                // required
                className='h-[40px] w-full pl-2 text-[14px] text-black placeholder-black focus:outline-none'
              />
            </div>
            <div className='ml-[2%] mt-2 flex h-[50px] w-[13%] flex-col items-center justify-center rounded-lg bg-[#0095D9]'>
              {/* <span className="text-[14px] font-semibold text-white"></span> */}
              <FaLocationDot className='text-lg text-white' />
            </div>
          </div>
        </div>
        <div className='d-flex flex-column mt-2 w-[90%] md:w-[49%]'>
          <span className='text-[12px] font-semibold text-gray-500'>
            Link for book an appointment
          </span>
          <div className='mt-2 flex h-[50px] w-full flex-row items-center rounded-[10px] border border-gray-500 focus:border-[#0097DB]'>
            <input
              type='text'
              // name="fullName"
              // value={formData.fullName}
              // onChange={handleInputChange}
              // required
              className='h-[40px] w-full pl-2 text-[14px] text-black placeholder-black focus:outline-none'
            />
          </div>
        </div>
      </div>
      <div className='mt-8 flex w-[90%] flex-row items-center justify-end pb-4'>
        {/* <button className="flex flex-row items-center w-[100px] h-[40px] rounded-md justify-center bg-[#B8B8B8]">
                    <span className="text-sm text-[white]">Save Draft</span>
                </button> */}
        {!props.loading && (
          <button
            onClick={props.onSave}
            className='ml-4 flex h-[40px] w-[100px] flex-row items-center justify-center rounded-full bg-gray-400'
          >
            <span className='text-sm text-[white]'>Save </span>
          </button>
        )}
      </div>
    </div>
  );
};
