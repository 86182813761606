import React from "react";
import HeroSection from "../../components/Users/Ui/HeroSection";
import { useDispatch } from "react-redux";
import { setMyPath } from "../../Redux/userSlice";
import { HomeNav } from "../../components";

const Login = () => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(setMyPath(window.location.pathname));
  }, []);

  return (
    <>
      <HomeNav />
      <HeroSection />
    </>
  );
};

export default Login;
