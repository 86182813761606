import React from "react";
import { Helmet } from "react-helmet";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Footer from "../../components/Home/footer/Footer";
import ExpertNav from "../../components/Navbar/ExpertNav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { setMyPath } from "../../Redux/userSlice";

const imageArr1 = [
  {
    image: require("../../Assets/image/app1.png"),
    description: "Comprehensive Profile",
  },
  {
    image: require("../../Assets/image/app2.png"),
    description: "Online reach",
  },
  {
    image: require("../../Assets/image/app3.png"),
    description: "patient management",
  },
  {
    image: require("../../Assets/image/app4.png"),
    description: "Ai assistance(Dr Jii)",
  },
  {
    image: require("../../Assets/image/app5.png"),
    description: " Community support(Docare)",
  },
  {
    image: require("../../Assets/image/app6.png"),
    description: "Earning Growth",
  },
];

const imageArr2 = [
  {
    image: require("../../Assets/image/alpha1.png"),
    description: "AI powered operations",
  },
  {
    image: require("../../Assets/image/alpha2.png"),
    description: "Facility marketing tools",
  },
  {
    image: require("../../Assets/image/alpha3.png"),
    description: "Smart Calling system",
  },
  {
    image: require("../../Assets/image/alpha4.png"),
    description: "Patient relationship",
  },
  {
    image: require("../../Assets/image/alpha5.png"),
    description: "Secure Data",
  },
  {
    image: require("../../Assets/image/alpha6.png"),
    description: "Facility Linkage",
  },
  {
    image: require("../../Assets/image/alpha7.png"),
    description: "More Profit & Growth",
  },
];

const imageArr3 = [
  {
    image: require("../../Assets/image/pro1.png"),
    description: "IPD management",
  },
  {
    image: require("../../Assets/image/pro2.png"),
    description: "Professionals management",
  },
  {
    image: require("../../Assets/image/pro3.png"),
    description: "Stores management",
  },
  {
    image: require("../../Assets/image/pro4.png"),
    description: "Linkage System",
  },
  {
    image: require("../../Assets/image/pro5.png"),
    description: "Staff management",
  },
  {
    image: require("../../Assets/image/pro6.png"),
    description: "Easy Reports creation",
  },
  {
    image: require("../../Assets/image/pro7.png"),
    description: "Easy payments & claims",
  },
];

export default function ChooseAcc() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  React.useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setMyPath(window.location.pathname));
  }, []);
  return (
    <>
      <ExpertNav />
      <div className='App w-[100%]'>
        <button onClick={() => navigate(-1)} className='ml-[3%] mt-[2%]'>
          <img
            src={require("../../Assets/image/left2.png")}
            className='h-[44px] w-[44px]'
          />
        </button>
        <div className='w-80% flex flex-col items-center'>
          <h1 className='w-[80%] font-[Gilroy-SemiBold] text-[26px] text-black'>
            Choose a way to setup your account
          </h1>
          <p className='w-[80%] font-[Gilroy-SemiBold] text-[18px] text-[#616161]'>
            You’ll use this method to set up your account. Choose an option that
            works best for you.
          </p>
        </div>
        <Types />
      </div>
      <Footer />
    </>
  );
}

const Types = () => {
  return (
    <div className='m-4 mb-[5%] flex w-[95%] flex-col items-center rounded-[5px] pb-4 md:flex-row md:items-start md:justify-between'>
      <div className='col border-1 mt-10 w-[80%] rounded-xl bg-[#F8FBFF] p-6 md:ml-10 md:w-[30%]'>
        <div className='flex flex-row items-end'>
          <h1
            className='font-semiBold text-[45px] text-[#535353]'
            style={{ fontFamily: "Gilroy-SemiBold" }}
          >
            avijo
          </h1>
          <h6 className='text-xl italic text-[#FD7979]'>Expert</h6>
        </div>
        <p className='mt-2 w-[80%] font-[Gilroy-SemiBold] text-[16px] text-[#616161]'>
          For Health Professionals
        </p>
        <p className='mt-2 w-[80%] font-[Gilroy-SemiBold] text-[16px] text-[#616161]'>
          A free practice & profile management tool for Doctors;
        </p>
        <div className='mb-4 mt-6'>
          <ul className='mb-3 p-0'>
            {imageArr1.map((item, index) => (
              <li key={index} className='d-flex align-items-center mb-3 gap-3'>
                <img src={item.image} className='h-[24px] w-[24px]' />
                <div className='flex w-full flex-col items-start'>
                  {item.description}
                </div>
              </li>
            ))}
          </ul>
        </div>
        <button className='border-1 mb-[4%] ml-[2%] mt-4 flex h-10 w-[90%] flex-row items-center justify-center rounded-full border-[black] bg-[white]'>
          <span className='self-center font-[Gilroy-SemiBold] text-[16px] text-[black]'>
            Create Free HPR Profile
          </span>
          <img
            src={require("../../Assets/image/rightBlack.png")}
            className='ml-2 h-[20px] w-[20px]'
          />
        </button>
      </div>
      <div className='col mt-10 w-[80%] rounded-xl border-2 bg-[#F8FBFF] p-6 md:ml-10 md:w-[30%]'>
        <div className='flex flex-row items-end'>
          <h1
            className='font-semiBold text-[45px] text-[#535353]'
            style={{ fontFamily: "Gilroy-SemiBold" }}
          >
            avijo
          </h1>
          <h6 className='text-xl italic text-[#12CDB7]'>Alpha</h6>
        </div>
        <p className='mt-2 w-[80%] font-[Gilroy-SemiBold] text-[16px] text-[#616161]'>
          For Health Facilities
        </p>
        <p className='mt-2 w-[90%] font-[Gilroy-SemiBold] text-[16px] text-[#616161]'>
          A Clinic management software for
        </p>
        <div className='flex flex-row'>
          <p className='mr-2 mt-1 rounded-[3px] border border-black pl-1 pr-1 text-[10px] text-black'>
            5 years
          </p>
          <p className='pr-1 pt-1 text-[10px] font-bold text-black'>₹</p>
          <h6 className='font-[Gilroy-SemiBold] text-[black]'>999</h6>
          <h6 className='pl-1 font-[Gilroy-SemiBold] text-[grey] line-through'>
            1,999
          </h6>
          <h6 className='font-[Gilroy-SemiBold] text-[black]'>/month;</h6>
        </div>
        <div className='ml-4 flex flex-row'>
          <p className='mr-2 mt-1 rounded-[2px] border border-black pl-1 pr-1 pt-[2px] text-[6px] text-black'>
            1 years
          </p>
          <p className='pr-1 pt-1 text-[10px] font-bold text-black'>₹</p>
          <h6 className='font-[Gilroy-SemiBold] text-[14px] text-[black]'>
            1250
          </h6>
          <h6 className='pl-1 font-[Gilroy-SemiBold] text-[14px] text-[grey] line-through'>
            1,999
          </h6>
          <h6 className='font-[Gilroy-SemiBold] text-[14px] text-[black]'>
            /month;
          </h6>
        </div>
        <p className='mt-2 w-[80%] font-[Gilroy-SemiBold] text-[16px] text-[#616161]'>
          Everything in Expert, plus:
        </p>
        <div className='mb-4'>
          <ul className='mb-3 p-0'>
            {imageArr2.map((item, index) => (
              <li key={index} className='d-flex align-items-center mb-3 gap-3'>
                <img src={item.image} className='h-[24px] w-[24px]' />
                <div className='flex w-full flex-col items-start'>
                  {item.description}
                </div>
              </li>
            ))}
          </ul>
        </div>
        <button className='mb-[4%] ml-[2%] mt-4 flex h-10 w-[90%] flex-row items-center justify-center rounded-full border-[black] bg-[#0097DB]'>
          <span className='self-center font-[Gilroy-SemiBold] text-[16px] text-[white]'>
            Start a free trial
          </span>
          <img
            src={require("../../Assets/image/rightWhite.png")}
            className='ml-2 h-[20px] w-[20px]'
          />
        </button>

        <button className='border-1 mb-[4%] ml-[2%] mt-4 flex h-10 w-[240px] flex-row items-center justify-center rounded-full border-[black] bg-[white]'>
          <span className='self-center font-[Gilroy-SemiBold] text-[16px] text-[black]'>
            Download On App Store
          </span>
          <img
            src={require("../../Assets/image/diagonal_arrow.png")}
            className='ml-2 h-[20px] w-[20px]'
          />
        </button>
        <button className='border-1 mb-[10%] ml-[2%] flex h-10 w-[270px] flex-row items-center justify-center rounded-full border-[black] bg-[white]'>
          <span className='self-center font-[Gilroy-SemiBold] text-[16px] text-[black]'>
            Download On Google Play
          </span>
          <img
            src={require("../../Assets/image/diagonal_arrow.png")}
            className='ml-2 h-[20px] w-[20px]'
          />
        </button>
      </div>
      <div className='col border-1 mt-10 w-[80%] rounded-xl bg-[#F8FBFF] p-6 md:ml-10 md:w-[30%]'>
        <div className='flex flex-row items-end'>
          <h1
            className='font-semiBold text-[45px] text-[#535353]'
            style={{ fontFamily: "Gilroy-SemiBold" }}
          >
            avijo
          </h1>
          <h6 className='text-xl italic text-[#12CDB7]'>Alpha</h6>
          <h6 className='ml-[2%] text-[18px] text-[#F39090]'> Pro</h6>
        </div>
        <p className='mt-2 w-[80%] font-[Gilroy-SemiBold] text-[16px] text-[#616161]'>
          For Hospitals
        </p>
        <p className='mt-2 w-[80%] font-[Gilroy-SemiBold] text-[16px] text-[#616161]'>
          A Hospital management system;
        </p>
        <p className='mt-2 w-[80%] font-[Gilroy-SemiBold] text-[16px] text-[#616161]'>
          Everything in Alpha, plus:
        </p>
        <div className='mb-4'>
          <ul className='mb-3 p-0'>
            {imageArr3.map((item, index) => (
              <li key={index} className='d-flex align-items-center mb-3 gap-3'>
                <img src={item.image} className='h-[24px] w-[24px]' />
                <div className='flex w-full flex-col items-start'>
                  {item.description}
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className='flex flex-row items-center'>
          <button className='border-1 mb-[5%] ml-[2%] mt-4 flex h-10 w-[50%] flex-row items-center justify-center rounded-full border-[black] bg-[black]'>
            <span className='self-center font-[Gilroy-SemiBold] text-[16px] text-[white]'>
              Contact Sales
            </span>
            <img
              src={require("../../Assets/image/rightWhite.png")}
              className='ml-2 h-[20px] w-[20px]'
            />
          </button>
          <button className='border-1 ml-[2%] flex h-10 w-[50%] flex-row items-center justify-center rounded-full border-[black] bg-[white]'>
            <span className='self-center font-[Gilroy-SemiBold] text-[16px] text-[black]'>
              Request Trial
            </span>
            <img
              src={require("../../Assets/image/rightBlack.png")}
              className='ml-2 h-[20px] w-[20px]'
            />
          </button>
        </div>
        <div>
          <button className='border-1 mb-[4%] ml-[2%] mt-4 flex h-10 w-[240px] flex-row items-center justify-center rounded-full border-[black] bg-[white]'>
            <span className='self-center font-[Gilroy-SemiBold] text-[16px] text-[black]'>
              Download On App Store
            </span>
            <img
              src={require("../../Assets/image/diagonal_arrow.png")}
              className='ml-2 h-[20px] w-[20px]'
            />
          </button>
          <button className='border-1 mb-[10%] ml-[2%] flex h-10 w-[270px] flex-row items-center justify-center rounded-full border-[black] bg-[white]'>
            <span className='self-center font-[Gilroy-SemiBold] text-[16px] text-[black]'>
              Download On Google Play
            </span>
            <img
              src={require("../../Assets/image/diagonal_arrow.png")}
              className='ml-2 h-[20px] w-[20px]'
            />
          </button>
        </div>
      </div>
    </div>
  );
};
